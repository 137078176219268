<script lang="ts">
  import { initializePaginationFromQueryParams, type PaginationParams } from 'src/api/query/pagination';
  import type { SortFunction } from 'src/api/query/sort';
  import type { DisruptionTextSnippetFilterCriteria } from 'src/components/pages/disruptionTextSnippet/disruptionTextSnippetService';
  import type { Pagination, TextSnippet } from '$generated/service/cache-api';
  import type { SvelteEvent } from 'src/model/event';
  import { _ } from 'svelte-i18n';
  import { writable } from 'svelte/store';
  import { onMount } from 'svelte';
  import { Button, NumberedPagination, PageLayout, notificationService } from '@pids/shared-component';
  import { DisruptionTextSnippetSort } from 'src/components/pages/disruptionTextSnippet/disruptionTextSnippetSort';
  import { sortBy } from 'src/api/query/sort';
  import { createQuery } from 'src/api/query/query';
  import {
    deleteDisruptionTextSnippet,
    disruptionMessageTextSnippetParamNames,
    fetchDisruptionTextSnippets
  } from 'src/components/pages/disruptionTextSnippet/disruptionTextSnippetService';
  import type { DisruptionTextSnippetDelete } from 'src/components/pages/disruptionTextSnippet/DisruptionTextSnippetTable.svelte';
  import DisruptionTextSnippetTable from 'src/components/pages/disruptionTextSnippet/DisruptionTextSnippetTable.svelte';
  import DisruptionTextSnippetFilter from 'src/components/pages/disruptionTextSnippet/DisruptionTextSnippetFilter.svelte';
  import { DEFAULT_ERROR_TEXT_KEY } from 'src/api/notification';
  import type { LoadingStateStore } from '@pids/shared-component';
  import DeleteModalDialog from 'src/components/shared/DeleteModalDialog.svelte';
  import { Permission } from 'src/user/permissions';
  import Restricted from 'src/components/shared/Restricted.svelte';
  import { routeLinks, toDisruptionMessageTextSnippetLink } from 'src/components/pages/routingService';
  import { parseNumberParam } from 'src/api/query/criteria';
  import { querystring, replace } from 'svelte-spa-router';

  const initialPagination: PaginationParams = {
    page: 1,
    size: 50,
    sort: `${DisruptionTextSnippetSort.Name},asc`
  };

  const sortFunction: SortFunction = sortBy(DisruptionTextSnippetSort.Name);
  let paginationParams: PaginationParams = initialPagination;

  let filter = writable<DisruptionTextSnippetFilterCriteria>({});
  let results: TextSnippet[] = [];
  let pagination: Pagination | undefined;
  let loading: LoadingStateStore;

  let disruptionTextSnippetId: number | undefined = undefined;

  let showDeleteDialog = false;
  let disruptionTextSnippetName: string | undefined;

  const resultsQuery = createQuery(
    [],
    signal => fetchDisruptionTextSnippets($filter, paginationParams, { signal }),
    false
  );

  $: results = !$resultsQuery.isError ? $resultsQuery.results : [];
  $: pagination = !$resultsQuery.isError ? $resultsQuery.pagination : undefined;
  $: loading = resultsQuery.loading;

  $: $resultsQuery.isError && notificationService.error($resultsQuery.error.message ?? $_(DEFAULT_ERROR_TEXT_KEY));

  onMount(() => {
    handleQueryString($querystring);

    const unsubscribeFilter = filter.subscribe(() => {
      resetPageAndSort();
      void loadData();
    });

    return () => {
      unsubscribeFilter();
      resultsQuery.abort();
    };
  });

  const loadData = async () => {
    updateUrlParameters();
    await resultsQuery.next();
  };

  const updateUrlParameters = () => {
    replace(toDisruptionMessageTextSnippetLink({ ...$filter }, paginationParams));
  };

  const handleQueryString = (query: string | undefined) => {
    if (!query) {
      return;
    }

    const params = new URLSearchParams(query);

    paginationParams = initializePaginationFromQueryParams(params, paginationParams);
    $filter = initializeCriteriaFromQueryParams(params);
  };

  const initializeCriteriaFromQueryParams = (params: URLSearchParams): DisruptionTextSnippetFilterCriteria => {
    return {
      ...$filter,
      search: params.get(disruptionMessageTextSnippetParamNames.search) ?? undefined,
      organizationId: parseNumberParam(params.get(disruptionMessageTextSnippetParamNames.organization))
    };
  };

  const handleSortChange = (e: SvelteEvent<{ sort: DisruptionTextSnippetSort }>) => {
    const newSort = sortFunction(e.detail.sort);

    paginationParams = {
      ...paginationParams,
      sort: newSort
    };

    void loadData();
  };

  const resetPageAndSort = () => {
    paginationParams = initialPagination;
  };

  const handlePageChange = (page: number) => {
    paginationParams = {
      ...paginationParams,
      page
    };
    void loadData();
  };

  const openDeleteDialog = (e: SvelteEvent<DisruptionTextSnippetDelete>) => {
    showDeleteDialog = true;
    disruptionTextSnippetName = e.detail.name;
    disruptionTextSnippetId = e.detail.id;
  };

  const closeDeleteDialog = () => {
    showDeleteDialog = false;
    disruptionTextSnippetId = undefined;
    disruptionTextSnippetName = undefined;
  };

  const handleConfirmDelete = async () => {
    if (!disruptionTextSnippetId) return;

    await deleteDisruptionTextSnippet(disruptionTextSnippetId);

    loadData();
    closeDeleteDialog();
  };
</script>

<PageLayout title={$_('disruption-management.text-snippet.list.title')}>
  <svelte:fragment slot="criteria">
    <DisruptionTextSnippetFilter bind:filter={$filter} />
  </svelte:fragment>

  <svelte:fragment slot="actions">
    <Restricted to={Permission.DISRUPTION_TEXT_SNIPPET_WRITE}>
      <Button primary icon="plus" href="#{routeLinks.disruptionTextSnippetCreate}">
        {$_('disruption-management.text-snippet.create.label')}
      </Button>
    </Restricted>
  </svelte:fragment>

  <svelte:fragment slot="content">
    <DisruptionTextSnippetTable
      loading={$loading}
      resultsList={results}
      on:sort={handleSortChange}
      on:delete={openDeleteDialog} />
  </svelte:fragment>

  <svelte:fragment slot="pagination">
    {#if !$loading && pagination}
      <NumberedPagination {...pagination} onPageChange={handlePageChange} />
    {/if}
  </svelte:fragment>
</PageLayout>

{#if showDeleteDialog && disruptionTextSnippetName}
  <DeleteModalDialog
    bind:open={showDeleteDialog}
    title={$_('disruption-management.text-snippet.delete.title')}
    name={disruptionTextSnippetName}
    on:confirm={handleConfirmDelete}
    on:cancel={closeDeleteDialog}>
    <svelte:fragment slot="confirm">
      {$_('disruption-management.text-snippet.delete.message.confirm')}
    </svelte:fragment>
  </DeleteModalDialog>
{/if}
