/* tslint:disable */
/* eslint-disable */
/**
 * Netcetera PIDS - Hub Cache API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.6.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { __awaiter } from "tslib";
import * as runtime from '../runtime';
import { StationItemFromJSON, } from '../models/index';
/**
 *
 */
export class StationApi extends runtime.BaseAPI {
    /**
     * Get all stations that match the given query. Stations without an sloid are filtered out.
     */
    searchStationsRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            if (requestParameters['name'] != null) {
                queryParameters['name'] = requestParameters['name'];
            }
            if (requestParameters['limit'] != null) {
                queryParameters['limit'] = requestParameters['limit'];
            }
            const headerParameters = {};
            const response = yield this.request({
                path: `/station/search`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(StationItemFromJSON));
        });
    }
    /**
     * Get all stations that match the given query. Stations without an sloid are filtered out.
     */
    searchStations() {
        return __awaiter(this, arguments, void 0, function* (requestParameters = {}, initOverrides) {
            const response = yield this.searchStationsRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
}
