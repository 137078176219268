/**
 * Checks whether the given value is a non-null plain object.
 *
 * @param value the value to check
 */
export const isPlainObject = (value) => {
    return value !== null && typeof value === 'object' && value.constructor === Object;
};
/**
 * Make a deep copy from a value.
 *
 * @param value the value to copy
 * @returns the copy
 */
export const deepCopy = (value) => {
    return JSON.parse(JSON.stringify(value));
};
/**
 * Checks if an array is empty.
 * @param arr the array
 */
export const isEmpty = (arr) => {
    return !arr || arr.length == 0;
};
