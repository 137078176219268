/* tslint:disable */
/* eslint-disable */
/**
 * Netcetera PIDS - Hub Cache API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.6.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { AffectedStationFromJSON, AffectedStationToJSON, } from './AffectedStation';
import { DisruptionScopeFromJSON, DisruptionScopeToJSON, } from './DisruptionScope';
import { DisruptionMessageCauseEnumFromJSON, DisruptionMessageCauseEnumToJSON, } from './DisruptionMessageCauseEnum';
import { TextFromJSON, TextToJSON, } from './Text';
import { AffectedLineFromJSON, AffectedLineToJSON, } from './AffectedLine';
import { ScheduleRefFromJSON, ScheduleRefToJSON, } from './ScheduleRef';
import { IntervalFromJSON, IntervalToJSON, } from './Interval';
/**
 * Check if a given object implements the DisruptionMessage interface.
 */
export function instanceOfDisruptionMessage(value) {
    if (!('id' in value) || value['id'] === undefined)
        return false;
    if (!('activeIntervals' in value) || value['activeIntervals'] === undefined)
        return false;
    if (!('scope' in value) || value['scope'] === undefined)
        return false;
    if (!('cause' in value) || value['cause'] === undefined)
        return false;
    if (!('texts' in value) || value['texts'] === undefined)
        return false;
    if (!('schedule' in value) || value['schedule'] === undefined)
        return false;
    return true;
}
export function DisruptionMessageFromJSON(json) {
    return DisruptionMessageFromJSONTyped(json, false);
}
export function DisruptionMessageFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'id': json['id'],
        'activeIntervals': (json['activeIntervals'].map(IntervalFromJSON)),
        'scope': DisruptionScopeFromJSON(json['scope']),
        'cause': DisruptionMessageCauseEnumFromJSON(json['cause']),
        'texts': (json['texts'].map(TextFromJSON)),
        'stations': json['stations'] == null ? undefined : (json['stations'].map(AffectedStationFromJSON)),
        'lines': json['lines'] == null ? undefined : (json['lines'].map(AffectedLineFromJSON)),
        'schedule': ScheduleRefFromJSON(json['schedule']),
    };
}
export function DisruptionMessageToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'id': value['id'],
        'activeIntervals': (value['activeIntervals'].map(IntervalToJSON)),
        'scope': DisruptionScopeToJSON(value['scope']),
        'cause': DisruptionMessageCauseEnumToJSON(value['cause']),
        'texts': (value['texts'].map(TextToJSON)),
        'stations': value['stations'] == null ? undefined : (value['stations'].map(AffectedStationToJSON)),
        'lines': value['lines'] == null ? undefined : (value['lines'].map(AffectedLineToJSON)),
        'schedule': ScheduleRefToJSON(value['schedule']),
    };
}
