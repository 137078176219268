import { cacheApi } from 'src/api/apiClients';
import { isNotBlank } from 'src/util/stringUtils';
import { wrapPaginated, wrap } from 'src/api/query';
export const fetchMessages = (criteria, pagination, options) => {
    const request = createRequestData(criteria, pagination);
    return wrapPaginated(() => cacheApi.findMessages(request, options));
};
const createRequestData = (criteria, pagination) => {
    const req = {
        page: pagination.page,
        size: pagination.size,
        sort: pagination.sort
    };
    if (criteria.schedule && isNotBlank(criteria.schedule)) {
        req.schedules = [criteria.schedule];
    }
    if (isNotBlank(criteria.cause)) {
        req.cause = criteria.cause;
    }
    if (criteria.validFrom) {
        req.validFrom = criteria.validFrom;
    }
    if (criteria.validTo) {
        req.validTo = criteria.validTo;
    }
    return req;
};
export const fetchMessage = (id, schedule, options) => {
    return wrap(() => cacheApi.findMessageById({
        id,
        schedules: isNotBlank(schedule) ? [schedule] : []
    }, options));
};
