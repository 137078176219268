import { get } from 'svelte/store';
import { CacheApi, Configuration as CacheApiConfiguration, ConfigurationDataApi, MarketingApi, DisruptionTextSnippetApi, OrganizationApi, VehicleCategoryMappingApi, RegionApi, StationApi, DisruptionMessageApi, LineApi } from '$generated/service/cache-api';
import { config } from 'src/config/static';
import { user } from 'src/user/userStore';
import { handleHttpError } from 'src/api/httpErrorHandling';
import { Configuration as LicenseApiConfiguration, LicenseApi } from 'src/generated/service/license-api';
import { Configuration as FeedApiConfiguration, FeedManagementApi } from '$generated/service/feed-api';
const getAuthTokenValue = () => {
    const authenticatedUser = get(user);
    const token = authenticatedUser === null || authenticatedUser === void 0 ? void 0 : authenticatedUser.token;
    return token ? 'Bearer ' + token : 'undefined';
};
class HubApiMiddleware {
    pre(context) {
        const authTokenValue = getAuthTokenValue();
        return Promise.resolve({
            url: context.url,
            init: Object.assign(Object.assign({}, context.init), { headers: Object.assign(Object.assign({}, context.init.headers), { Authorization: authTokenValue }) })
        });
    }
    post(context) {
        return handleHttpError(context.response);
    }
}
export let cacheApi;
export let configurationDataApi;
export let licenseApi;
export let feedManagementApi;
export let marketingApi;
export let disruptionTextSnippetApi;
export let vehicleCategoryMappingApi;
export let organizationApi;
export let disruptionRegionApi;
export let disruptionMessageApi;
export let stationApi;
export let lineApi;
config.subscribe(conf => {
    const cacheApiConfiguration = new CacheApiConfiguration({
        basePath: conf.hubCacheApiBasePath,
        middleware: [new HubApiMiddleware()]
    });
    const licenseApiConfiguration = new LicenseApiConfiguration({
        basePath: `${conf.hubBaseUrl}${conf.licenseBasePath}`,
        middleware: [new HubApiMiddleware()]
    });
    const feedApiConfig = new FeedApiConfiguration({
        basePath: `${conf.hubBaseUrl}`,
        middleware: [new HubApiMiddleware()]
    });
    cacheApi = new CacheApi(cacheApiConfiguration);
    marketingApi = new MarketingApi(cacheApiConfiguration);
    disruptionTextSnippetApi = new DisruptionTextSnippetApi(cacheApiConfiguration);
    vehicleCategoryMappingApi = new VehicleCategoryMappingApi(cacheApiConfiguration);
    organizationApi = new OrganizationApi(cacheApiConfiguration);
    configurationDataApi = new ConfigurationDataApi(cacheApiConfiguration);
    disruptionRegionApi = new RegionApi(cacheApiConfiguration);
    disruptionMessageApi = new DisruptionMessageApi(cacheApiConfiguration);
    stationApi = new StationApi(cacheApiConfiguration);
    lineApi = new LineApi(cacheApiConfiguration);
    licenseApi = new LicenseApi(licenseApiConfiguration);
    feedManagementApi = new FeedManagementApi(feedApiConfig);
});
