<script lang="ts">
  import { _, locale } from 'svelte-i18n';
  import { DisruptionMessageCauseEnum } from '$generated/service/cache-api';
  import ScheduleSelect from 'src/components/shared/ScheduleSelect.svelte';
  import type { MessageCriteria } from './types';
  import { DropdownSelect, DateIntervalInput, defaultStringIncludesFilter } from '@pids/shared-component';
  import { QuickFilterType } from 'src/components/pages/routingService';
  import { resolveDateLocale } from 'src/config/i18n';

  export let criteria: MessageCriteria;

  const causeOptions: string[] = Object.values(DisruptionMessageCauseEnum);
</script>

<div class="columns">
  <div class="column is-one-third">
    <div class="control">
      <DateIntervalInput
        id="message-interval-filter"
        bind:fromDate={criteria.validFrom}
        bind:toDate={criteria.validTo}
        locale={resolveDateLocale($locale)}>
        <svelte:fragment slot="from-label">
          {$_('message.filter.interval.label')}
        </svelte:fragment>
      </DateIntervalInput>
    </div>
  </div>

  <div class="column is-one-quarter">
    <label for="cause-select">{$_('message.filter.cause.label')}</label>
    <div class="control">
      <DropdownSelect
        id="cause-select"
        items={causeOptions}
        bind:value={criteria.cause}
        filter={defaultStringIncludesFilter}
        placeholder={$_('select.default-placeholder')}
        empty={$_('select.empty-text')}
        readonly={true} />
    </div>
  </div>

  <div class="column is-one-quarter">
    <label for="schedule-select">
      {$_('message.filter.schedule.label')}
    </label>
    <div class="control">
      <ScheduleSelect bind:value={criteria.schedule} id="schedule-select" type={QuickFilterType.Message} />
    </div>
  </div>
</div>
