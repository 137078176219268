import { Direction } from 'src/generated/service/cache-api';
import { _ } from 'svelte-i18n';
import { get } from 'svelte/store';
export const formatLineFilter = (lf) => {
    var _a;
    return `${formatDirection(lf)}${formatLineFilterStations((_a = lf.stations) !== null && _a !== void 0 ? _a : [])}`;
};
const formatDirection = (lf) => {
    return lf.direction && lf.direction !== Direction.Both
        ? ` ${get(_)(`disruption-message.line.direction.${lf.direction.toLowerCase()}`)}`
        : '';
};
const formatLineFilterStations = (stations) => {
    if (!stations.length)
        return '';
    if (stations.length === 1)
        return `: ${stations[0].name}`;
    return `: ${stations[0].name} - ${stations[stations.length - 1].name}`;
};
const hasWhiteBg = (bg) => {
    return !!bg && ['#FFFFFF', '#FFF', 'WHITE'].includes(bg.trim().toUpperCase());
};
export const getBorder = (line) => {
    var _a, _b, _c, _d, _e;
    return (((_a = line.color) === null || _a === void 0 ? void 0 : _a.border) ||
        (hasWhiteBg((_b = line.color) === null || _b === void 0 ? void 0 : _b.background) ? ((_c = line.color) === null || _c === void 0 ? void 0 : _c.border) || ((_d = line.color) === null || _d === void 0 ? void 0 : _d.foreground) : (_e = line.color) === null || _e === void 0 ? void 0 : _e.background));
};
