<script lang="ts" context="module">
  export interface RegionData extends CreateEditRegion {
    stations?: StationBase[];
  }
</script>

<script lang="ts">
  import { _ } from 'svelte-i18n';
  import type { CreateEditRegion, Region, StationItem } from '$generated/service/cache-api';
  import { onMount } from 'svelte';
  import { deepCopy } from 'src/util/objectUtils';
  import TextInput from 'src/components/shared/TextInput.svelte';
  import { DropdownSelect, FieldControl } from '@pids/shared-component';
  import { searchStations } from 'src/services/stationService';
  import { formatStationName } from 'src/util/stationUtils';
  import { type StationBase } from 'src/generated/service/cache-api/models/StationBase';

  export let initial: Region | undefined = undefined;
  export let data: Partial<RegionData>;
  export let required: boolean = false;

  let touched: boolean = false;

  const loadOptions = async (text: string): Promise<StationBase[]> => {
    return await searchStations(text).then((stations: StationItem[]) =>
      stations.map(
        station =>
          <StationBase>{
            id: station.id,
            sloid: station.sloid,
            name: station.name,
            shortName: station.shortName
          }
      )
    );
  };

  onMount(() => {
    data = initial ? deepCopy(initial) : {};
  });
</script>

{#if data}
  <div class="is-flex is-flex-direction-column" style="gap: 2rem">
    <TextInput id="disruption-region-name" bind:value={data.name} autofocus required>
      {$_('disruption-region.dialog.config.name.label')}
    </TextInput>
    <TextInput id="disruption-region-description" bind:value={data.description} required>
      {$_('disruption-region-mapping.dialog.config.description.label')}
    </TextInput>
    <FieldControl id="station-search">
      <svelte:fragment slot="label">
        {$_('disruption-region-mapping.dialog.config.station.label')}
      </svelte:fragment>

      <svelte:fragment slot="control">
        <DropdownSelect
          id="station-search"
          multiple
          request={loadOptions}
          bind:values={data.stations}
          hasError={required && touched && (!data?.stations || data?.stations?.length === 0)}
          placeholder={$_('placeholder.search')}
          empty={$_('select.empty-text')}
          equals={(a, b) => a.sloid === b.sloid}
          on:focus={() => (touched = true)}
          let:item>
          {formatStationName(item)}
        </DropdownSelect>
      </svelte:fragment>
    </FieldControl>
  </div>
{/if}
