<script lang="ts">
  import { format } from 'date-fns';
  import { DateInput, isBlank, TimeInput } from '@pids/shared-component';
  import { resolveDateLocale } from 'src/config/i18n';
  import { locale } from 'svelte-i18n';
  import { isValid, parse } from 'date-fns';
  import { isSameDay } from 'date-fns/isSameDay';

  export let id: string;
  export let value: Date | undefined = undefined;
  export let minDate: Date | undefined = undefined;
  export let maxDate: Date | undefined = undefined;
  export let dateLabel: string | undefined = undefined;
  export let timeLabel: string | undefined = undefined;
  export let invalid: boolean = false;
  export let disabled: boolean = false;
  export let readonly: boolean = false;
  export let validateTime = true;

  export let time: string | undefined = value ? format(value, 'HH:mm') : undefined;
  let date: Date | undefined = value ? new Date(value.getTime()) : undefined;

  const updateTimeInDate = (dateVal?: Date, timeVal?: string) => {
    if (dateVal) {
      invalid = !timeVal;
      value = parseTime(timeVal, dateVal);
    } else {
      time = undefined;
      value = undefined;
      invalid = false;
    }
  };

  export const parseTime = (timeString: string | undefined, date: Date): Date => {
    if (isBlank(timeString)) return date;

    const nextDate = parse(timeString, 'HH:mm', date);
    if (!isValid(nextDate)) return date;

    return nextDate;
  };

  $: timeValue = date && parseTime(time, date);
  $: timeMin = validateTime && date && minDate && isSameDay(date, minDate) ? minDate : undefined;
  $: timeMax = validateTime && date && maxDate && isSameDay(date, maxDate) ? maxDate : undefined;

  $: isTimeValid = validateTime
    ? timeValue && (!timeMin || timeValue >= timeMin) && (!timeMax || timeValue <= timeMax)
    : true;

  $: updateTimeInDate(date, time);
</script>

<style>
  .dt-container {
    display: flex;
    gap: 1rem;
  }
</style>

<div class="dt-container">
  <div style="flex: 2">
    <DateInput
      id="{id}-date"
      bind:value={date}
      {minDate}
      {maxDate}
      {readonly}
      {disabled}
      clearable
      locale={resolveDateLocale($locale)}>
      {dateLabel}
    </DateInput>
  </div>
  <div style="flex: 1">
    <TimeInput id="{id}-time" bind:value={time} invalid={!disabled && (!isTimeValid || invalid)} {disabled} {readonly}>
      {timeLabel ?? ''}
    </TimeInput>
  </div>
</div>
