<script lang="ts" context="module">
  export type DisruptionTextSnippetEdit = TextSnippet;
  export type DisruptionTextSnippetDelete = TextSnippet;
</script>

<script lang="ts">
  import { type TextSnippet } from '$generated/service/cache-api';
  import { createEventDispatcher } from 'svelte';
  import { ActionIcon, Button, Table, TableCol } from '@pids/shared-component';
  import { _ } from 'svelte-i18n';
  import type { LoadingState } from '@pids/shared-component';
  import Restricted from 'src/components/shared/Restricted.svelte';
  import { Permission } from 'src/user/permissions';
  import { DisruptionTextSnippetSort } from 'src/components/pages/disruptionTextSnippet/disruptionTextSnippetSort';
  import { config } from 'src/config/static';
  import { routeLinks } from 'src/components/pages/routingService';
  import { getText } from 'src/util/languageUtils';
  import { navigatedFromLink } from 'src/stores/navigation';

  export let resultsList: TextSnippet[];
  export let loading: LoadingState = undefined;

  const dispatch = createEventDispatcher<{
    sort: { sort: DisruptionTextSnippetSort };
    edit: DisruptionTextSnippetEdit;
    delete: DisruptionTextSnippetDelete;
  }>();

  const dispatchSortEvent = (sort: DisruptionTextSnippetSort) => {
    dispatch('sort', { sort });
  };

  const dispatchEdit = (data: DisruptionTextSnippetEdit) => {
    dispatch('edit', data);
  };

  const dispatchDelete = (data: DisruptionTextSnippetDelete) => {
    dispatch('delete', data);
  };

  const findFirstLanguage = (row: TextSnippet | undefined) => {
    if (!row) return '';

    return disruptionLanguages.map(v => getText(v, row)).find(s => s) ?? '';
  };

  const handleEditClick = () => {
    $navigatedFromLink = true;
  };

  $: disruptionLanguages = $config.disruptionLanguages ?? [];
</script>

<style>
  .row-actions {
    display: flex;
    gap: 1rem;
  }
</style>

<Table
  rows={resultsList}
  {loading}
  let:row
  emptyText={$_('table.empty.default')}
  loadingText={$_('table.loading.default')}>
  <TableCol
    {row}
    header={$_('disruption-management.text-snippet.column.name.header-label')}
    value="name"
    width="20%"
    sortable
    onHeaderClick={() => dispatchSortEvent(DisruptionTextSnippetSort.Name)} />

  <TableCol {row} header={$_('disruption-management.text-snippet.column.text.header-label')}>
    {findFirstLanguage(row)}
  </TableCol>

  <TableCol {row} header={$_('disruption-management.text-snippet.column.actions.header-label')} width="0">
    {#if row}
      <div class="row-actions">
        <Restricted to={Permission.DISRUPTION_TEXT_SNIPPET_WRITE}>
          <Button
            icon="edit_note"
            href="#{routeLinks.disruptionTextSnippetEdit.replace(':id', `${row?.id ?? ''}`)}"
            link={true}
            compact={true}
            text={true}
            style="font-size: inherit; font-weight: inherit; gap: 0.25rem;"
            iconStyle="font-size: 1.25rem"
            on:click={() => dispatchEdit(row)}
            className="link-button">
            {$_('disruption-management.text-snippet.column.actions.edit.label')}
          </Button>
          <ActionIcon icon="delete-outline" on:click={() => dispatchDelete(row)} />

          <svelte:fragment slot="fallback">
            <Button
              icon="visibility"
              href="#{routeLinks.disruptionTextSnippetEdit.replace(':id', `${row?.id ?? ''}`)}"
              link={true}
              compact={true}
              text={true}
              style="font-size: inherit; font-weight: inherit; gap: 0.25rem;"
              iconStyle="font-size: 1.25rem"
              on:click={handleEditClick}
              className="link-button">
              {$_('disruption-management.text-snippet.column.actions.view.label')}
            </Button>
          </svelte:fragment>
        </Restricted>
      </div>
    {/if}
  </TableCol>

  <Restricted to={Permission.ORGANIZATION_READ}>
    <TableCol {row} header={$_('marketing-text.column.organization.header-label')} value="organization" width="0">
      {#if row?.organization?.name}
        {row.organization.name}
      {/if}
    </TableCol>
  </Restricted>
</Table>
