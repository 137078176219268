/* tslint:disable */
/* eslint-disable */
/**
 * Netcetera PIDS - Hub Cache API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.6.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { OrganizationFromJSON, OrganizationToJSON, } from './Organization';
import { StationBaseFromJSON, StationBaseToJSON, } from './StationBase';
/**
 * Check if a given object implements the Region interface.
 */
export function instanceOfRegion(value) {
    if (!('id' in value) || value['id'] === undefined)
        return false;
    if (!('name' in value) || value['name'] === undefined)
        return false;
    if (!('description' in value) || value['description'] === undefined)
        return false;
    if (!('stations' in value) || value['stations'] === undefined)
        return false;
    return true;
}
export function RegionFromJSON(json) {
    return RegionFromJSONTyped(json, false);
}
export function RegionFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'id': json['id'],
        'name': json['name'],
        'description': json['description'],
        'organization': json['organization'] == null ? undefined : OrganizationFromJSON(json['organization']),
        'stations': (json['stations'].map(StationBaseFromJSON)),
    };
}
export function RegionToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'id': value['id'],
        'name': value['name'],
        'description': value['description'],
        'organization': OrganizationToJSON(value['organization']),
        'stations': (value['stations'].map(StationBaseToJSON)),
    };
}
