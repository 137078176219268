<script lang="ts">
  import { Table, TableCol, ColoredLine, getLineIcon, LineIcon, LineIconContainer } from '@pids/shared-component';
  import type { TripDetails } from 'src/generated/service/cache-api';
  import { _ } from 'svelte-i18n';

  export let trips: TripDetails[];
</script>

<Table
  rows={trips}
  let:row
  borderless={true}
  emptyText={$_('table.empty.default')}
  loadingText={$_('table.loading.default')}>
  <TableCol {row} value="id" header={$_('trip.column.id.label')} />

  <TableCol {row} value="destinations" header={$_('trip.column.destination.label')} />

  <TableCol {row} header={$_('trip.column.destination.short.label')} value="shortDestination" />

  <TableCol {row} value="directionId" header={$_('trip.column.direction.label')} />

  <TableCol {row} value="line" header={$_('trip.column.line.label')} chipped>
    {#if row?.line}
      <LineIconContainer>
        <LineIcon height="1.25rem" type={getLineIcon(row.line.type)} number={row.line.number ?? ''}>
          {#if row.line.name}
            <ColoredLine
              line={row.line.name}
              background={row.line.color?.background}
              foreground={row.line.color?.foreground}
              border={row.line.color?.border} />
          {/if}
        </LineIcon>
      </LineIconContainer>
    {/if}
  </TableCol>

  <TableCol {row} header={$_('trip.column.agency.label')}>
    {#if row}
      {row.line.agency}
    {/if}
  </TableCol>

  <TableCol {row} header={$_('trip.column.schedule.label')}>
    {#if row?.schedule?.displayName}
      {row?.schedule?.displayName}
    {/if}
  </TableCol>
</Table>
