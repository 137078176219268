<script lang="ts">
  import { _ } from 'svelte-i18n';
  import { createEventDispatcher, onMount } from 'svelte';
  import { format } from 'date-fns';
  import type { FetchResult } from 'src/api/query';
  import { fetchSchedules, schedulesByPageType } from 'src/components/pages/schedule/scheduleService';
  import type { SvelteEvent } from 'src/model/event';
  import { DropdownSelect } from '@pids/shared-component';
  import { type QuickFilterType } from 'src/components/pages/routingService';
  import { type Schedule, ScheduleTypeEnum } from 'src/generated/service/cache-api';
  import { VALIDITY_DATE_FORMAT } from 'src/util/dateTimeUtils';

  /**
   * The id of the selected schedule.
   */
  export let value: string | undefined = undefined;

  export let type: QuickFilterType | undefined = undefined;

  /**
   * The id to use for the select component.
   */
  export let id: string;

  const dispatch = createEventDispatcher<{
    change: string | undefined;
  }>();

  let items: Schedule[] = [];

  onMount(async () => {
    const schedules: FetchResult<Schedule[]> = await fetchSchedules();

    if (schedules.isError) {
      return;
    }

    items = filterByType(schedules.results);
  });

  const onChange = ({ detail }: SvelteEvent<Schedule[]>) => {
    value = detail?.length === 1 ? detail[0].id : undefined;
    dispatch('change', value);
  };

  const onClear = () => {
    value = undefined;
    dispatch('change', value);
  };

  const filter = (searchText: string, items: Schedule[]): Schedule[] =>
    items.filter(item => item.id.toLowerCase().includes(searchText.toLowerCase()));

  const filterByType = (schedules: Schedule[]): Schedule[] => {
    if (!type) {
      return schedules;
    }

    const availableSchedules = schedulesByPageType[type];
    return schedules.filter(schedule => schedule.protocol && availableSchedules.includes(schedule.protocol));
  };

  const formatScheduleName = (schedule: Schedule): string => {
    let name = schedule.displayName;

    return ScheduleTypeEnum.DailySchedule === schedule.type
      ? `${name} (${format(schedule.validity.start, VALIDITY_DATE_FORMAT)})`
      : name;
  };

  $: selected = items.find(item => item.id === value);
</script>

<DropdownSelect
  bind:value={selected}
  {id}
  {items}
  {filter}
  showClearIcon
  placeholder={$_('schedule.filter.label.placeholder')}
  empty={$_('schedule.filter.label.empty')}
  on:clear={onClear}
  on:change={onChange}
  let:item>
  {formatScheduleName(item)}
</DropdownSelect>
