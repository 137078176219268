<script lang="ts">
  import TextInput from 'src/components/shared/TextInput.svelte';
  import { _ } from 'svelte-i18n';
  import type { Text } from 'src/model/Text';

  type T = $$Generic<Text>;

  export let data: T;
</script>

<style>
  .text-input-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
  }
</style>

<div>
  <p class="menu-label is-size-7 has-text-weight-semibold has-text-black mb-2">
    <slot name="group" />
  </p>
  <div class="text-input-container">
    <TextInput id="ModalTextDe" bind:value={data.textDe} required>
      <slot name="german" />
    </TextInput>
    <TextInput id="ModalTextIt" bind:value={data.textIt} required>
      <slot name="italian" />
    </TextInput>
    <TextInput id="ModalTextFr" bind:value={data.textFr} required>
      <slot name="french" />
    </TextInput>
    <TextInput id="ModalTextEn" bind:value={data.textEn} required>
      <slot name="english" />
    </TextInput>
  </div>
</div>
