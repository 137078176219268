<script lang="ts">
  import { toHtmlId } from 'src/util/htmlUtils';
  import { ClearButton, FieldControl } from '@pids/shared-component';

  export let id: string;
  export let value: string | undefined = undefined;
  export let required = false;
  export let autofocus = false;

  let interacted = false;

  const inputId = toHtmlId(id, 'text-input');

  const handleClear = () => {
    value = undefined;
  };

  $: showClear = Boolean(value);
</script>

<style lang="scss">
  @import 'src/styles/variables';

  input.invalid {
    outline-color: $alert-error !important;
    outline: 0.0625rem solid;
    outline-offset: 0.06rem;
  }

  input {
    display: inline-block;
  }

  input.clearable {
    padding-right: 1.75rem;
  }
</style>

<FieldControl id={inputId}>
  <svelte:fragment slot="label">
    <slot />
  </svelte:fragment>
  <svelte:fragment slot="control">
    <input
      id={inputId}
      class="input"
      {autofocus}
      class:invalid={interacted && required && !value}
      class:clearable={showClear}
      on:blur={() => (interacted = true)}
      bind:value
      type="text" />
    {#if showClear}
      <ClearButton on:click={handleClear} />
    {/if}
  </svelte:fragment>
</FieldControl>
