<script lang="ts">
  import type { DisruptionRegionCriteria } from 'src/components/pages/disruptionregion/disruptionRegionService';
  import { _ } from 'svelte-i18n';
  import { DebouncedTextInput, Icon } from '@pids/shared-component';
  import OrganizationFilter from 'src/components/shared/OrganizationFilter.svelte';
  import type { StationRef } from '$generated/service/cache-api';
  import DisruptionRegionStationFilter from 'src/components/pages/disruptionregion/DisruptionRegionStationFilter.svelte';
  import { Permission } from 'src/user/permissions';
  import Restricted from 'src/components/shared/Restricted.svelte';

  export let filter: DisruptionRegionCriteria;
  export let usedStations: StationRef[] = [];
</script>

<div class="columns">
  <div class="column is-one-quarter">
    <label for="text-search">
      {$_('disruption-region.filter.name.label')}
    </label>
    <div class="control has-icons-left">
      <DebouncedTextInput
        id="text-search"
        class="input"
        placeholder={$_('disruption-region.filter.name.placeholder')}
        bind:value={filter.name}
        clearable />
      <span class="icon is-left">
        <Icon name="search" style="font-size: 1.25rem;" />
      </span>
    </div>
  </div>

  <div class="column is-one-quarter">
    <DisruptionRegionStationFilter bind:stationSloid={filter.stationSloid} {usedStations}>
      {$_('disruption-region.filter.stations.label')}
    </DisruptionRegionStationFilter>
  </div>

  <Restricted to={Permission.ORGANIZATION_READ}>
    <div class="column is-one-quarter">
      <OrganizationFilter
        bind:selectedOrganizationId={filter.organizationId}
        emptyText={$_('disruption-region.filter.organization.no-items-text')}
        placeholder={$_('disruption-region.filter.organization.placeholder')}>
        {$_('disruption-region.filter.organization.label')}
      </OrganizationFilter>
    </div>
  </Restricted>
</div>
