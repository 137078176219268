export const messageParamNames = {
    schedule: 'schedule',
    cause: 'cause',
    line: 'line',
    station: 'station',
    from: 'from',
    to: 'to'
};
export const messageDetailsParamNames = {
    id: 'id',
    schedule: 'schedule'
};
/**
 * Possible sort options for messages.
 */
export var MessageSort;
(function (MessageSort) {
    MessageSort["Interval"] = "interval";
    MessageSort["Line"] = "line";
    MessageSort["Station"] = "station";
})(MessageSort || (MessageSort = {}));
