import { pop, push } from 'svelte-spa-router';
import { get, writable } from 'svelte/store';
export const navigatedFromLink = writable(false);
export const back = (link) => {
    if (get(navigatedFromLink)) {
        pop();
    }
    else {
        push(link);
    }
};
