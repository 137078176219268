/* tslint:disable */
/* eslint-disable */
/**
 * Netcetera PIDS - Hub Cache API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.6.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { OrganizationFromJSON, OrganizationToJSON, } from './Organization';
import { DisruptionScopeFromJSON, DisruptionScopeToJSON, } from './DisruptionScope';
import { DisruptionMessageCauseEnumFromJSON, DisruptionMessageCauseEnumToJSON, } from './DisruptionMessageCauseEnum';
import { StationBaseFromJSON, StationBaseToJSON, } from './StationBase';
import { DisruptionMessageLineFilterFromJSON, DisruptionMessageLineFilterToJSON, } from './DisruptionMessageLineFilter';
import { IntervalFromJSON, IntervalToJSON, } from './Interval';
import { RegionRefFromJSON, RegionRefToJSON, } from './RegionRef';
/**
 * Check if a given object implements the DisruptionMessageConfig interface.
 */
export function instanceOfDisruptionMessageConfig(value) {
    if (!('uid' in value) || value['uid'] === undefined)
        return false;
    if (!('validity' in value) || value['validity'] === undefined)
        return false;
    if (!('cause' in value) || value['cause'] === undefined)
        return false;
    if (!('active' in value) || value['active'] === undefined)
        return false;
    if (!('disruptionScope' in value) || value['disruptionScope'] === undefined)
        return false;
    if (!('organization' in value) || value['organization'] === undefined)
        return false;
    return true;
}
export function DisruptionMessageConfigFromJSON(json) {
    return DisruptionMessageConfigFromJSONTyped(json, false);
}
export function DisruptionMessageConfigFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'uid': json['uid'],
        'validity': IntervalFromJSON(json['validity']),
        'cause': DisruptionMessageCauseEnumFromJSON(json['cause']),
        'active': json['active'],
        'textEn': json['textEn'] == null ? undefined : json['textEn'],
        'textDe': json['textDe'] == null ? undefined : json['textDe'],
        'textIt': json['textIt'] == null ? undefined : json['textIt'],
        'textFr': json['textFr'] == null ? undefined : json['textFr'],
        'disruptionScope': DisruptionScopeFromJSON(json['disruptionScope']),
        'organization': OrganizationFromJSON(json['organization']),
        'stations': json['stations'] == null ? undefined : (json['stations'].map(StationBaseFromJSON)),
        'regions': json['regions'] == null ? undefined : (json['regions'].map(RegionRefFromJSON)),
        'lineFilters': json['lineFilters'] == null ? undefined : (json['lineFilters'].map(DisruptionMessageLineFilterFromJSON)),
    };
}
export function DisruptionMessageConfigToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'uid': value['uid'],
        'validity': IntervalToJSON(value['validity']),
        'cause': DisruptionMessageCauseEnumToJSON(value['cause']),
        'active': value['active'],
        'textEn': value['textEn'],
        'textDe': value['textDe'],
        'textIt': value['textIt'],
        'textFr': value['textFr'],
        'disruptionScope': DisruptionScopeToJSON(value['disruptionScope']),
        'organization': OrganizationToJSON(value['organization']),
        'stations': value['stations'] == null ? undefined : (value['stations'].map(StationBaseToJSON)),
        'regions': value['regions'] == null ? undefined : (value['regions'].map(RegionRefToJSON)),
        'lineFilters': value['lineFilters'] == null ? undefined : (value['lineFilters'].map(DisruptionMessageLineFilterToJSON)),
    };
}
