import { __awaiter } from "tslib";
import { DisruptionMessageCauseEnum, DisruptionScope } from '$generated/service/cache-api';
import { LanguageValues, getTextByLanguage, setText } from 'src/util/languageUtils';
import { disruptionMessageApi } from 'src/api/apiClients';
export const emptyDisruptionSnapshot = () => {
    const textEntries = LanguageValues.map(lang => [lang, [{ value: '' }]]);
    return {
        cause: DisruptionMessageCauseEnum.Disruption,
        active: true,
        validityStart: undefined,
        validityEnd: undefined,
        disruptionScope: DisruptionScope.Network,
        stationFilter: {
            stations: [],
            regions: []
        },
        lineFilters: [],
        texts: Object.fromEntries(textEntries)
    };
};
const mapTextSnippetsToUiModel = (c) => {
    const textsEntries = LanguageValues.map(lang => {
        var _a, _b;
        return [
            lang,
            (_b = (_a = getTextByLanguage(lang, c)) === null || _a === void 0 ? void 0 : _a.split('\n').map(v => ({ value: v }))) !== null && _b !== void 0 ? _b : [{ value: '' }]
        ];
    });
    return Object.fromEntries(textsEntries);
};
const mapDisruptionMessageConfigToUiModel = (c) => {
    var _a, _b, _c, _d, _e, _f;
    let lineFilters = [];
    if (DisruptionScope.Line === c.disruptionScope) {
        lineFilters =
            (_b = (_a = c.lineFilters) === null || _a === void 0 ? void 0 : _a.map(lf => ({
                line: lf.line,
                direction: lf.direction,
                stations: lf.stations
            }))) !== null && _b !== void 0 ? _b : [];
    }
    return {
        uid: c.uid,
        cause: c.cause,
        active: c.active,
        validityStart: (_c = c.validity) === null || _c === void 0 ? void 0 : _c.start,
        validityEnd: (_d = c.validity) === null || _d === void 0 ? void 0 : _d.end,
        disruptionScope: c.disruptionScope,
        texts: mapTextSnippetsToUiModel(c),
        stationFilter: {
            stations: (_e = c.stations) !== null && _e !== void 0 ? _e : [],
            regions: (_f = c.regions) !== null && _f !== void 0 ? _f : []
        },
        lineFilters
    };
};
const mapToApiModel = (snapshot) => {
    const data = {
        validity: {
            start: snapshot.validityStart,
            end: snapshot.validityEnd
        },
        cause: snapshot.cause,
        active: snapshot.active,
        disruptionScope: snapshot.disruptionScope
    };
    if (DisruptionScope.Station === snapshot.disruptionScope) {
        data.stationSloids = snapshot.stationFilter.stations.map(s => s.sloid);
        data.regionIds = snapshot.stationFilter.regions.map(r => r.id);
        data.lineFilters = [];
    }
    else if (DisruptionScope.Line === snapshot.disruptionScope) {
        data.lineFilters = snapshot.lineFilters.map(lf => {
            var _a, _b;
            return ({
                lineId: lf.line.id,
                direction: lf.direction,
                stationSloids: (_b = (_a = lf.stations) === null || _a === void 0 ? void 0 : _a.map(ref => ref.sloid)) !== null && _b !== void 0 ? _b : []
            });
        });
        data.stationSloids = [];
        data.regionIds = [];
    }
    else {
        data.stationSloids = [];
        data.regionIds = [];
        data.lineFilters = [];
    }
    const textEntries = Object.entries(snapshot.texts);
    textEntries.forEach(([lang, values]) => {
        const text = values.map(t => t.value).join('\n');
        setText(text, lang, data);
    });
    return data;
};
export const saveDisruptionConfig = (snapshot) => __awaiter(void 0, void 0, void 0, function* () {
    const request = mapToApiModel(snapshot);
    if (snapshot.uid) {
        return disruptionMessageApi
            .updateDisruptionMessage({
            uid: snapshot.uid,
            createEditDisruptionMessageConfig: request
        })
            .then(() => undefined);
    }
    else {
        return disruptionMessageApi
            .createDisruptionMessage({
            createEditDisruptionMessageConfig: request
        })
            .then(() => { });
    }
});
export const fetchDisruptionConfig = (uid) => __awaiter(void 0, void 0, void 0, function* () {
    const message = yield disruptionMessageApi.getDisruptionMessage({ uid });
    return mapDisruptionMessageConfigToUiModel(message);
});
