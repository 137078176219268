<script lang="ts">
  import { initializePaginationFromQueryParams, type PaginationParams } from 'src/api/query/pagination';
  import type { SortFunction } from 'src/api/query/sort';
  import type { LineCategoriesFilterCriteria } from 'src/components/pages/linecategories/lineCategoriesService';
  import type { LineCategory, Pagination } from '$generated/service/cache-api';
  import type { SvelteEvent } from 'src/model/event';
  import { writable } from 'svelte/store';
  import { onMount } from 'svelte';
  import { _ } from 'svelte-i18n';
  import { NumberedPagination, PageLayout, notificationService } from '@pids/shared-component';
  import { LineCategoriesSort } from 'src/components/pages/linecategories/lineCategoriesSort';
  import { sortBy } from 'src/api/query/sort';
  import { createQuery } from 'src/api/query/query';
  import {
    fetchLineCategories,
    lineCategoriesParamNames
  } from 'src/components/pages/linecategories/lineCategoriesService';
  import ClearFiltersButton from 'src/components/shared/ClearFiltersButton.svelte';
  import LineCategoriesTable from 'src/components/pages/linecategories/LineCategoriesTable.svelte';
  import LineCategoriesFilter from 'src/components/pages/linecategories/LineCategoriesFilter.svelte';
  import { DEFAULT_ERROR_TEXT_KEY } from 'src/api/notification';
  import type { LoadingStateStore } from '@pids/shared-component';
  import { toLineCategoryLink } from 'src/components/pages/routingService';
  import { querystring, replace } from 'svelte-spa-router';

  const initialPagination: PaginationParams = {
    page: 1,
    size: 50,
    sort: `${LineCategoriesSort.TransportType},asc`
  };

  const sortFunction: SortFunction = sortBy(LineCategoriesSort.TransportType);
  let paginationParams: PaginationParams = initialPagination;

  let filter = writable<LineCategoriesFilterCriteria>({});
  let results: LineCategory[] = [];
  let pagination: Pagination | undefined;
  let loading: LoadingStateStore;

  const resultsQuery = createQuery([], signal => fetchLineCategories($filter, paginationParams, { signal }), false);

  let clearFiltersDisabled: boolean;
  $: clearFiltersDisabled = filtersAreEmpty($filter);

  $: results = !$resultsQuery.isError ? $resultsQuery.results : [];
  $: pagination = !$resultsQuery.isError ? $resultsQuery.pagination : undefined;
  $: loading = resultsQuery.loading;

  $: $resultsQuery.isError && notificationService.error($resultsQuery.error.message ?? $_(DEFAULT_ERROR_TEXT_KEY));

  onMount(() => {
    handleQueryString($querystring);

    const unsubscribeFilter = filter.subscribe(() => {
      resetPageAndSort();
      void loadData();
    });

    return () => {
      unsubscribeFilter();
      resultsQuery.abort();
    };
  });

  const loadData = async () => {
    updateUrlParameters();
    await resultsQuery.next();
  };

  const handleSortChange = (e: SvelteEvent<{ sort: LineCategoriesSort }>) => {
    const newSort = sortFunction(e.detail.sort);

    paginationParams = {
      ...paginationParams,
      sort: newSort
    };

    void loadData();
  };

  const resetPageAndSort = () => {
    paginationParams = initialPagination;
  };

  const handlePageChange = (page: number) => {
    paginationParams = {
      ...paginationParams,
      page
    };
    void loadData();
  };

  const filtersAreEmpty = (filter: LineCategoriesFilterCriteria): boolean => {
    return !filter.category && !filter.transportGroup && !filter.transportType;
  };

  const clearFilters = () => {
    $filter = {};
  };

  const updateUrlParameters = () => {
    replace(toLineCategoryLink({ ...$filter }, paginationParams));
  };

  const handleQueryString = (query: string | undefined) => {
    if (!query) {
      return;
    }

    const params = new URLSearchParams(query);

    paginationParams = initializePaginationFromQueryParams(params, paginationParams);
    $filter = initializeCriteriaFromQueryParams(params);
  };

  const initializeCriteriaFromQueryParams = (params: URLSearchParams): LineCategoriesFilterCriteria => {
    return {
      ...$filter,
      category: params.get(lineCategoriesParamNames.category) ?? undefined,
      transportGroup: params.get(lineCategoriesParamNames.transportGroup) ?? undefined,
      transportType: params.get(lineCategoriesParamNames.transportType) ?? undefined
    };
  };
</script>

<PageLayout title={$_('routes.group.configurations.line-type-category-mapping')}>
  <svelte:fragment slot="criteria">
    <LineCategoriesFilter bind:filter={$filter} />
  </svelte:fragment>

  <svelte:fragment slot="actions">
    <ClearFiltersButton on:click={clearFilters} disabled={clearFiltersDisabled} />
  </svelte:fragment>

  <svelte:fragment slot="content">
    <LineCategoriesTable loading={$loading} resultsList={results} on:sort={handleSortChange} />
  </svelte:fragment>

  <svelte:fragment slot="pagination">
    {#if !$loading && pagination}
      <NumberedPagination {...pagination} onPageChange={handlePageChange} />
    {/if}
  </svelte:fragment>
</PageLayout>
