<script lang="ts">
  import { onDestroy, onMount } from 'svelte';
  import { _ } from 'svelte-i18n';
  import type { SvelteEvent } from 'src/model/event';
  import { ScheduleType } from 'src/model/ScheduleType';
  import { type StationOption, stationStore } from './stationStore';
  import { fetchStations } from './stationSearchService';
  import { DropdownSelect } from '@pids/shared-component';
  import ScheduleTypeSelect from './ScheduleTypeSelect.svelte';
  import { defaultAbortReason } from 'src/api/query';

  const abortController = new AbortController();

  export let stationId: string | undefined;

  let scheduleType = ScheduleType.Annual;

  onMount(() => {
    const station = $stationStore;
    if (station && station.id !== stationId) {
      $stationStore = undefined;
    }
  });

  const loadOptions = (filterText: string): Promise<StationOption[]> => {
    if (!filterText.length) {
      return Promise.resolve([]);
    }

    return fetchStations(filterText, scheduleType, { signal: abortController.signal });
  };

  const formatOption = (station: StationOption): string => {
    return `${station.name} (` + (station.shortName ? `${station.shortName}, ` : '') + `${station.id})`;
  };

  const handleStationSelect = ({ detail: [selectedStation] }: SvelteEvent<StationOption[]>): void => {
    $stationStore = selectedStation;
    stationId = selectedStation.id;
  };

  const handleStationClear = (): void => {
    $stationStore = undefined;
    stationId = undefined;
  };

  const handleScheduleTypeChange = (): void => {
    if (stationId) {
      handleStationClear();
    }
  };

  onDestroy(() => abortController.abort(defaultAbortReason([])));
</script>

<div class="columns">
  <div class="column is-one-quarter">
    <div class="field">
      <label for="station-search">
        {$_('trip.filter.station.label')}
      </label>
      <div class="control">
        <DropdownSelect
          id="station-search"
          request={loadOptions}
          value={$stationStore}
          equals={(left, right) => left.id === right.id}
          readonly={Boolean($stationStore)}
          placeholder={$_('placeholder.search')}
          empty={$_('select.empty-text')}
          on:change={handleStationSelect}
          on:clear={handleStationClear}
          let:item>
          {formatOption(item)}
        </DropdownSelect>
      </div>
    </div>
  </div>
  <div class="column is-one-quarter">
    <div class="field">
      <label for="station-search">
        {$_('trip.filter.schedule-type.label')}
      </label>
      <div class="control">
        <ScheduleTypeSelect bind:value={scheduleType} on:change={handleScheduleTypeChange} />
      </div>
    </div>
  </div>
</div>
