<script lang="ts">
  import type { MarketingTextFilterCriteria } from 'src/components/pages/marketing/marketingTextService';
  import { _, locale } from 'svelte-i18n';
  import { DebouncedTextInput, Icon, DateIntervalInput } from '@pids/shared-component';
  import Restricted from 'src/components/shared/Restricted.svelte';
  import { Permission } from 'src/user/permissions';
  import OrganizationFilter from 'src/components/shared/OrganizationFilter.svelte';
  import { resolveDateLocale } from 'src/config/i18n';

  export let filter: MarketingTextFilterCriteria;

  const marketingTextValidityId = 'marketing-text-validity';
</script>

<div class="columns">
  <div class="column">
    <label for="text-search">
      {$_('marketing-text.filter.name.label')}
    </label>
    <div class="control has-icons-left">
      <DebouncedTextInput
        id="text-search"
        class="input"
        placeholder={$_('marketing-text.filter.name.placeholder')}
        bind:value={filter.name}
        clearable />
      <span class="icon is-left">
        <Icon name="search" style="font-size: 1.25rem;" />
      </span>
    </div>
  </div>
  <div class="column">
    <div class="control">
      <DateIntervalInput
        id={marketingTextValidityId}
        bind:fromDate={filter.fromDate}
        bind:toDate={filter.toDate}
        locale={resolveDateLocale($locale)}>
        <svelte:fragment slot="from-label">
          {$_('marketing-text.filter.interval.label')}
        </svelte:fragment>
        {$_('marketing-text.filter.interval.until.label')}
      </DateIntervalInput>
    </div>
  </div>

  <Restricted to={Permission.ORGANIZATION_READ}>
    <div class="column">
      <OrganizationFilter
        bind:selectedOrganizationId={filter.organizationId}
        emptyText={$_('marketing-text.filter.organization.no-items-text')}
        placeholder={$_('marketing-text.filter.organization.placeholder')}>
        {$_('marketing-text.filter.organization.label')}
      </OrganizationFilter>
    </div>
  </Restricted>
</div>
