<script lang="ts">
  import { createEventDispatcher } from 'svelte';
  import { _ } from 'svelte-i18n';
  import { ActionIcon, Table, TableCol } from '@pids/shared-component';
  import { type DisruptionMessage, Protocol } from '$generated/service/cache-api';
  import { MessageSort } from './types';
  import { formatInterval } from './messageUtils';
  import type { LoadingState } from '@pids/shared-component';
  import { ActionButton } from '@pids/shared-component';
  import { selectedMessage } from './messageStore';
  import { toDisruptionMessageEditLink, toMessageDetailsLink } from 'src/components/pages/routingService';
  import MessageScope from 'src/components/pages/message/MessageScope.svelte';
  import { navigatedFromLink } from 'src/stores/navigation';
  import { Permission } from 'src/user/permissions';
  import Restricted from 'src/components/shared/Restricted.svelte';

  export let results: DisruptionMessage[];
  export let loading: LoadingState = undefined;

  const dispatch = createEventDispatcher<{
    sort: { sort: MessageSort };
  }>();

  const handleHeaderClick = (col: MessageSort) => {
    return () => dispatch('sort', { sort: col });
  };

  const navigateToDetails = (message: DisruptionMessage | undefined) => {
    $navigatedFromLink = true;
    selectedMessage.set(message);
  };

  const toLink = (message: DisruptionMessage | undefined) => {
    if (!message) {
      return '#';
    }

    return toMessageDetailsLink({
      id: message.id,
      schedule: message.schedule.id
    });
  };

  const handleEditClick = () => {
    $navigatedFromLink = true;
  };
</script>

<style>
  .action-col {
    display: flex;
    align-items: center;
    gap: 1rem;
  }
</style>

<Table rows={results} {loading} let:row emptyText={$_('table.empty.default')} loadingText={$_('table.loading.default')}>
  <TableCol
    {row}
    header={$_('message.column.intervals.label')}
    width="20%"
    sortable
    onHeaderClick={handleHeaderClick(MessageSort.Interval)}>
    {#if row && row.activeIntervals}
      {#each row.activeIntervals as interval}
        <p>{formatInterval(interval)}</p>
      {/each}
    {/if}
  </TableCol>

  <TableCol {row} value="cause" header={$_('message.column.cause.label')} width="14%" />

  <TableCol {row} header={$_('message.column.scope.label')} width="40%" chipped>
    {#if row}
      <MessageScope message={row} />
    {/if}
  </TableCol>

  <TableCol {row} header={$_('message.column.schedule.label')} width="15%">
    {#if row}
      {row.schedule.name}
    {/if}
  </TableCol>

  <TableCol {row}>
    {#if row}
      <div class="action-col">
        <ActionButton href={toLink(row)} icon="search" on:click={() => navigateToDetails(row)}>
          {$_('message.table.actions.view-details')}
        </ActionButton>
        {#if row.schedule.protocol === Protocol.DisruptionDirect}
          <Restricted to={Permission.DISRUPTION_MESSAGE_CONFIG_WRITE}>
            <ActionIcon icon="edit" href={toDisruptionMessageEditLink(row.id)} on:click={handleEditClick} />
            <svelte:fragment slot="fallback">
              <ActionIcon icon="visibility" href={toDisruptionMessageEditLink(row.id)} />
            </svelte:fragment>
          </Restricted>
        {/if}
      </div>
    {/if}
  </TableCol>
</Table>
