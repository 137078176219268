<script lang="ts" context="module">
  import { DisruptionMessageCauseEnum } from 'src/generated/service/cache-api';

  const CauseOptions: DisruptionMessageCauseEnum[] = Object.values(DisruptionMessageCauseEnum);
</script>

<script lang="ts">
  import type { DisruptionMessageConfigFilterCriteria } from 'src/components/pages/disruptionMessageConfig/disruptionMessageConfigService';
  import { _, locale } from 'svelte-i18n';
  import Restricted from 'src/components/shared/Restricted.svelte';
  import { Permission } from 'src/user/permissions';
  import OrganizationFilter from 'src/components/shared/OrganizationFilter.svelte';
  import { defaultStringIncludesFilter, DropdownSelect, DateIntervalInput } from '@pids/shared-component';
  import { resolveDateLocale } from 'src/config/i18n';

  export let filter: DisruptionMessageConfigFilterCriteria;

  let dateIntervalInput: DateIntervalInput | undefined;
  export const clear = () => {
    dateIntervalInput?.clear();
  };
</script>

<div class="columns">
  <div class="column is-one-third">
    <label for="disruption-config-filter-interval"> </label>
    <div class="control">
      <DateIntervalInput
        bind:this={dateIntervalInput}
        id="disruption-config-filter-interval"
        bind:fromDate={filter.fromDate}
        bind:toDate={filter.toDate}
        locale={resolveDateLocale($locale)}>
        <svelte:fragment slot="from-label">
          {$_('disruption-management.config.filter.interval.label')}
        </svelte:fragment>
        {$_('disruption-management.config.filter.interval.until.label')}
      </DateIntervalInput>
    </div>
  </div>
  <div class="column is-one-quarter">
    <label for="cause-select">{$_('disruption-management.config.filter.cause.label')}</label>
    <div class="control">
      <DropdownSelect
        id="cause-select"
        items={CauseOptions}
        bind:value={filter.cause}
        filter={defaultStringIncludesFilter}
        placeholder={$_('select.default-placeholder')}
        empty={$_('select.empty-text')}
        readonly={true} />
    </div>
  </div>

  <Restricted to={Permission.ORGANIZATION_READ}>
    <div class="column is-one-quarter">
      <OrganizationFilter
        bind:selectedOrganizationId={filter.organizationId}
        emptyText={$_('disruption-management.config.filter.organization.no-items-text')}
        placeholder={$_('disruption-management.config.filter.organization.placeholder')}>
        {$_('disruption-management.config.filter.organization.label')}
      </OrganizationFilter>
    </div>
  </Restricted>
</div>
