/* tslint:disable */
/* eslint-disable */
/**
 * PIDS Feed API
 * PIDS Feed API
 *
 * The version of the OpenAPI document: 1.6.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { TaskExecutionFromJSON, TaskExecutionToJSON, } from './TaskExecution';
import { FeedStatusFromJSON, FeedStatusToJSON, } from './FeedStatus';
/**
 * Check if a given object implements the Feed interface.
 */
export function instanceOfFeed(value) {
    if (!('id' in value) || value['id'] === undefined)
        return false;
    if (!('name' in value) || value['name'] === undefined)
        return false;
    if (!('running' in value) || value['running'] === undefined)
        return false;
    if (!('status' in value) || value['status'] === undefined)
        return false;
    if (!('config' in value) || value['config'] === undefined)
        return false;
    return true;
}
export function FeedFromJSON(json) {
    return FeedFromJSONTyped(json, false);
}
export function FeedFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'id': json['id'],
        'name': json['name'],
        'running': json['running'],
        'status': FeedStatusFromJSON(json['status']),
        'startTime': json['startTime'] == null ? undefined : (new Date(json['startTime'])),
        'stopTime': json['stopTime'] == null ? undefined : (new Date(json['stopTime'])),
        'nextRun': json['nextRun'] == null ? undefined : (new Date(json['nextRun'])),
        'lastSuccessfulRun': json['lastSuccessfulRun'] == null ? undefined : (new Date(json['lastSuccessfulRun'])),
        'lastExecution': json['lastExecution'] == null ? undefined : TaskExecutionFromJSON(json['lastExecution']),
        'config': json['config'],
    };
}
export function FeedToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'id': value['id'],
        'name': value['name'],
        'running': value['running'],
        'status': FeedStatusToJSON(value['status']),
        'startTime': value['startTime'] == null ? undefined : ((value['startTime']).toISOString()),
        'stopTime': value['stopTime'] == null ? undefined : ((value['stopTime']).toISOString()),
        'nextRun': value['nextRun'] == null ? undefined : ((value['nextRun']).toISOString()),
        'lastSuccessfulRun': value['lastSuccessfulRun'] == null ? undefined : ((value['lastSuccessfulRun']).toISOString()),
        'lastExecution': TaskExecutionToJSON(value['lastExecution']),
        'config': value['config'],
    };
}
