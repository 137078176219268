import { wrapPaginated } from 'src/api/query/fetchResult';
import { Protocol } from '$generated/service/cache-api';
import { cacheApi } from 'src/api/apiClients';
import { QuickFilterType } from 'src/components/pages/routingService';
export const fetchSchedules = (options) => {
    const func = () => cacheApi.findAllSchedules(options);
    return wrapPaginated(func);
};
export const scheduleQuickFilterOptions = {
    [Protocol.Hrdf]: [QuickFilterType.Trip, QuickFilterType.Line, QuickFilterType.Station, QuickFilterType.Agency],
    [Protocol.GtfsRealtime]: [
        QuickFilterType.Trip,
        QuickFilterType.Line,
        QuickFilterType.Message,
        QuickFilterType.Station,
        QuickFilterType.Agency
    ],
    [Protocol.GtfsStatic]: [QuickFilterType.Trip, QuickFilterType.Line, QuickFilterType.Station, QuickFilterType.Agency],
    [Protocol.Vdv454Aus]: [QuickFilterType.Trip, QuickFilterType.Line, QuickFilterType.Station, QuickFilterType.Agency],
    [Protocol.Vdv736]: [QuickFilterType.Message],
    [Protocol.DisruptionDirect]: [QuickFilterType.Message]
};
export const schedulesByPageType = {
    [QuickFilterType.Agency]: [Protocol.Hrdf, Protocol.GtfsStatic, Protocol.GtfsRealtime, Protocol.Vdv454Aus],
    [QuickFilterType.Station]: [Protocol.Hrdf, Protocol.GtfsStatic, Protocol.GtfsRealtime, Protocol.Vdv454Aus],
    [QuickFilterType.Trip]: [Protocol.Hrdf, Protocol.GtfsStatic, Protocol.GtfsRealtime, Protocol.Vdv454Aus],
    [QuickFilterType.Line]: [Protocol.Hrdf, Protocol.GtfsStatic, Protocol.GtfsRealtime, Protocol.Vdv454Aus],
    [QuickFilterType.Message]: [Protocol.Vdv736, Protocol.GtfsRealtime, Protocol.DisruptionDirect]
};
