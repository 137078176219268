<script lang="ts">
  import { getLanguage, Language } from 'src/util/languageUtils';
  import { config } from 'src/config/static';
  import { _ } from 'svelte-i18n';
  import { Button } from '@pids/shared-component';
  import { createEventDispatcher } from 'svelte';

  export let disabled = false;
  export let dialogButton = false;

  const dispatch = createEventDispatcher<{
    insertTextSnippet: void;
  }>();

  const languages: Language[] = $config.disruptionLanguages?.map(v => getLanguage(v)).filter(s => s) as Language[];

  let activeLanguage: Language = languages.find(s => s) ?? Language.German;
</script>

<style lang="scss">
  @import 'src/styles/variables';

  .tab-header {
    display: flex;
    border-bottom: 0.0625rem solid $disabled;
  }

  .tab-header.is-disabled > * {
    color: $disabled;
    box-shadow: 0 calc(0.0625rem * 1.5) 0 $disabled;
    cursor: default;
  }

  .language-label {
    color: $inactive;
    user-select: none;
    cursor: pointer;
    padding: 0 1rem;
    font-size: 1rem;
    font-weight: $font-weight-semi-bold;
    background-color: transparent;
    line-height: inherit;
    border: 0;
  }

  .language-label.active {
    color: $primary-accent;
    box-shadow: 0 calc(0.0625rem * 1.5) 0 $primary-accent;
  }

  .text-fields {
    display: flex;
    flex-direction: column;
    gap: 1.25rem;
  }

  .text-configurer {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  .text-snippet-dialog-btn {
    flex: 1;
    text-align: right;
  }
</style>

<div class="text-configurer">
  <nav class="tab-header" class:is-disabled={disabled}>
    {#each languages as languageField}
      <button
        class="language-label"
        class:active={activeLanguage === languageField}
        on:click={() => (activeLanguage = languageField)}>
        <slot name="header" language={languageField}>
          {$_(`form.text.header.language.${languageField.toLowerCase()}`)}
        </slot>
      </button>
    {/each}
    {#if dialogButton}
      <div class="text-snippet-dialog-btn">
        <Button
          text={true}
          compact={true}
          icon="feed"
          opacityDisabledStyle={true}
          className="icon-text has-text-link has-text-weight-bold"
          on:click={() => dispatch('insertTextSnippet')}
          >{$_('disruption-management.text-snippet.insert.label')}
        </Button>
      </div>
    {/if}
  </nav>
  <section class="text-fields">
    <slot language={activeLanguage} />
  </section>
</div>
