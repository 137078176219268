/* tslint:disable */
/* eslint-disable */
/**
 * Netcetera PIDS - Hub Cache API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.6.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { __awaiter } from "tslib";
import * as runtime from '../runtime';
import { OrganizationFromJSON, OrganizationResponseFromJSON, } from '../models/index';
/**
 *
 */
export class OrganizationApi extends runtime.BaseAPI {
    /**
     */
    getOrganizationByNameRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters['name'] == null) {
                throw new runtime.RequiredError('name', 'Required parameter "name" was null or undefined when calling getOrganizationByName().');
            }
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/organization/{name}`.replace(`{${"name"}}`, encodeURIComponent(String(requestParameters['name']))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => OrganizationFromJSON(jsonValue));
        });
    }
    /**
     */
    getOrganizationByName(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.getOrganizationByNameRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    getOrganizationsRaw(initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/organization`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => OrganizationResponseFromJSON(jsonValue));
        });
    }
    /**
     */
    getOrganizations(initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.getOrganizationsRaw(initOverrides);
            return yield response.value();
        });
    }
}
