<script lang="ts">
  import { onMount } from 'svelte';
  import { writable } from 'svelte/store';
  import { _ } from 'svelte-i18n';
  import { NumberedPagination, PageLayout } from '@pids/shared-component';
  import type { DisruptionMessage, Pagination } from '$generated/service/cache-api';
  import type { PaginationParams, SortFunction } from 'src/api/query';
  import { createQuery, initializePaginationFromQueryParams, sortBy } from 'src/api/query';
  import ClearFiltersButton from 'src/components/shared/ClearFiltersButton.svelte';
  import type { SvelteEvent } from 'src/model/event';
  import { fetchMessages } from './messageService';
  import { type MessageCriteria, MessageSort, messageParamNames } from './types';
  import MessageTable from './MessageTable.svelte';
  import MessageFilter from './MessageFilter.svelte';
  import type { LoadingStateStore } from '@pids/shared-component';
  import { querystring, replace } from 'svelte-spa-router';
  import { toMessagesLink } from 'src/components/pages/routingService';

  const INITIAL_SORT = MessageSort.Interval;

  const resolveSort: SortFunction = sortBy(INITIAL_SORT);

  const initialPagination: PaginationParams = {
    page: 1,
    size: 50,
    sort: `${INITIAL_SORT},asc`
  };

  let paginationParams: PaginationParams = initialPagination;

  const messageCriteria = writable<MessageCriteria>({});

  const messagesQuery = createQuery<DisruptionMessage[]>(
    [],
    signal => fetchMessages($messageCriteria, paginationParams, { signal }),
    false
  );

  let results: DisruptionMessage[];
  $: results = $messagesQuery.isError ? [] : $messagesQuery.results;

  let pagination: Pagination | undefined;
  $: pagination = $messagesQuery.isError ? undefined : $messagesQuery.pagination;

  let loading: LoadingStateStore;
  $: loading = messagesQuery.loading;

  let clearFiltersDisabled: boolean;
  $: clearFiltersDisabled = filtersAreEmpty($messageCriteria);

  onMount(() => {
    handleQueryString($querystring);

    const unsubscribeFilter = messageCriteria.subscribe(() => {
      resetPageAndSort();
      void loadData();
    });

    return () => {
      unsubscribeFilter();
      messagesQuery.abort();
    };
  });

  const handleQueryString = (query: string | undefined) => {
    if (!query) {
      return;
    }

    const params = new URLSearchParams(query);

    paginationParams = initializePaginationFromQueryParams(params, paginationParams);
    $messageCriteria = initializeCriteriaFromQueryParams(params);
  };

  const initializeCriteriaFromQueryParams = (params: URLSearchParams): MessageCriteria => {
    return {
      ...$messageCriteria,
      line: params.get(messageParamNames.line) ?? undefined,
      station: params.get(messageParamNames.station) ?? undefined,
      cause: params.get(messageParamNames.cause) ?? undefined,
      schedule: params.get(messageParamNames.schedule) ?? undefined,
      validFrom: getDateParam(params, messageParamNames.from),
      validTo: getDateParam(params, messageParamNames.to)
    };
  };

  const getDateParam = (params: URLSearchParams, name: string): Date | undefined => {
    const dateValue = params.get(name);
    return dateValue ? new Date(Date.parse(dateValue)) : undefined;
  };

  const loadData = async () => {
    updateUrlParameters();
    await messagesQuery.next();
  };

  const updateUrlParameters = () => {
    const location = toMessagesLink($messageCriteria, paginationParams);
    replace(location);
  };

  const handleSortChange = (e: SvelteEvent<{ sort: MessageSort }>) => {
    const newSort = resolveSort(e.detail.sort);

    paginationParams = {
      ...paginationParams,
      sort: newSort
    };

    void loadData();
  };

  const resetPageAndSort = () => {
    paginationParams = initialPagination;
  };

  const handlePageChange = (page: number) => {
    paginationParams = {
      ...paginationParams,
      page
    };

    void loadData();
  };

  const filtersAreEmpty = (criteria: MessageCriteria) => {
    return (
      !criteria.schedule &&
      !criteria.cause &&
      !criteria.line &&
      !criteria.station &&
      !criteria.validFrom &&
      !criteria.validTo
    );
  };

  const clearFilters = () => {
    $messageCriteria = {};
  };
</script>

<PageLayout title={$_('routes.group.core.message')}>
  <svelte:fragment slot="actions">
    <ClearFiltersButton on:click={clearFilters} disabled={clearFiltersDisabled} />
  </svelte:fragment>

  <svelte:fragment slot="criteria">
    <MessageFilter bind:criteria={$messageCriteria} />
  </svelte:fragment>

  <svelte:fragment slot="content">
    <MessageTable {results} loading={$loading} on:sort={handleSortChange} />
  </svelte:fragment>

  <svelte:fragment slot="pagination">
    {#if !$loading && pagination}
      <NumberedPagination {...pagination} onPageChange={handlePageChange} />
    {/if}
  </svelte:fragment>
</PageLayout>
