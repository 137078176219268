import { __awaiter } from "tslib";
import { wrapPaginated } from 'src/api/query';
import { applyStringParam } from 'src/api/paramUtils';
import { vehicleCategoryMappingApi } from 'src/api/apiClients';
import { errorHandlerWithDefault } from 'src/util/errorUtils';
export const vehicleCategoryMappingParamNames = {
    text: 'text',
    from: 'from',
    to: 'to'
};
export const fetchVehicleCategoryMappings = (filterCriteria, pagination, options) => {
    const params = createFilterParams(filterCriteria, pagination);
    const func = () => vehicleCategoryMappingApi.getAllVehicleCategoryMappings(params, options);
    return wrapPaginated(func);
};
export const deleteVehicleCategoryMapping = (id) => __awaiter(void 0, void 0, void 0, function* () { return yield vehicleCategoryMappingApi.deleteVehicleCategoryMapping({ id }).catch(errorHandlerWithDefault(undefined)); });
export const getVehicleCategoryMapping = (id, options) => __awaiter(void 0, void 0, void 0, function* () {
    return yield vehicleCategoryMappingApi
        .getVehicleCategoryMapping({ id }, options)
        .catch(errorHandlerWithDefault(undefined));
});
export const createVehicleCategoryMapping = (data) => __awaiter(void 0, void 0, void 0, function* () {
    return yield vehicleCategoryMappingApi
        .createVehicleCategoryMapping({
        createEditVehicleCategoryMapping: data
    })
        .catch(errorHandlerWithDefault(undefined));
});
export const updateVehicleCategoryMapping = (id, data) => __awaiter(void 0, void 0, void 0, function* () {
    return yield vehicleCategoryMappingApi
        .updateVehicleCategoryMapping({
        id,
        createEditVehicleCategoryMapping: data
    })
        .catch(errorHandlerWithDefault(undefined));
});
const createFilterParams = (filterCriteria, pagination) => {
    const request = {
        page: pagination.page,
        size: pagination.size,
        sort: [pagination.sort]
    };
    applyStringParam({
        value: filterCriteria.from,
        target: request,
        eqField: 'fromEq',
        likeField: 'fromLike'
    });
    applyStringParam({
        value: filterCriteria.to,
        target: request,
        eqField: 'toEq',
        likeField: 'toLike'
    });
    applyStringParam({
        value: filterCriteria.text,
        target: request,
        eqField: 'textEq',
        likeField: 'textLike'
    });
    return request;
};
