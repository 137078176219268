<script lang="ts">
  import { Chip, ChipList, ChipType, ColoredLine } from '@pids/shared-component';
  import { formatLineFilter, getBorder } from 'src/components/pages/message/disruptionMessageUtils';
  import { DisruptionScope, type DisruptionMessage } from 'src/generated/service/cache-api';
  import { formatStationName } from 'src/util/stationUtils';
  import { _ } from 'svelte-i18n';

  export let message: DisruptionMessage;
</script>

{#if message.scope === DisruptionScope.Network}
  {$_('disruption-management.config.column.scope.network')}
{:else if message.scope === DisruptionScope.Station}
  <ChipList>
    {#each message.stations ?? [] as station}
      <Chip type={ChipType.Readonly}>{formatStationName(station)}</Chip>
    {/each}
  </ChipList>
{:else if message.scope === DisruptionScope.Line}
  <ChipList>
    {#each message.lines ?? [] as af}
      <ColoredLine
        background={af.line?.color?.background ?? 'var(--white-darker)'}
        foreground={af.line?.color?.foreground ?? 'var(--high-emphasis)'}
        border={af.line ? `solid ${getBorder(af.line)} 0.1rem` : undefined}>
        {af.line?.number ?? af.line?.id}
        {formatLineFilter(af)}
      </ColoredLine>
    {/each}
  </ChipList>
{/if}
