<script lang="ts">
  import { hasAnyPermission, hasPermission } from 'src/user/permissionCheck';
  import type { Permission } from 'src/user/permissions';
  import { internalUserStore } from 'src/user/userStore';

  /**
   * The permissions that are required for the content to be rendered.
   */
  export let to: Permission | Permission[] = [];

  let isAllowed: boolean;
  $: isAllowed = Array.isArray(to) ? hasAnyPermission($internalUserStore, to) : hasPermission($internalUserStore, to);
</script>

{#if isAllowed}
  <slot />
{:else}
  <slot name="fallback" />
{/if}
