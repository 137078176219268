/* tslint:disable */
/* eslint-disable */
/**
 * Netcetera PIDS - Hub Cache API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.6.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { PlatformFromJSON, PlatformToJSON, } from './Platform';
import { ScheduleRefFromJSON, ScheduleRefToJSON, } from './ScheduleRef';
/**
 * Check if a given object implements the Station interface.
 */
export function instanceOfStation(value) {
    if (!('id' in value) || value['id'] === undefined)
        return false;
    if (!('name' in value) || value['name'] === undefined)
        return false;
    if (!('schedule' in value) || value['schedule'] === undefined)
        return false;
    return true;
}
export function StationFromJSON(json) {
    return StationFromJSONTyped(json, false);
}
export function StationFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'id': json['id'],
        'sloid': json['sloid'] == null ? undefined : json['sloid'],
        'name': json['name'],
        'shortName': json['shortName'] == null ? undefined : json['shortName'],
        'lat': json['lat'] == null ? undefined : json['lat'],
        'lon': json['lon'] == null ? undefined : json['lon'],
        'schedule': ScheduleRefFromJSON(json['schedule']),
        'platforms': json['platforms'] == null ? undefined : (json['platforms'].map(PlatformFromJSON)),
    };
}
export function StationToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'id': value['id'],
        'sloid': value['sloid'],
        'name': value['name'],
        'shortName': value['shortName'],
        'lat': value['lat'],
        'lon': value['lon'],
        'schedule': ScheduleRefToJSON(value['schedule']),
        'platforms': value['platforms'] == null ? undefined : (value['platforms'].map(PlatformToJSON)),
    };
}
