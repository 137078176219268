/* tslint:disable */
/* eslint-disable */
/**
 * Netcetera PIDS - Hub Cache API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.6.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { LineFromJSON, LineToJSON, } from './Line';
import { CalendarFromJSON, CalendarToJSON, } from './Calendar';
import { ScheduleFromJSON, ScheduleToJSON, } from './Schedule';
/**
 * @export
 */
export const TripDetailsTypeEnum = {
    Planned: 'PLANNED',
    Operational: 'OPERATIONAL'
};
/**
 * Check if a given object implements the TripDetails interface.
 */
export function instanceOfTripDetails(value) {
    if (!('id' in value) || value['id'] === undefined)
        return false;
    if (!('destinations' in value) || value['destinations'] === undefined)
        return false;
    if (!('line' in value) || value['line'] === undefined)
        return false;
    if (!('schedule' in value) || value['schedule'] === undefined)
        return false;
    if (!('calendars' in value) || value['calendars'] === undefined)
        return false;
    if (!('canceled' in value) || value['canceled'] === undefined)
        return false;
    if (!('type' in value) || value['type'] === undefined)
        return false;
    return true;
}
export function TripDetailsFromJSON(json) {
    return TripDetailsFromJSONTyped(json, false);
}
export function TripDetailsFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'id': json['id'],
        'shortDestination': json['shortDestination'] == null ? undefined : json['shortDestination'],
        'destinations': json['destinations'],
        'directionId': json['directionId'] == null ? undefined : json['directionId'],
        'line': LineFromJSON(json['line']),
        'schedule': ScheduleFromJSON(json['schedule']),
        'calendars': (json['calendars'].map(CalendarFromJSON)),
        'canceled': json['canceled'],
        'type': json['type'],
    };
}
export function TripDetailsToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'id': value['id'],
        'shortDestination': value['shortDestination'],
        'destinations': value['destinations'],
        'directionId': value['directionId'],
        'line': LineToJSON(value['line']),
        'schedule': ScheduleToJSON(value['schedule']),
        'calendars': (value['calendars'].map(CalendarToJSON)),
        'canceled': value['canceled'],
        'type': value['type'],
    };
}
