/* tslint:disable */
/* eslint-disable */
/**
 * Netcetera PIDS - Hub Cache API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.6.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { __awaiter } from "tslib";
import * as runtime from '../runtime';
import { CreateEditDisruptionMessageConfigToJSON, DisruptionMessageConfigFromJSON, DisruptionMessageConfigOverviewFromJSON, DisruptionMessageConfigPatchToJSON, } from '../models/index';
/**
 *
 */
export class DisruptionMessageApi extends runtime.BaseAPI {
    /**
     */
    createDisruptionMessageRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters['createEditDisruptionMessageConfig'] == null) {
                throw new runtime.RequiredError('createEditDisruptionMessageConfig', 'Required parameter "createEditDisruptionMessageConfig" was null or undefined when calling createDisruptionMessage().');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/disruption-message`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: CreateEditDisruptionMessageConfigToJSON(requestParameters['createEditDisruptionMessageConfig']),
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => DisruptionMessageConfigFromJSON(jsonValue));
        });
    }
    /**
     */
    createDisruptionMessage(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.createDisruptionMessageRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    deleteDisruptionMessageRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters['uid'] == null) {
                throw new runtime.RequiredError('uid', 'Required parameter "uid" was null or undefined when calling deleteDisruptionMessage().');
            }
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/disruption-message/{uid}`.replace(`{${"uid"}}`, encodeURIComponent(String(requestParameters['uid']))),
                method: 'DELETE',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.VoidApiResponse(response);
        });
    }
    /**
     */
    deleteDisruptionMessage(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.deleteDisruptionMessageRaw(requestParameters, initOverrides);
        });
    }
    /**
     * All disruption messages configured on the hub.
     */
    getAllDisruptionMessageConfigurationsRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            if (requestParameters['page'] != null) {
                queryParameters['page'] = requestParameters['page'];
            }
            if (requestParameters['size'] != null) {
                queryParameters['size'] = requestParameters['size'];
            }
            if (requestParameters['sort'] != null) {
                queryParameters['sort'] = requestParameters['sort'];
            }
            if (requestParameters['searchLike'] != null) {
                queryParameters['search.like'] = requestParameters['searchLike'];
            }
            if (requestParameters['searchEq'] != null) {
                queryParameters['search.eq'] = requestParameters['searchEq'];
            }
            if (requestParameters['cause'] != null) {
                queryParameters['cause'] = requestParameters['cause'];
            }
            if (requestParameters['startDate'] != null) {
                queryParameters['startDate'] = requestParameters['startDate'].toISOString().substring(0, 10);
            }
            if (requestParameters['endDate'] != null) {
                queryParameters['endDate'] = requestParameters['endDate'].toISOString().substring(0, 10);
            }
            if (requestParameters['organizationId'] != null) {
                queryParameters['organizationId'] = requestParameters['organizationId'];
            }
            const headerParameters = {};
            const response = yield this.request({
                path: `/disruption-message`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => DisruptionMessageConfigOverviewFromJSON(jsonValue));
        });
    }
    /**
     * All disruption messages configured on the hub.
     */
    getAllDisruptionMessageConfigurations() {
        return __awaiter(this, arguments, void 0, function* (requestParameters = {}, initOverrides) {
            const response = yield this.getAllDisruptionMessageConfigurationsRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    getDisruptionMessageRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters['uid'] == null) {
                throw new runtime.RequiredError('uid', 'Required parameter "uid" was null or undefined when calling getDisruptionMessage().');
            }
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/disruption-message/{uid}`.replace(`{${"uid"}}`, encodeURIComponent(String(requestParameters['uid']))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => DisruptionMessageConfigFromJSON(jsonValue));
        });
    }
    /**
     */
    getDisruptionMessage(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.getDisruptionMessageRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    patchDisruptionMessageRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters['uid'] == null) {
                throw new runtime.RequiredError('uid', 'Required parameter "uid" was null or undefined when calling patchDisruptionMessage().');
            }
            if (requestParameters['disruptionMessageConfigPatch'] == null) {
                throw new runtime.RequiredError('disruptionMessageConfigPatch', 'Required parameter "disruptionMessageConfigPatch" was null or undefined when calling patchDisruptionMessage().');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/disruption-message/{uid}`.replace(`{${"uid"}}`, encodeURIComponent(String(requestParameters['uid']))),
                method: 'PATCH',
                headers: headerParameters,
                query: queryParameters,
                body: DisruptionMessageConfigPatchToJSON(requestParameters['disruptionMessageConfigPatch']),
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => DisruptionMessageConfigFromJSON(jsonValue));
        });
    }
    /**
     */
    patchDisruptionMessage(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.patchDisruptionMessageRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    updateDisruptionMessageRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters['uid'] == null) {
                throw new runtime.RequiredError('uid', 'Required parameter "uid" was null or undefined when calling updateDisruptionMessage().');
            }
            if (requestParameters['createEditDisruptionMessageConfig'] == null) {
                throw new runtime.RequiredError('createEditDisruptionMessageConfig', 'Required parameter "createEditDisruptionMessageConfig" was null or undefined when calling updateDisruptionMessage().');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/disruption-message/{uid}`.replace(`{${"uid"}}`, encodeURIComponent(String(requestParameters['uid']))),
                method: 'PUT',
                headers: headerParameters,
                query: queryParameters,
                body: CreateEditDisruptionMessageConfigToJSON(requestParameters['createEditDisruptionMessageConfig']),
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => DisruptionMessageConfigFromJSON(jsonValue));
        });
    }
    /**
     */
    updateDisruptionMessage(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.updateDisruptionMessageRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
}
