<script lang="ts">
  import { _ } from 'svelte-i18n';
  import { pop, querystring } from 'svelte-spa-router';
  import { Button, DropdownSelect, ToggleSwitch, notificationService } from '@pids/shared-component';
  import { DisruptionMessageCauseEnum, DisruptionScope, Direction } from '$generated/service/cache-api';
  import { hasPermission } from 'src/user/permissionCheck';
  import { Permission } from 'src/user/permissions';
  import { user } from 'src/user/userStore';
  import { DEFAULT_ERROR_TEXT_KEY } from 'src/api/notification';
  import RadioGroupStack from 'src/components/shared/RadioGroupStack.svelte';
  import TextConfigurer from 'src/components/pages/disruptionTextSnippet/TextConfigurer.svelte';
  import type { DisruptionConfigSnapshot } from './types';
  import LineFilter from './line/LineFilter.svelte';
  import StationSearchInput from './StationSearchInput.svelte';
  import RegionSearchInput from './RegionSearchInput.svelte';
  import { fetchDisruptionConfig, emptyDisruptionSnapshot, saveDisruptionConfig } from './disruptionEditorService';
  import { hasNonEmptyText } from 'src/util/textUtils';
  import { back } from 'src/stores/navigation';
  import { toMessageConfigLink } from 'src/components/pages/routingService';
  import DateTimeInterval from 'src/components/pages/disruptionMessageConfig/editor/DateTimeInterval.svelte';

  const causes = Object.values(DisruptionMessageCauseEnum);
  const scopes = Object.values(DisruptionScope);

  const scopeTranslations: Record<DisruptionScope, string> = {
    [DisruptionScope.Network]: 'disruption-management.editor.scope.network',
    [DisruptionScope.Station]: 'disruption-management.editor.scope.stations-regions',
    [DisruptionScope.Line]: 'disruption-management.editor.scope.lines'
  };

  let snapshot: DisruptionConfigSnapshot;
  let startDurationInvalid = false;
  let endDurationInvalid = false;
  let hasValidityError = false;
  let stationFilterInteracted = false;
  let regionFilterInteracted = false;

  const init = async () => {
    const q = new URLSearchParams($querystring);
    const existingUid = q.get('id');

    if (existingUid) {
      try {
        snapshot = await fetchDisruptionConfig(existingUid);
      } catch (e) {
        console.error('Failed to fetch message with uid', existingUid, e);
        notificationService.error($_(DEFAULT_ERROR_TEXT_KEY));
      }
    } else {
      snapshot = emptyDisruptionSnapshot();
    }
  };

  const handleStationChange = () => {
    if (!stationFilterInteracted) {
      stationFilterInteracted = true;
    }
  };

  const handleRegionChange = () => {
    if (!regionFilterInteracted) {
      regionFilterInteracted = true;
    }
  };

  const handleScopeChange = (scope: DisruptionScope) => {
    if (DisruptionScope.Line === scope && (!snapshot.lineFilters || !snapshot.lineFilters.length)) {
      snapshot.lineFilters = [
        {
          line: undefined,
          direction: Direction.Both
        }
      ];
    }
  };

  const handleSaveClick = () => {
    saveDisruptionConfig(snapshot)
      .then(() => {
        pop();
      })
      .catch(e => {
        console.error('Failed to save disruption message', e);
        notificationService.error($_(DEFAULT_ERROR_TEXT_KEY));
      });
  };

  const handleBack = () => {
    back(toMessageConfigLink({}));
  };

  $: stationRegionFiltersEmpty =
    !snapshot || (!snapshot.stationFilter.stations.length && !snapshot.stationFilter.regions.length);
  $: hasStationRegionError =
    (stationFilterInteracted || regionFilterInteracted) &&
    snapshot &&
    DisruptionScope.Station === snapshot.disruptionScope &&
    stationRegionFiltersEmpty;
  $: lineFilterEmpty = !snapshot || snapshot.lineFilters.length === 0 || snapshot.lineFilters.some(lf => !lf.line);
  $: saveable =
    snapshot &&
    !startDurationInvalid &&
    !endDurationInvalid &&
    !hasValidityError &&
    hasNonEmptyText(snapshot.texts) &&
    (snapshot.disruptionScope === DisruptionScope.Network ||
      (DisruptionScope.Station === snapshot.disruptionScope && !stationRegionFiltersEmpty) ||
      (DisruptionScope.Line === snapshot.disruptionScope && !lineFilterEmpty));

  $: readOnlyMode = !hasPermission($user, Permission.DISRUPTION_MESSAGE_CONFIG_WRITE);

  $: $querystring, init();
  $: snapshot && handleScopeChange(snapshot.disruptionScope);
</script>

<style lang="scss">
  @import 'src/styles/variables';

  .editor-title {
    display: flex;
    gap: 1rem;
    line-height: $line-height-large;
  }

  .title-content {
    display: flex;
    gap: 0.625rem;
    padding-inline: 1rem;
  }

  .title-text {
    font-size: 1.25rem;
    font-weight: $font-weight-semi-bold;
    line-height: $line-height-large;
  }

  .form-row {
    display: grid;
    grid-template-columns: 1fr 4fr;
    gap: 1.25rem;
    align-items: center;
  }

  .form-row > label {
    font-weight: 600;
  }

  .form-row .control {
    display: block;
    flex: none;
  }

  .field-error {
    display: block;
    margin-left: 20%;
  }

  .field-error p {
    color: $danger;
    font-size: 0.875rem;
    padding-left: 0.25rem;
    padding-top: 0.25rem;
  }

  .form-container {
    display: grid;
    gap: 1.25rem;
    padding-top: 1.25rem;
  }

  .control-container-small {
    max-width: 20rem;
  }

  .button-section {
    display: flex;
    gap: 0.625rem;
  }

  .readonly {
    pointer-events: none;
  }
</style>

<div class="editor-title">
  <Button
    link={true}
    compact={true}
    text={true}
    icon="arrow-back"
    style="font-size: inherit; font-weight: inherit; gap: 0.25rem;"
    iconStyle="font-size: 1.25rem"
    className="link-button"
    on:click={handleBack}>
    <div class="title-content">
      <span class="title-text">
        {$_('disruption-management.editor.title')}{snapshot?.uid ? `: ${snapshot.uid}` : ''}
      </span>
    </div>
  </Button>
</div>

<div class="form-container">
  {#if snapshot}
    <div class="form-row" class:readonly={readOnlyMode}>
      <label for="cause-select">{$_('disruption-management.editor.field.cause')}</label>
      <div class="control control-container-small">
        <DropdownSelect
          bind:value={snapshot.cause}
          id="cause-select"
          items={causes}
          readonly={true}
          showClearIcon={false} />
      </div>
    </div>

    <div class="form-row" class:readonly={readOnlyMode}>
      <label for="message-active-switch">{$_('disruption-management.editor.field.state')}</label>
      <div class="control">
        <ToggleSwitch id="message-active-switch" bind:checked={snapshot.active} readonly={readOnlyMode} />
      </div>
    </div>

    <div class="form-row">
      <label for="message-validity">{$_('disruption-management.editor.field.duration')}</label>
      <div class="control">
        <DateTimeInterval
          id="message-validity"
          bind:snapshot
          bind:invalidEnd={endDurationInvalid}
          bind:invalidStart={startDurationInvalid}
          bind:invalid={hasValidityError}
          readonly={readOnlyMode} />
      </div>
    </div>

    {#if hasValidityError}
      <div class="field-error">
        <p>{$_('disruption-management.editor.errors.validity.invalid.time')}</p>
      </div>
    {/if}

    <div class="form-row">
      <label for="message-scope-rg">{$_('disruption-management.editor.field.scope')}</label>
      <div class="control">
        <RadioGroupStack
          id="message-scope-rg"
          options={scopes}
          readonly={readOnlyMode}
          bind:value={snapshot.disruptionScope}
          let:option>
          {$_(scopeTranslations[option])}
        </RadioGroupStack>
      </div>
    </div>

    {#if snapshot.disruptionScope === DisruptionScope.Station}
      <div class="form-row" class:readonly={readOnlyMode}>
        <label for="disruption-message-config-station-search">
          {$_('disruption-management.editor.station-filter.stations')}
        </label>
        <div class="control">
          <StationSearchInput
            id="disruption-message-config-station-search"
            readonly={readOnlyMode}
            bind:values={snapshot.stationFilter.stations}
            on:change={handleStationChange}
            hasError={hasStationRegionError} />
        </div>
      </div>
      <div class="form-row" class:readonly={readOnlyMode}>
        <label for="disruption-message-config-region-search">
          {$_('disruption-management.editor.station-filter.regions')}
        </label>
        <div class="control">
          <RegionSearchInput
            id="disruption-message-config-region-search"
            readonly={readOnlyMode}
            bind:values={snapshot.stationFilter.regions}
            on:change={handleRegionChange}
            hasError={hasStationRegionError} />
        </div>
      </div>
    {:else if snapshot.disruptionScope === DisruptionScope.Line}
      <LineFilter bind:lineFilters={snapshot.lineFilters} readOnly={readOnlyMode} />
    {/if}

    <div>
      <hr class="m-0" />

      <TextConfigurer dialogEnabled bind:data={snapshot.texts} readOnly={readOnlyMode} />
    </div>

    <div class="button-section">
      <Button primary={true} outlined={true} icon="arrow-back" on:click={handleBack}>
        {$_('button.back')}
      </Button>
      {#if !readOnlyMode}
        <Button primary={true} icon="save" disabled={!saveable} on:click={handleSaveClick}>{$_('button.save')}</Button>
      {/if}
    </div>
  {/if}
</div>
