/* tslint:disable */
/* eslint-disable */
/**
 * Netcetera PIDS - Hub Cache API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.6.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { __awaiter } from "tslib";
import * as runtime from '../runtime';
import { CreateEditTextSnippetToJSON, TextSnippetFromJSON, TextSnippetOverviewFromJSON, } from '../models/index';
/**
 *
 */
export class DisruptionTextSnippetApi extends runtime.BaseAPI {
    /**
     */
    createTextSnippetRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters['createEditTextSnippet'] == null) {
                throw new runtime.RequiredError('createEditTextSnippet', 'Required parameter "createEditTextSnippet" was null or undefined when calling createTextSnippet().');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/disruption/text-snippet`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: CreateEditTextSnippetToJSON(requestParameters['createEditTextSnippet']),
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => TextSnippetFromJSON(jsonValue));
        });
    }
    /**
     */
    createTextSnippet(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.createTextSnippetRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    deleteTextSnippetRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters['id'] == null) {
                throw new runtime.RequiredError('id', 'Required parameter "id" was null or undefined when calling deleteTextSnippet().');
            }
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/disruption/text-snippet/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
                method: 'DELETE',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.VoidApiResponse(response);
        });
    }
    /**
     */
    deleteTextSnippet(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.deleteTextSnippetRaw(requestParameters, initOverrides);
        });
    }
    /**
     */
    getAllTextSnippetsRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            if (requestParameters['searchEq'] != null) {
                queryParameters['search.eq'] = requestParameters['searchEq'];
            }
            if (requestParameters['searchLike'] != null) {
                queryParameters['search.like'] = requestParameters['searchLike'];
            }
            if (requestParameters['organizationId'] != null) {
                queryParameters['organizationId'] = requestParameters['organizationId'];
            }
            if (requestParameters['page'] != null) {
                queryParameters['page'] = requestParameters['page'];
            }
            if (requestParameters['size'] != null) {
                queryParameters['size'] = requestParameters['size'];
            }
            if (requestParameters['sort'] != null) {
                queryParameters['sort'] = requestParameters['sort'].join(runtime.COLLECTION_FORMATS["csv"]);
            }
            const headerParameters = {};
            const response = yield this.request({
                path: `/disruption/text-snippet`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => TextSnippetOverviewFromJSON(jsonValue));
        });
    }
    /**
     */
    getAllTextSnippets() {
        return __awaiter(this, arguments, void 0, function* (requestParameters = {}, initOverrides) {
            const response = yield this.getAllTextSnippetsRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    getTextSnippetRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters['id'] == null) {
                throw new runtime.RequiredError('id', 'Required parameter "id" was null or undefined when calling getTextSnippet().');
            }
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/disruption/text-snippet/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => TextSnippetFromJSON(jsonValue));
        });
    }
    /**
     */
    getTextSnippet(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.getTextSnippetRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    updateTextSnippetRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters['id'] == null) {
                throw new runtime.RequiredError('id', 'Required parameter "id" was null or undefined when calling updateTextSnippet().');
            }
            if (requestParameters['createEditTextSnippet'] == null) {
                throw new runtime.RequiredError('createEditTextSnippet', 'Required parameter "createEditTextSnippet" was null or undefined when calling updateTextSnippet().');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/disruption/text-snippet/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
                method: 'PUT',
                headers: headerParameters,
                query: queryParameters,
                body: CreateEditTextSnippetToJSON(requestParameters['createEditTextSnippet']),
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => TextSnippetFromJSON(jsonValue));
        });
    }
    /**
     */
    updateTextSnippet(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.updateTextSnippetRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
}
