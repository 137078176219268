<script lang="ts">
  import { initializePaginationFromQueryParams, type PaginationParams } from 'src/api/query/pagination';
  import type { SortFunction } from 'src/api/query/sort';
  import type { DisruptionMessageConfig, Pagination } from '$generated/service/cache-api';
  import type { SvelteEvent } from 'src/model/event';
  import { _ } from 'svelte-i18n';
  import { writable } from 'svelte/store';
  import { onMount, tick } from 'svelte';
  import { Button, NumberedPagination, PageLayout, notificationService } from '@pids/shared-component';
  import { DisruptionMessageConfigSort } from 'src/components/pages/disruptionMessageConfig/disruptionMessageConfigSort';
  import { sortBy } from 'src/api/query/sort';
  import { createQuery } from 'src/api/query/query';
  import type { DisruptionMessageConfigFilterCriteria } from 'src/components/pages/disruptionMessageConfig/disruptionMessageConfigService';
  import {
    deleteDisruptionMessageConfig,
    fetchDisruptionMessageConfig,
    getDisruptionMessageCause,
    messageConfigParamNames,
    patchDisruptionMessage
  } from 'src/components/pages/disruptionMessageConfig/disruptionMessageConfigService';
  import type {
    DisruptionMessageConfigDelete,
    DisruptionMessageConfigStateChange
  } from 'src/components/pages/disruptionMessageConfig/DisruptionMessageConfigTable.svelte';
  import DisruptionMessageConfigTable from 'src/components/pages/disruptionMessageConfig/DisruptionMessageConfigTable.svelte';
  import DisruptionMessageConfigFilter from 'src/components/pages/disruptionMessageConfig/DisruptionMessageConfigFilter.svelte';
  import { DEFAULT_ERROR_TEXT_KEY } from 'src/api/notification';
  import type { LoadingStateStore } from '@pids/shared-component';
  import DeleteModalDialog from 'src/components/shared/DeleteModalDialog.svelte';
  import { Permission } from 'src/user/permissions';
  import Restricted from 'src/components/shared/Restricted.svelte';
  import ClearFiltersButton from 'src/components/shared/ClearFiltersButton.svelte';
  import { routeLinks, toMessageConfigLink } from 'src/components/pages/routingService';
  import { parseDateParam, parseNumberParam } from 'src/api/query/criteria';
  import { querystring, replace } from 'svelte-spa-router';

  const initialPagination: PaginationParams = {
    page: 1,
    size: 50,
    sort: `${DisruptionMessageConfigSort.FromValidity},desc`
  };

  const sortFunction: SortFunction = sortBy(DisruptionMessageConfigSort.FromValidity);
  let paginationParams: PaginationParams = initialPagination;

  let filter = writable<DisruptionMessageConfigFilterCriteria>({});
  let results: DisruptionMessageConfig[] = [];
  let pagination: Pagination | undefined;
  let loading: LoadingStateStore;

  let showDeleteDialog = false;
  let disruptionMessageUid: string | undefined;

  let filterComponent: DisruptionMessageConfigFilter;

  const resultsQuery = createQuery(
    [],
    signal => fetchDisruptionMessageConfig($filter, paginationParams, { signal }),
    false
  );

  $: results = !$resultsQuery.isError ? $resultsQuery.results : [];
  $: pagination = !$resultsQuery.isError ? $resultsQuery.pagination : undefined;
  $: loading = resultsQuery.loading;

  $: $resultsQuery.isError && notificationService.error($resultsQuery.error.message ?? $_(DEFAULT_ERROR_TEXT_KEY));

  onMount(() => {
    handleQueryString($querystring);

    const unsubscribeFilter = filter.subscribe(() => {
      resetPageAndSort();
      void loadData();
    });

    return () => {
      unsubscribeFilter();
      resultsQuery.abort();
    };
  });

  const loadData = async () => {
    updateUrlParameters();
    await resultsQuery.next();
  };

  const updateUrlParameters = () => {
    replace(toMessageConfigLink({ ...$filter }, paginationParams));
  };

  const handleQueryString = (query: string | undefined) => {
    if (!query) {
      return;
    }

    const params = new URLSearchParams(query);

    paginationParams = initializePaginationFromQueryParams(params, paginationParams);
    $filter = initializeCriteriaFromQueryParams(params);
  };

  const initializeCriteriaFromQueryParams = (params: URLSearchParams): DisruptionMessageConfigFilterCriteria => {
    return {
      ...$filter,
      cause: getDisruptionMessageCause(params.get(messageConfigParamNames.cause)),
      organizationId: parseNumberParam(params.get(messageConfigParamNames.organization)),
      fromDate: parseDateParam(params.get(messageConfigParamNames.from)),
      toDate: parseDateParam(params.get(messageConfigParamNames.to))
    };
  };

  const handleSortChange = (e: SvelteEvent<{ sort: DisruptionMessageConfigSort }>) => {
    const newSort = sortFunction(e.detail.sort);

    paginationParams = {
      ...paginationParams,
      sort: newSort
    };

    void loadData();
  };

  const resetPageAndSort = () => {
    paginationParams = initialPagination;
  };

  const handlePageChange = (page: number) => {
    paginationParams = {
      ...paginationParams,
      page
    };
    void loadData();
  };

  const clearFilters = () => {
    $filter = {};
    tick().then(() => filterComponent.clear());
  };

  const handleStateChange = async (ev: SvelteEvent<DisruptionMessageConfigStateChange>) => {
    const { active, message } = ev.detail;

    const patchedMessage = await patchDisruptionMessage(message.uid, { active });

    if (!patchedMessage) {
      message.active = !active;
      results = results;
      return;
    }

    message.active = patchedMessage.active;
  };

  const openDeleteDialog = (e: SvelteEvent<DisruptionMessageConfigDelete>) => {
    showDeleteDialog = true;
    disruptionMessageUid = e.detail.uid;
  };

  const closeDeleteDialog = () => {
    showDeleteDialog = false;
    disruptionMessageUid = undefined;
  };

  const handleConfirmDelete = async () => {
    if (!disruptionMessageUid) return;

    await deleteDisruptionMessageConfig(disruptionMessageUid);

    loadData();
    closeDeleteDialog();
  };

  const filtersAreEmpty = (criteria: DisruptionMessageConfigFilterCriteria) => {
    return !criteria.cause && !criteria.fromDate && !criteria.toDate && !criteria.organizationId;
  };

  $: clearFiltersDisabled = filtersAreEmpty($filter);
</script>

<style>
  .actions-section {
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
  }
</style>

<PageLayout title={$_('disruption-management.config.list.title')}>
  <svelte:fragment slot="criteria">
    <DisruptionMessageConfigFilter bind:this={filterComponent} bind:filter={$filter} />
  </svelte:fragment>

  <svelte:fragment slot="actions">
    <div class="actions-section">
      <ClearFiltersButton on:click={clearFilters} disabled={clearFiltersDisabled} />
      <Restricted to={Permission.DISRUPTION_MESSAGE_CONFIG_WRITE}>
        <Button primary icon="plus" href="#{routeLinks.disruptionMessageConfigCreate}">
          {$_('disruption-management.config.create.label')}
        </Button>
      </Restricted>
    </div>
  </svelte:fragment>

  <svelte:fragment slot="content">
    <DisruptionMessageConfigTable
      loading={$loading}
      resultsList={results}
      on:stateChange={handleStateChange}
      on:sort={handleSortChange}
      on:delete={openDeleteDialog} />
  </svelte:fragment>

  <svelte:fragment slot="pagination">
    {#if !$loading && pagination}
      <NumberedPagination {...pagination} onPageChange={handlePageChange} />
    {/if}
  </svelte:fragment>
</PageLayout>

{#if showDeleteDialog && disruptionMessageUid}
  <DeleteModalDialog
    bind:open={showDeleteDialog}
    title={$_('disruption-management.config.delete.title')}
    name={disruptionMessageUid}
    on:confirm={handleConfirmDelete}
    on:cancel={closeDeleteDialog}>
    <svelte:fragment slot="confirm">
      {$_('disruption-management.config.delete.message.confirm')}
    </svelte:fragment>
  </DeleteModalDialog>
{/if}
