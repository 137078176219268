/* tslint:disable */
/* eslint-disable */
/**
 * Netcetera PIDS - Hub Cache API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.6.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { DirectionFromJSON, DirectionToJSON, } from './Direction';
/**
 * Check if a given object implements the CreateEditLineFilter interface.
 */
export function instanceOfCreateEditLineFilter(value) {
    if (!('lineId' in value) || value['lineId'] === undefined)
        return false;
    if (!('direction' in value) || value['direction'] === undefined)
        return false;
    return true;
}
export function CreateEditLineFilterFromJSON(json) {
    return CreateEditLineFilterFromJSONTyped(json, false);
}
export function CreateEditLineFilterFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'lineId': json['lineId'],
        'direction': DirectionFromJSON(json['direction']),
        'stationSloids': json['stationSloids'] == null ? undefined : json['stationSloids'],
    };
}
export function CreateEditLineFilterToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'lineId': value['lineId'],
        'direction': DirectionToJSON(value['direction']),
        'stationSloids': value['stationSloids'],
    };
}
