export const Language = {
    English: 'EN',
    German: 'DE',
    French: 'FR',
    Italian: 'IT'
};
export const LanguageValues = Object.values(Language);
const LanguageKey = Object.fromEntries(Object.entries(Language).map(([k, v]) => [v, k]));
const textExtractor = {
    [Language.German]: textSnippet => textSnippet.textDe,
    [Language.English]: textSnippet => textSnippet.textEn,
    [Language.French]: textSnippet => textSnippet.textFr,
    [Language.Italian]: textSnippet => textSnippet.textIt
};
const textInserter = {
    [Language.German]: (v, textSnippet) => (textSnippet.textDe = v),
    [Language.English]: (v, textSnippet) => (textSnippet.textEn = v),
    [Language.French]: (v, textSnippet) => (textSnippet.textFr = v),
    [Language.Italian]: (v, textSnippet) => (textSnippet.textIt = v)
};
export const getLanguage = (value) => {
    return value ? Language[LanguageKey[value.toUpperCase()]] : undefined;
};
export const getText = (value, data) => {
    const language = getLanguage(value);
    return getTextByLanguage(language, data);
};
export const setText = (text, language, data) => {
    return textInserter[language](text, data);
};
export const getTextByLanguage = (language, data) => {
    var _a;
    if (!language)
        return;
    return (_a = textExtractor[language]) === null || _a === void 0 ? void 0 : _a.call(textExtractor, data);
};
