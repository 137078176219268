<script lang="ts" context="module">
  export type DisruptionMessageConfigEdit = DisruptionMessageConfig;
  export type DisruptionMessageConfigDelete = DisruptionMessageConfig;
  export type DisruptionMessageConfigStateChange = {
    message: DisruptionMessageConfig;
    active: boolean;
  };
</script>

<script lang="ts">
  import { DisruptionScope, type DisruptionMessageConfig } from '$generated/service/cache-api';
  import { createEventDispatcher } from 'svelte';
  import { ActionIcon, Button, ChipList, ColoredLine, Table, TableCol } from '@pids/shared-component';
  import { _ } from 'svelte-i18n';
  import { Chip, type LoadingState, ChipType } from '@pids/shared-component';
  import { ToggleSwitch } from '@pids/shared-component';
  import Restricted from 'src/components/shared/Restricted.svelte';
  import { Permission } from 'src/user/permissions';
  import { DisruptionMessageConfigSort } from 'src/components/pages/disruptionMessageConfig/disruptionMessageConfigSort';
  import { formatInterval } from 'src/components/pages/message/messageUtils';
  import { formatStationName } from 'src/util/stationUtils';
  import { formatLineFilter, getBorder } from 'src/components/pages/message/disruptionMessageUtils';
  import { toDisruptionMessageEditLink } from 'src/components/pages/routingService';

  export let resultsList: DisruptionMessageConfig[];
  export let loading: LoadingState = undefined;

  const dispatch = createEventDispatcher<{
    sort: { sort: DisruptionMessageConfigSort };
    delete: DisruptionMessageConfigDelete;
    stateChange: DisruptionMessageConfigStateChange;
  }>();

  const dispatchSortEvent = (sort: DisruptionMessageConfigSort) => {
    dispatch('sort', { sort });
  };

  const dispatchDelete = (data: DisruptionMessageConfigDelete) => {
    dispatch('delete', data);
  };

  const dispatchStateChange = (data: DisruptionMessageConfig, value: boolean) => {
    data.active = value;
    dispatch('stateChange', {
      message: data,
      active: value
    });
  };
</script>

<style>
  .row-actions {
    display: flex;
    gap: 1rem;
  }
</style>

<Table
  rows={resultsList}
  {loading}
  let:row
  emptyText={$_('table.empty.default')}
  loadingText={$_('table.loading.default')}>
  <TableCol
    {row}
    header={$_('disruption-management.config.column.interval.header-label')}
    width="20%"
    sortable
    onHeaderClick={() => dispatchSortEvent(DisruptionMessageConfigSort.FromValidity)}>
    {#if row}
      {formatInterval(row.validity)}
    {/if}
  </TableCol>

  <TableCol {row} header={$_('disruption-management.config.column.cause.header-label')} width="14%">
    {row?.cause ?? ''}
  </TableCol>

  <TableCol
    {row}
    header={$_('disruption-management.config.column.scope.header-label')}
    chipped={row?.disruptionScope !== DisruptionScope.Network}>
    {#if row}
      {#if row.disruptionScope === DisruptionScope.Network}
        {$_('disruption-management.config.column.scope.network')}
      {:else if row.disruptionScope === DisruptionScope.Station}
        <ChipList>
          {#each row.regions ?? [] as region}
            <Chip type={ChipType.Readonly}>{region.name}</Chip>
          {/each}
          {#each row.stations ?? [] as station}
            <Chip type={ChipType.Readonly}>{formatStationName(station)}</Chip>
          {/each}
        </ChipList>
      {:else if row.disruptionScope === DisruptionScope.Line}
        <ChipList>
          {#each row.lineFilters ?? [] as lf}
            <ColoredLine
              background={lf.line?.color?.background ?? 'var(--white-darker)'}
              foreground={lf.line?.color?.foreground ?? 'var(--high-emphasis)'}
              border={lf.line ? `solid ${getBorder(lf.line)} 0.1rem` : undefined}>
              {lf.line?.number ?? lf.line?.id}
              {formatLineFilter(lf)}
            </ColoredLine>
          {/each}
        </ChipList>
      {/if}
    {/if}
  </TableCol>

  <Restricted to={Permission.DISRUPTION_MESSAGE_CONFIG_WRITE}>
    <TableCol {row} header={$_('disruption-management.config.column.toggle.header-label')} width="0">
      {#if row}
        <div class="is-flex">
          <ToggleSwitch
            id="disruption-message-active-{row.uid}"
            checked={row.active}
            on:change={ev => dispatchStateChange(row, ev.detail)}
            small></ToggleSwitch>
        </div>
      {/if}
    </TableCol>
  </Restricted>

  <TableCol {row} header={$_('disruption-management.config.column.actions.header-label')} width="0">
    {#if row}
      <div class="row-actions">
        <Restricted to={Permission.DISRUPTION_MESSAGE_CONFIG_WRITE}>
          <Button
            icon="edit_note"
            href={toDisruptionMessageEditLink(row.uid)}
            link={true}
            compact={true}
            text={true}
            style="font-size: inherit; font-weight: inherit; gap: 0.25rem;"
            iconStyle="font-size: 1.25rem"
            className="link-button">
            {$_('disruption-management.text-snippet.column.actions.edit.label')}
          </Button>
          <ActionIcon icon="delete-outline" on:click={() => dispatchDelete(row)} />

          <svelte:fragment slot="fallback">
            <Button
              icon="visibility"
              href={toDisruptionMessageEditLink(row.uid)}
              link={true}
              compact={true}
              text={true}
              style="font-size: inherit; font-weight: inherit; gap: 0.25rem;"
              iconStyle="font-size: 1.25rem"
              className="link-button">
              {$_('disruption-management.text-snippet.column.actions.view.label')}
            </Button>
          </svelte:fragment>
        </Restricted>
      </div>
    {/if}
  </TableCol>

  <Restricted to={Permission.ORGANIZATION_READ}>
    <TableCol
      {row}
      header={$_('disruption-management.organization.column.organization.header-label')}
      value="organization"
      width="0">
      {#if row?.organization?.name}
        {row.organization.name}
      {/if}
    </TableCol>
  </Restricted>
</Table>
