/* tslint:disable */
/* eslint-disable */
/**
 * Netcetera PIDS - Hub Cache API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.6.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { LineFromJSON, LineToJSON, } from './Line';
import { StationRefFromJSON, StationRefToJSON, } from './StationRef';
import { DirectionFromJSON, DirectionToJSON, } from './Direction';
/**
 * Check if a given object implements the DisruptionMessageLineFilter interface.
 */
export function instanceOfDisruptionMessageLineFilter(value) {
    return true;
}
export function DisruptionMessageLineFilterFromJSON(json) {
    return DisruptionMessageLineFilterFromJSONTyped(json, false);
}
export function DisruptionMessageLineFilterFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'line': json['line'] == null ? undefined : LineFromJSON(json['line']),
        'direction': json['direction'] == null ? undefined : DirectionFromJSON(json['direction']),
        'stations': json['stations'] == null ? undefined : (json['stations'].map(StationRefFromJSON)),
    };
}
export function DisruptionMessageLineFilterToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'line': LineToJSON(value['line']),
        'direction': DirectionToJSON(value['direction']),
        'stations': value['stations'] == null ? undefined : (value['stations'].map(StationRefToJSON)),
    };
}
