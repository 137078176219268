<script lang="ts">
  import { onMount } from 'svelte';
  import type { CreateEditVehicleCategoryMapping } from '$generated/service/cache-api';
  import { deepCopy } from 'src/util/objectUtils';
  import { _ } from 'svelte-i18n';
  import TextInput from 'src/components/shared/TextInput.svelte';
  import MessageInput from 'src/components/shared/MessageInput.svelte';

  export let initial: CreateEditVehicleCategoryMapping | undefined = undefined;
  export let data: Partial<CreateEditVehicleCategoryMapping>;

  onMount(() => {
    data = initial ? deepCopy(initial) : {};
  });
</script>

<style>
  .form-container {
    display: grid;
    gap: 2rem;
  }

  .form-side {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
  }
</style>

{#if data}
  <div class="form-container">
    <div class="form-side">
      <TextInput id="VehicleCategoryMappingFrom" bind:value={data.from} required>
        {$_('vehicle-category-mapping.dialog.config.from.label')}
      </TextInput>
      <TextInput id="VehicleCategoryMappingTo" bind:value={data.to} required>
        {$_('vehicle-category-mapping.dialog.config.to.label')}
      </TextInput>
    </div>
    <MessageInput bind:data>
      <svelte:fragment slot="group">{$_('vehicle-category-mapping.dialog.config.message')}</svelte:fragment>
      <svelte:fragment slot="german">{$_('vehicle-category-mapping.dialog.config.message.de')}</svelte:fragment>
      <svelte:fragment slot="french">{$_('vehicle-category-mapping.dialog.config.message.fr')}</svelte:fragment>
      <svelte:fragment slot="italian">{$_('vehicle-category-mapping.dialog.config.message.it')}</svelte:fragment>
      <svelte:fragment slot="english">{$_('vehicle-category-mapping.dialog.config.message.en')}</svelte:fragment>
    </MessageInput>
  </div>
{/if}
