<script lang="ts">
  import { _ } from 'svelte-i18n';
  import type { StationRef } from '$generated/service/cache-api';
  import { DropdownSelect } from '@pids/shared-component';
  import type { SvelteEvent } from 'src/model/event';

  export let stationSloid: string | undefined;
  export let usedStations: StationRef[] = [];

  const filter = (searchText: string, stations: StationRef[]) => {
    return stations.filter(station => station.name.toLowerCase().includes(searchText.toLowerCase()));
  };

  const onChange = ({ detail }: SvelteEvent<StationRef[]>) => {
    stationSloid = detail?.length === 1 ? detail[0].sloid : undefined;
  };

  const onClear = () => {
    stationSloid = undefined;
  };

  $: station = usedStations.find(value => value.sloid === stationSloid);
</script>

<label for="type-filter">
  <slot />
</label>
<div class="control">
  <DropdownSelect
    id="type-filter"
    bind:value={station}
    items={usedStations}
    {filter}
    readonly={Boolean(stationSloid)}
    equals={(a, b) => a.sloid === b.sloid}
    placeholder={$_('disruption-region.filter.stations.placeholder')}
    empty={$_('disruption-region.filter.stations.empty')}
    on:change={onChange}
    on:clear={onClear}
    let:item>
    {item.name}
  </DropdownSelect>
</div>
