<script lang="ts">
  import { ModalDialog } from '@pids/shared-component';
  import { _ } from 'svelte-i18n';
  import { type Region, type CreateEditRegion } from '$generated/service/cache-api';
  import { isNotBlank } from 'src/util/stringUtils';
  import { createEventDispatcher } from 'svelte';
  import DisruptionRegionForm, {
    type RegionData
  } from 'src/components/pages/disruptionregion/DisruptionRegionForm.svelte';
  import { isEmpty } from 'src/util/objectUtils';

  const dispatch = createEventDispatcher<{
    save: CreateEditRegion;
    close: void;
  }>();

  export let show: boolean = false;
  export let title: string = '';
  export let initial: Region | undefined = undefined;

  let data: Partial<RegionData>;

  const handleSave = () => {
    const request = toRequest(data);
    if (!request) return;
    dispatch('save', request);
  };

  const toRequest = (data: Partial<RegionData>): CreateEditRegion | undefined => {
    if (!data.description || !data.name || !data.stations?.length) return;

    return {
      name: data.name ?? '',
      description: data.description ?? '',
      stations: data.stations ?? []
    };
  };

  const handleClose = () => {
    dispatch('close');
  };

  const validate = (data: Partial<CreateEditRegion>) => {
    return Boolean(data) && isNotBlank(data.name) && isNotBlank(data.description) && !isEmpty(data.stations);
  };

  $: isValid = validate(data);
</script>

<ModalDialog
  {title}
  bind:active={show}
  okText={$_('button.ok')}
  cancelText={$_('button.cancel')}
  overflow="visible"
  showCancel={true}
  okHandler={handleSave}
  cancelHandler={handleClose}
  primaryButtonEnabled={isValid}
  width={800}>
  <DisruptionRegionForm {initial} bind:data required />
</ModalDialog>
