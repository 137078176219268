/* tslint:disable */
/* eslint-disable */
/**
 * Netcetera PIDS - Hub Cache API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.6.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { LineCategoryFromJSON, LineCategoryToJSON, } from './LineCategory';
import { ResponseMetadataFromJSON, ResponseMetadataToJSON, } from './ResponseMetadata';
/**
 * Check if a given object implements the LineCategories interface.
 */
export function instanceOfLineCategories(value) {
    if (!('metadata' in value) || value['metadata'] === undefined)
        return false;
    if (!('results' in value) || value['results'] === undefined)
        return false;
    return true;
}
export function LineCategoriesFromJSON(json) {
    return LineCategoriesFromJSONTyped(json, false);
}
export function LineCategoriesFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'metadata': ResponseMetadataFromJSON(json['metadata']),
        'results': (json['results'].map(LineCategoryFromJSON)),
    };
}
export function LineCategoriesToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'metadata': ResponseMetadataToJSON(value['metadata']),
        'results': (value['results'].map(LineCategoryToJSON)),
    };
}
