/* tslint:disable */
/* eslint-disable */
/**
 * Netcetera PIDS - Hub Cache API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.6.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * Check if a given object implements the PlannedTime interface.
 */
export function instanceOfPlannedTime(value) {
    if (!('time' in value) || value['time'] === undefined)
        return false;
    if (!('nextDay' in value) || value['nextDay'] === undefined)
        return false;
    return true;
}
export function PlannedTimeFromJSON(json) {
    return PlannedTimeFromJSONTyped(json, false);
}
export function PlannedTimeFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'time': json['time'],
        'zoneId': json['zoneId'] == null ? undefined : json['zoneId'],
        'nextDay': json['nextDay'],
    };
}
export function PlannedTimeToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'time': value['time'],
        'zoneId': value['zoneId'],
        'nextDay': value['nextDay'],
    };
}
