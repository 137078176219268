/* tslint:disable */
/* eslint-disable */
/**
 * Netcetera PIDS - Hub Cache API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.6.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { LineFromJSON, LineToJSON, } from './Line';
import { ScheduleRefFromJSON, ScheduleRefToJSON, } from './ScheduleRef';
/**
 * Check if a given object implements the Trip interface.
 */
export function instanceOfTrip(value) {
    if (!('id' in value) || value['id'] === undefined)
        return false;
    if (!('destinations' in value) || value['destinations'] === undefined)
        return false;
    if (!('line' in value) || value['line'] === undefined)
        return false;
    if (!('schedule' in value) || value['schedule'] === undefined)
        return false;
    if (!('canceled' in value) || value['canceled'] === undefined)
        return false;
    return true;
}
export function TripFromJSON(json) {
    return TripFromJSONTyped(json, false);
}
export function TripFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'id': json['id'],
        'shortDestination': json['shortDestination'] == null ? undefined : json['shortDestination'],
        'destinations': json['destinations'],
        'directionId': json['directionId'] == null ? undefined : json['directionId'],
        'line': LineFromJSON(json['line']),
        'schedule': ScheduleRefFromJSON(json['schedule']),
        'nextDay': json['nextDay'] == null ? undefined : json['nextDay'],
        'startTime': json['startTime'] == null ? undefined : json['startTime'],
        'canceled': json['canceled'],
    };
}
export function TripToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'id': value['id'],
        'shortDestination': value['shortDestination'],
        'destinations': value['destinations'],
        'directionId': value['directionId'],
        'line': LineToJSON(value['line']),
        'schedule': ScheduleRefToJSON(value['schedule']),
        'nextDay': value['nextDay'],
        'startTime': value['startTime'],
        'canceled': value['canceled'],
    };
}
