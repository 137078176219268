import enJson from 'public/i18n/lang.json';
import deJson from 'public/i18n/lang_de.json';
import frJson from 'public/i18n/lang_fr.json';
import { locale, addMessages } from 'svelte-i18n';
import { de as dtDe, enGB as dtEn, frCH as dtFr } from 'date-fns/locale';
import { get } from 'svelte/store';
const Locale = {
    English: 'en',
    German: 'de',
    French: 'fr'
};
const LocaleKey = Object.fromEntries(Object.entries(Locale).map(([k, v]) => [v, k]));
const DateLocale = {
    [Locale.German]: dtDe,
    [Locale.English]: dtEn,
    [Locale.French]: dtFr
};
export const resolveDateLocale = (value) => {
    const locale = resolveLocale(value);
    return locale && DateLocale[locale];
};
const resolveLocale = (value) => {
    return value ? Locale[LocaleKey[value.toLowerCase()]] : undefined;
};
//adding locale json files to dictionary
addMessages('en', enJson);
addMessages('de', deJson);
addMessages('fr', frJson);
export const registerLocale = (userLocale) => {
    if (get(locale) === userLocale) {
        return;
    }
    locale.set(userLocale);
};
