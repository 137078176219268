import { wrapPaginated } from 'src/api/query/fetchResult';
import { cacheApi } from 'src/api/apiClients';
import { applyStringParam } from 'src/api/paramUtils';
import { isNotBlank } from 'src/util/stringUtils';
export const agencyParamNames = {
    id: 'id',
    schedule: 'schedule',
    said: 'said',
    fullname: 'fullname'
};
export const fetchAgencies = (filter, pagination, options) => {
    const params = createFilterParams(filter, pagination);
    const func = () => cacheApi.findAgencies(params, options);
    return wrapPaginated(func);
};
const createFilterParams = (filterCriteria, pagination) => {
    const request = {};
    applyStringParam({ value: filterCriteria.id, target: request, eqField: 'idEq', likeField: 'idLike' });
    applyStringParam({
        value: filterCriteria.fullname,
        target: request,
        eqField: 'fullnameEq',
        likeField: 'fullnameLike'
    });
    applyStringParam({ value: filterCriteria.said, target: request, eqField: 'saidEq', likeField: 'saidLike' });
    if (filterCriteria.schedules && filterCriteria.schedules.length > 0) {
        request.schedules = filterCriteria.schedules;
    }
    if (filterCriteria.schedule && isNotBlank(filterCriteria.schedule)) {
        request.schedules = [filterCriteria.schedule];
    }
    request.page = pagination.page;
    request.size = pagination.size;
    request.sort = pagination.sort;
    return request;
};
