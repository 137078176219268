/* tslint:disable */
/* eslint-disable */
/**
 * Netcetera PIDS - Hub Cache API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.6.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { __awaiter } from "tslib";
import * as runtime from '../runtime';
import { LineFromJSON, LineVariantsFromJSON, } from '../models/index';
/**
 *
 */
export class LineApi extends runtime.BaseAPI {
    /**
     * Get the different variants of a line based on all available line variants
     */
    findLineVariantsRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters['lineId'] == null) {
                throw new runtime.RequiredError('lineId', 'Required parameter "lineId" was null or undefined when calling findLineVariants().');
            }
            const queryParameters = {};
            if (requestParameters['lineId'] != null) {
                queryParameters['lineId'] = requestParameters['lineId'];
            }
            const headerParameters = {};
            const response = yield this.request({
                path: `/line/variant`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => LineVariantsFromJSON(jsonValue));
        });
    }
    /**
     * Get the different variants of a line based on all available line variants
     */
    findLineVariants(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.findLineVariantsRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     * Get all lines from all schedules
     */
    getAllLinesRaw(initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/line/all`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(LineFromJSON));
        });
    }
    /**
     * Get all lines from all schedules
     */
    getAllLines(initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.getAllLinesRaw(initOverrides);
            return yield response.value();
        });
    }
}
