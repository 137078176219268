/* tslint:disable */
/* eslint-disable */
/**
 * Netcetera PIDS - Hub Cache API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.6.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { LineFromJSON, LineToJSON, } from './Line';
import { AffectedStationFromJSON, AffectedStationToJSON, } from './AffectedStation';
import { DirectionFromJSON, DirectionToJSON, } from './Direction';
/**
 * Check if a given object implements the AffectedLine interface.
 */
export function instanceOfAffectedLine(value) {
    if (!('line' in value) || value['line'] === undefined)
        return false;
    if (!('direction' in value) || value['direction'] === undefined)
        return false;
    if (!('stations' in value) || value['stations'] === undefined)
        return false;
    return true;
}
export function AffectedLineFromJSON(json) {
    return AffectedLineFromJSONTyped(json, false);
}
export function AffectedLineFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'line': LineFromJSON(json['line']),
        'direction': DirectionFromJSON(json['direction']),
        'stations': (json['stations'].map(AffectedStationFromJSON)),
    };
}
export function AffectedLineToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'line': LineToJSON(value['line']),
        'direction': DirectionToJSON(value['direction']),
        'stations': (value['stations'].map(AffectedStationToJSON)),
    };
}
