<script lang="ts">
  import { _ } from 'svelte-i18n';
  import { DebouncedTextInput, Icon, DropdownSelect, defaultStringIncludesFilter } from '@pids/shared-component';
  import type { TripFilter as TripFilterModel } from '$generated/service/cache-api';
  import ScheduleSelect from 'src/components/shared/ScheduleSelect.svelte';
  import type { SvelteEvent } from 'src/model/event';
  import { quoted } from 'src/util/stringUtils';
  import type { TripCriteria } from './types';
  import { QuickFilterType } from 'src/components/pages/routingService';

  export let tripCriteria: TripCriteria;

  export let filterData: TripFilterModel;

  /**
   * Callback invoked when a schedule is selected.
   */
  export let onScheduleChange: (s: string | undefined) => void;

  // all available agencies in the selected schedule
  let agencies: string[];
  $: agencies = filterData.agencies ?? [];

  // keep a separate value for the agency because the value in the criteria object is in quotes
  let agencyValue: string | undefined;
  $: agencyValue = tripCriteria.agency ? tripCriteria.agency.substring(1, tripCriteria.agency.length - 1) : undefined;

  const handleAgencySelect = ({ detail: [agencyName] }: SvelteEvent<string[]>) => {
    if (agencyName && agencyName.length) {
      tripCriteria.agency = quoted(agencyName);
    }
  };
</script>

<div class="columns">
  <div class="column">
    <label for="line-search">
      {$_('trip.filter.line.label')}
    </label>
    <div class="control has-icons-left">
      <DebouncedTextInput
        id="line-search"
        class="input"
        bind:value={tripCriteria.line}
        placeholder={$_('placeholder.search')}
        clearable />
      <span class="icon is-left">
        <Icon name="search" style="font-size: 1.25rem;" />
      </span>
    </div>
  </div>

  <div class="column">
    <label for="line-id-search">
      {$_('trip.filter.line-id.label')}
    </label>
    <div class="control has-icons-left">
      <DebouncedTextInput
        id="line-id-search"
        class="input"
        bind:value={tripCriteria.lineId}
        placeholder={$_('placeholder.search')}
        clearable />
      <span class="icon is-left">
        <Icon name="search" style="font-size: 1.25rem;" />
      </span>
    </div>
  </div>

  <div class="column">
    <label for="destination-search">
      {$_('trip.filter.destination.label')}
    </label>
    <div class="control has-icons-left">
      <DebouncedTextInput
        id="destination-search"
        class="input"
        bind:value={tripCriteria.destination}
        placeholder={$_('placeholder.search')}
        clearable />
      <span class="icon is-left">
        <Icon name="search" style="font-size: 1.25rem;" />
      </span>
    </div>
  </div>

  <div class="column">
    <label for="agency-search">
      {$_('trip.filter.agency.label')}
    </label>
    <div class="control">
      <DropdownSelect
        id="agency-search"
        items={agencies}
        filter={defaultStringIncludesFilter}
        value={agencyValue}
        placeholder={$_('select.default-placeholder')}
        empty={$_('select.empty-text')}
        readonly={Boolean(agencyValue)}
        on:change={handleAgencySelect}
        on:clear={() => (tripCriteria.agency = undefined)} />
    </div>
  </div>

  <div class="column">
    <label for="id-search">
      {$_('trip.filter.id.label')}
    </label>
    <div class="control has-icons-left">
      <DebouncedTextInput
        id="id-search"
        class="input"
        bind:value={tripCriteria.id}
        placeholder={$_('placeholder.search')}
        clearable />
      <span class="icon is-left">
        <Icon name="search" style="font-size: 1.25rem;" />
      </span>
    </div>
  </div>

  <div class="column">
    <label for="schedule-select">
      {$_('trip.filter.schedule.label')}
    </label>
    <div class="control">
      <ScheduleSelect
        value={tripCriteria.schedule}
        id="schedule-select"
        on:change={e => onScheduleChange(e.detail)}
        type={QuickFilterType.Trip} />
    </div>
  </div>
</div>
