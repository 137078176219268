<script lang="ts">
  import { _ } from 'svelte-i18n';
  import {
    Button,
    type LoadingStateStore,
    notificationService,
    NumberedPagination,
    PageLayout
  } from '@pids/shared-component';
  import { writable } from 'svelte/store';
  import {
    createDisruptionRegion,
    type DisruptionRegionCriteria,
    disruptionRegionParamNames,
    fetchDisruptionRegion,
    fetchUsedStations,
    getDisruptionRegion,
    updateDisruptionRegion
  } from 'src/components/pages/disruptionregion/disruptionRegionService';
  import DisruptionRegionFilter from 'src/components/pages/disruptionregion/DisruptionRegionFilter.svelte';
  import type { CreateEditRegion, Pagination, Region, StationRef } from '$generated/service/cache-api';
  import {
    createQuery,
    initializePaginationFromQueryParams,
    type PaginationParams,
    sortBy,
    type SortFunction
  } from 'src/api/query';
  import { DisruptionRegionSort } from 'src/components/pages/disruptionregion/disruptionRegionSort';
  import { DEFAULT_ERROR_TEXT_KEY } from 'src/api/notification';
  import { onMount } from 'svelte';
  import DisruptionRegionTable from 'src/components/pages/disruptionregion/DisruptionRegionTable.svelte';
  import type { SvelteEvent } from 'src/model/event';
  import DisruptionRegionModalDialog from 'src/components/pages/disruptionregion/DisruptionRegionModalDialog.svelte';
  import DeleteModalDialog from 'src/components/shared/DeleteModalDialog.svelte';
  import ClearFiltersButton from 'src/components/shared/ClearFiltersButton.svelte';
  import { isBlank } from 'src/util/stringUtils';
  import { deleteDisruptionRegion } from 'src/components/pages/disruptionregion/disruptionRegionService.js';
  import Restricted from 'src/components/shared/Restricted.svelte';
  import { Permission } from 'src/user/permissions';
  import { querystring, replace } from 'svelte-spa-router';
  import { toDisruptionRegionLink } from 'src/components/pages/routingService';
  import { parseNumberParam } from 'src/api/query/criteria';

  const initialPagination: PaginationParams = {
    page: 1,
    size: 50,
    sort: `${DisruptionRegionSort.Name},asc`
  };
  const sortFunction: SortFunction = sortBy(DisruptionRegionSort.Name);

  let filter = writable<DisruptionRegionCriteria>({});
  let loading: LoadingStateStore;
  let pagination: Pagination | undefined;
  let paginationParams: PaginationParams = { ...initialPagination };

  let usedStations: StationRef[] = [];
  let disruptionRegion: Region | undefined = undefined;
  let disruptionRegionData: CreateEditRegion | undefined = undefined;

  let showSaveDialog = false;
  let showDeleteDialog = false;

  const resultsQuery = createQuery([], signal => fetchDisruptionRegion($filter, paginationParams, { signal }), false);

  $: results = !$resultsQuery.isError ? $resultsQuery.results : [];
  $: pagination = !$resultsQuery.isError ? $resultsQuery.pagination : undefined;
  $: loading = resultsQuery.loading;

  $: $resultsQuery.isError && notificationService.error($resultsQuery.error.message ?? $_(DEFAULT_ERROR_TEXT_KEY));
  $: clearFiltersDisabled = filtersAreEmpty($filter);

  const filtersAreEmpty = (filter: DisruptionRegionCriteria) => {
    return isBlank(filter.name) && isBlank(filter.stationSloid) && !Boolean(filter.organizationId);
  };

  const handlePageChange = (page: number) => {
    Object.assign(paginationParams, { page });
    void loadData();
  };

  const loadData = async () => {
    updateUrlParameters();
    await resultsQuery.next();
  };

  const openSaveDialog = async (region?: Region) => {
    if (region) {
      const { id } = region;
      disruptionRegion = await getDisruptionRegion(id);
      if (!disruptionRegion) return;
      const { name, description, stations } = disruptionRegion;
      disruptionRegionData = { name, description, stations };
    }

    showSaveDialog = true;
  };

  const closeSaveDialog = () => {
    showSaveDialog = false;
    disruptionRegion = undefined;
    disruptionRegionData = undefined;
  };

  const clearFilters = () => {
    $filter = {};
  };

  const resetPageAndSort = () => {
    paginationParams = { ...initialPagination };
  };

  const handleSortChange = ({ detail }: SvelteEvent<DisruptionRegionSort>) => {
    const newSort = sortFunction(detail);

    paginationParams = {
      ...paginationParams,
      sort: newSort
    };

    void loadData();
  };

  const handleSave = async ({ detail }: SvelteEvent<CreateEditRegion>) => {
    const data = await saveDisruptionRegion(detail);
    if (data) void loadData();

    usedStations = await fetchUsedStations();
    closeSaveDialog();
  };

  const saveDisruptionRegion = async (data: CreateEditRegion) => {
    if (disruptionRegion) {
      return updateDisruptionRegion(disruptionRegion.id, data);
    }

    return createDisruptionRegion(data);
  };

  const handleConfirmDelete = async () => {
    if (!disruptionRegion || !disruptionRegion.id) return;

    const result = await deleteDisruptionRegion(disruptionRegion.id);

    if (result.ok) {
      usedStations = await fetchUsedStations();
      void loadData();
      closeDeleteDialog();
    } else if (result.conflict) {
      notificationService.error($_('disruption-region.dialog.delete.conflict'));
    } else {
      notificationService.error($_(DEFAULT_ERROR_TEXT_KEY));
    }
  };

  const closeDeleteDialog = () => {
    showDeleteDialog = false;
    disruptionRegion = undefined;
  };

  const openDeleteDialog = ({ detail }: SvelteEvent<Region>) => {
    showDeleteDialog = true;
    disruptionRegion = detail;
  };

  onMount(() => {
    handleQueryString($querystring);

    fetchUsedStations().then(stations => (usedStations = stations));
    const unsubscribeFilter = filter.subscribe(() => {
      resetPageAndSort();
      void loadData();
    });

    return () => {
      unsubscribeFilter();
      resultsQuery.abort();
    };
  });

  const updateUrlParameters = () => {
    replace(toDisruptionRegionLink({ ...$filter }, paginationParams));
  };

  const handleQueryString = (query: string | undefined) => {
    if (!query) {
      return;
    }

    const params = new URLSearchParams(query);

    paginationParams = initializePaginationFromQueryParams(params, paginationParams);
    $filter = initializeCriteriaFromQueryParams(params);
  };

  const initializeCriteriaFromQueryParams = (params: URLSearchParams): DisruptionRegionCriteria => {
    return {
      ...$filter,
      name: params.get(disruptionRegionParamNames.name) ?? undefined,
      organizationId: parseNumberParam(params.get(disruptionRegionParamNames.organization)),
      stationSloid: params.get(disruptionRegionParamNames.station) ?? undefined
    };
  };
</script>

<style>
  .actions-section {
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
  }
</style>

<PageLayout title={$_('routes.group.disruption-management.disruption-region.title')}>
  <svelte:fragment slot="actions">
    <div class="actions-section">
      <ClearFiltersButton on:click={clearFilters} disabled={clearFiltersDisabled} />
      <Restricted to={Permission.DISRUPTION_REGION_WRITE}>
        <Button primary icon="plus" on:click={() => openSaveDialog()}>
          {$_('disruption-region.action.create.label')}
        </Button>
      </Restricted>
    </div>
  </svelte:fragment>

  <svelte:fragment slot="criteria">
    <DisruptionRegionFilter bind:filter={$filter} {usedStations} />
  </svelte:fragment>

  <svelte:fragment slot="content">
    <DisruptionRegionTable
      loading={$loading}
      resultsList={results}
      on:sort={handleSortChange}
      on:delete={openDeleteDialog}
      on:edit={({ detail }) => openSaveDialog(detail)} />
  </svelte:fragment>

  <svelte:fragment slot="pagination">
    {#if !$loading && pagination}
      <NumberedPagination {...pagination} onPageChange={handlePageChange} />
    {/if}
  </svelte:fragment>
</PageLayout>

{#if showSaveDialog}
  <DisruptionRegionModalDialog
    title={disruptionRegionData
      ? $_('disruption-region.dialog.config.title.edit')
      : $_('disruption-region.dialog.config.title.create')}
    bind:show={showSaveDialog}
    on:close={closeSaveDialog}
    on:save={handleSave}
    initial={disruptionRegion} />
{/if}

{#if showDeleteDialog && disruptionRegion}
  <DeleteModalDialog
    bind:open={showDeleteDialog}
    title={$_('disruption-region.dialog.delete.title')}
    name={disruptionRegion.name}
    on:confirm={handleConfirmDelete}
    on:cancel={closeDeleteDialog}>
    <svelte:fragment slot="confirm">
      {$_('disruption-region.dialog.delete.confirm')}
    </svelte:fragment>
  </DeleteModalDialog>
{/if}
