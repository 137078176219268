import { format } from 'date-fns';
import { isNotBlank } from 'src/util/stringUtils';
import { tripDetailsParamName, tripParamName } from 'src/components/pages/trips/types';
import { lineParamNames } from 'src/components/pages/line/lineService';
import { paginationParamName } from 'src/api/query';
import { messageDetailsParamNames, messageParamNames } from 'src/components/pages/message/types';
import { stationDetailsParamNames, stationParamNames } from 'src/components/pages/station/stationService';
import { ISO_DATE_FORMAT } from 'src/util/dateTimeUtils';
import { agencyParamNames } from 'src/components/pages/agency/agencyService';
import { messageConfigParamNames } from './disruptionMessageConfig/disruptionMessageConfigService';
import { disruptionRegionParamNames } from 'src/components/pages/disruptionregion/disruptionRegionService';
import { disruptionMessageTextSnippetParamNames } from 'src/components/pages/disruptionTextSnippet/disruptionTextSnippetService';
import { servicePointParamNames } from 'src/components/pages/servicePoint/servicePointService';
import { lineCategoriesParamNames } from 'src/components/pages/linecategories/lineCategoriesService';
import { lineColorParamNames } from 'src/components/pages/lineColor/lineColorService';
import { vehicleCategoryMappingParamNames } from 'src/components/pages/vehiclecategorymapping/vehicleCategoryMappingService';
import { marketingTextParamNames } from 'src/components/pages/marketing/marketingTextService';
import { disruptionCausesParamNames } from 'src/components/pages/disruptionCausesVdv/disruptionCausesService';
export var QuickFilterType;
(function (QuickFilterType) {
    QuickFilterType["Trip"] = "TRIP";
    QuickFilterType["Line"] = "LINE";
    QuickFilterType["Message"] = "MESSAGE";
    QuickFilterType["Station"] = "STATION";
    QuickFilterType["Agency"] = "AGENCY";
    QuickFilterType["MessageConfig"] = "MESSAGE_CONFIG";
})(QuickFilterType || (QuickFilterType = {}));
export const routeLinks = {
    // core data
    schedule: '/schedule/',
    agency: '/agency/',
    line: '/line/',
    station: '/station/',
    stationDetail: '/station/details/',
    trip: '/trip/',
    tripDetails: '/trip/details/',
    tripsByStation: '/trips-by-station/',
    message: '/message/',
    messageDetails: '/message/details/',
    // disruption management
    disruptionMessageConfig: '/disruption-message-config/',
    disruptionMessageConfigCreate: '/disruption-message-config/create/',
    disruptionMessageConfigEdit: '/disruption-message-config/edit/',
    disruptionRegion: '/disruption-region/',
    disruptionTextSnippet: '/disruption-text-snippet/',
    disruptionTextSnippetCreate: '/disruption-text-snippet/create/',
    disruptionTextSnippetEdit: '/disruption-text-snippet/edit/:id',
    // config data
    marketing: '/marketing/',
    lineCategory: '/line-category/',
    lineColor: '/line-color/',
    lineType: '/line-type/',
    vehicleCategoryMapping: '/vehicle-category-mapping/',
    vdvDisruptionCause: '/vdv-disruption-cause/',
    servicePoint: '/service-point/',
    feedManagement: '/feed/'
};
export const toLink = (queryCriteria) => {
    switch (queryCriteria.type) {
        case QuickFilterType.Trip:
            return toTripsLink(queryCriteria.data);
        case QuickFilterType.Line:
            return toLinesLink(queryCriteria.data);
        case QuickFilterType.Message:
            return toMessagesLink(queryCriteria.data);
        case QuickFilterType.Station:
            return toStationsLink(queryCriteria.data);
    }
};
/**
 * Creates a link to the trips page with the specified filtering criteria & pagination.
 *
 * @param params the filtering criteria
 * @param paginationParams the pagination params.
 */
export const toTripsLink = (params, paginationParams) => {
    const query = createTripQueryParams(params, paginationParams);
    return `#${routeLinks.trip}?${query.toString()}`;
};
/**
 * Creates a link to the trips page with the specified filtering criteria & pagination.
 *
 * @param params the filtering criteria
 * @param paginationParams the pagination params.
 */
export const toStationsLink = (params, paginationParams) => {
    const query = createStationQueryParams(params, paginationParams);
    return `#${routeLinks.station}?${query.toString()}`;
};
/**
 * Creates a link to the trips page with the specified filtering criteria.
 *
 * @param params the filtering criteria
 */
export const toTripDetailsLink = (params) => {
    const query = createTripDetailsQueryParams(params);
    return `#${routeLinks.tripDetails}?${query.toString()}`;
};
/**
 * Creates a link to the agency page with the specified filtering criteria.
 *
 * @param params the filtering criteria
 * @param paginationParams the pagination parameters
 */
export const toAgencyLink = (params, paginationParams) => {
    const query = createAgencyQueryParams(params, paginationParams);
    return `#${routeLinks.agency}?${query.toString()}`;
};
export const createAgencyQueryParams = (params, paginationParams) => {
    const query = new URLSearchParams();
    if (isNotBlank(params.id)) {
        query.set(agencyParamNames.id, params.id);
    }
    if (isNotBlank(params.schedule)) {
        query.set(agencyParamNames.schedule, params.schedule);
    }
    if (isNotBlank(params.said)) {
        query.set(agencyParamNames.said, params.said);
    }
    if (isNotBlank(params.fullname)) {
        query.set(agencyParamNames.fullname, params.fullname);
    }
    if (paginationParams) {
        query.set(paginationParamName.page, `${paginationParams.page}`);
        query.set(paginationParamName.size, `${paginationParams.size}`);
        query.set(paginationParamName.sort, paginationParams.sort);
    }
    return query;
};
/**
 * Creates a link to the trips by station page with the specified filtering criteria.
 *
 * @param params the filtering criteria
 * @param paginationParams the pagination parameters
 */
export const toTripsByStationLink = (params, paginationParams) => {
    let query = undefined;
    if (params.stationId) {
        query = createTripQueryParams(params, paginationParams);
    }
    const qs = query ? `?${query.toString()}` : '';
    return `#${routeLinks.tripsByStation}${qs}`;
};
const createTripQueryParams = (params, paginationParams) => {
    const query = new URLSearchParams();
    if (isNotBlank(params.id)) {
        query.set(tripParamName.id, params.id);
    }
    if (isNotBlank(params.line)) {
        query.set(tripParamName.line, params.line);
    }
    if (isNotBlank(params.lineId)) {
        query.set(tripParamName.lineId, params.lineId);
    }
    if (isNotBlank(params.destination)) {
        query.set(tripParamName.destination, params.destination);
    }
    if (isNotBlank(params.agency)) {
        query.set(tripParamName.agency, params.agency);
    }
    if (isNotBlank(params.schedule)) {
        query.set(tripParamName.schedule, params.schedule);
    }
    if (isNotBlank(params.stationId)) {
        query.set(tripParamName.station, params.stationId);
    }
    if (paginationParams) {
        query.set(paginationParamName.page, `${paginationParams.page}`);
        query.set(paginationParamName.size, `${paginationParams.size}`);
        query.set(paginationParamName.sort, paginationParams.sort);
    }
    return query;
};
const createTripDetailsQueryParams = (params) => {
    const query = new URLSearchParams();
    query.set(tripDetailsParamName.id, params.id);
    query.set(tripDetailsParamName.schedule, params.schedule);
    if (isNotBlank(params.stationId)) {
        query.set(tripDetailsParamName.stationId, params.stationId);
    }
    return query;
};
const createStationQueryParams = (params, paginationParams) => {
    const query = new URLSearchParams();
    if (isNotBlank(params.id)) {
        query.set(stationParamNames.id, params.id);
    }
    if (isNotBlank(params.schedule)) {
        query.set(stationParamNames.schedule, params.schedule);
    }
    if (isNotBlank(params.name)) {
        query.set(stationParamNames.name, params.name);
    }
    if (isNotBlank(params.shortName)) {
        query.set(stationParamNames.shortName, params.shortName);
    }
    if (isNotBlank(params.sloid)) {
        query.set(stationParamNames.sloid, params.sloid);
    }
    if (paginationParams) {
        query.set(paginationParamName.page, `${paginationParams.page}`);
        query.set(paginationParamName.size, `${paginationParams.size}`);
        query.set(paginationParamName.sort, paginationParams.sort);
    }
    return query;
};
/**
 * Navigates the user to the trips page with the specified filtering criteria.
 *
 * @param params the filtering criteria
 * @param paginationParams the pagination parameters
 */
export const toLinesLink = (params, paginationParams) => {
    const query = createLineQueryParams(params, paginationParams);
    return `#${routeLinks.line}?${query.toString()}`;
};
export const createLineQueryParams = (params, paginationParams) => {
    const query = new URLSearchParams();
    if (isNotBlank(params.id)) {
        query.set(lineParamNames.id, params.id);
    }
    if (isNotBlank(params.schedule)) {
        query.set(lineParamNames.schedule, params.schedule);
    }
    if (isNotBlank(params.agency)) {
        query.set(lineParamNames.agency, params.agency);
    }
    if (isNotBlank(params.type)) {
        query.set(lineParamNames.type, params.type);
    }
    if (isNotBlank(params.name)) {
        query.set(lineParamNames.name, params.name);
    }
    if (paginationParams) {
        query.set(paginationParamName.page, `${paginationParams.page}`);
        query.set(paginationParamName.size, `${paginationParams.size}`);
        query.set(paginationParamName.sort, paginationParams.sort);
    }
    return query;
};
/**
 * Navigates the user to the messages page with the specified filtering criteria.
 *
 * @param params the filtering criteria
 * @param paginationParams the pagination parameters
 */
export const toMessagesLink = (params, paginationParams) => {
    const query = createMessageQueryParams(params, paginationParams);
    return `#${routeLinks.message}?${query.toString()}`;
};
export const createMessageQueryParams = (params, paginationParams) => {
    const query = new URLSearchParams();
    if (isNotBlank(params.schedule)) {
        query.set(messageParamNames.schedule, params.schedule);
    }
    if (isNotBlank(params.cause)) {
        query.set(messageParamNames.cause, params.cause);
    }
    if (isNotBlank(params.station)) {
        query.set(messageParamNames.station, params.station);
    }
    if (isNotBlank(params.line)) {
        query.set(messageParamNames.line, params.line);
    }
    if (params.validFrom) {
        query.set(messageParamNames.from, format(params.validFrom, ISO_DATE_FORMAT));
    }
    if (params.validTo) {
        query.set(messageParamNames.to, format(params.validTo, ISO_DATE_FORMAT));
    }
    if (paginationParams) {
        query.set(paginationParamName.page, `${paginationParams.page}`);
        query.set(paginationParamName.size, `${paginationParams.size}`);
        query.set(paginationParamName.sort, paginationParams.sort);
    }
    return query;
};
/**
 * Returns a link to the station detail page.
 *
 * @param params the station params
 * @returns a link with them matching params to station details page
 */
export const toStationDetailLink = (params) => {
    const query = new URLSearchParams();
    if (isNotBlank(params.id)) {
        query.set(stationDetailsParamNames.id, params.id);
    }
    if (isNotBlank(params.schedule)) {
        query.set(stationDetailsParamNames.schedule, params.schedule);
    }
    if (isNotBlank(params.sloid)) {
        query.set(stationDetailsParamNames.sloid, params.sloid);
    }
    return `#${routeLinks.stationDetail}?${query.toString()}`;
};
export const toMessageDetailsLink = (params) => {
    const query = new URLSearchParams();
    if (isNotBlank(params.id)) {
        query.set(messageDetailsParamNames.id, params.id);
    }
    if (isNotBlank(params.schedule)) {
        query.set(messageDetailsParamNames.schedule, params.schedule);
    }
    return `#${routeLinks.messageDetails}?${query.toString()}`;
};
export const toMessageConfigLink = (params, paginationParams) => {
    const query = createMessageConfigQueryParams(params, paginationParams);
    return `#${routeLinks.disruptionMessageConfig}?${query.toString()}`;
};
export const createMessageConfigQueryParams = (params, paginationParams) => {
    const query = new URLSearchParams();
    if (isNotBlank(params.cause)) {
        query.set(messageConfigParamNames.cause, params.cause);
    }
    if (params.fromDate) {
        query.set(messageConfigParamNames.from, format(params.fromDate, ISO_DATE_FORMAT));
    }
    if (params.toDate) {
        query.set(messageConfigParamNames.to, format(params.toDate, ISO_DATE_FORMAT));
    }
    if (params.organizationId != undefined) {
        query.set(messageConfigParamNames.organization, params.organizationId.toString());
    }
    if (paginationParams) {
        query.set(paginationParamName.page, `${paginationParams.page}`);
        query.set(paginationParamName.size, `${paginationParams.size}`);
        query.set(paginationParamName.sort, paginationParams.sort);
    }
    return query;
};
export const toDisruptionRegionLink = (params, paginationParams) => {
    const query = createDisruptionRegionQueryParams(params, paginationParams);
    return `#${routeLinks.disruptionRegion}?${query.toString()}`;
};
export const createDisruptionRegionQueryParams = (params, paginationParams) => {
    const query = new URLSearchParams();
    if (isNotBlank(params.name)) {
        query.set(disruptionRegionParamNames.name, params.name);
    }
    if (isNotBlank(params.stationSloid)) {
        query.set(disruptionRegionParamNames.station, params.stationSloid);
    }
    if (params.organizationId != undefined) {
        query.set(disruptionRegionParamNames.organization, params.organizationId.toString());
    }
    if (paginationParams) {
        query.set(paginationParamName.page, `${paginationParams.page}`);
        query.set(paginationParamName.size, `${paginationParams.size}`);
        query.set(paginationParamName.sort, paginationParams.sort);
    }
    return query;
};
export const toDisruptionMessageTextSnippetLink = (params, paginationParams) => {
    const query = createDisruptionMessageTextSnippetQueryParams(params, paginationParams);
    return `#${routeLinks.disruptionTextSnippet}?${query.toString()}`;
};
export const createDisruptionMessageTextSnippetQueryParams = (params, paginationParams) => {
    const query = new URLSearchParams();
    if (isNotBlank(params.search)) {
        query.set(disruptionMessageTextSnippetParamNames.search, params.search);
    }
    if (params.organizationId != undefined) {
        query.set(disruptionMessageTextSnippetParamNames.organization, params.organizationId.toString());
    }
    if (paginationParams) {
        query.set(paginationParamName.page, `${paginationParams.page}`);
        query.set(paginationParamName.size, `${paginationParams.size}`);
        query.set(paginationParamName.sort, paginationParams.sort);
    }
    return query;
};
export const toDisruptionMessageEditLink = (id) => `#${routeLinks.disruptionMessageConfigEdit}?id=${id}`;
export const toServicePointLink = (params, paginationParams) => {
    const query = createServicePointQueryParams(params, paginationParams);
    return `#${routeLinks.servicePoint}?${query.toString()}`;
};
export const createServicePointQueryParams = (params, paginationParams) => {
    const query = new URLSearchParams();
    if (isNotBlank(params.name)) {
        query.set(servicePointParamNames.name, params.name);
    }
    if (isNotBlank(params.abbreviation)) {
        query.set(servicePointParamNames.abbreviation, params.abbreviation);
    }
    if (isNotBlank(params.sloid)) {
        query.set(servicePointParamNames.sloid, params.sloid);
    }
    if (isNotBlank(params.countryCode)) {
        query.set(servicePointParamNames.countryCode, params.countryCode);
    }
    if (paginationParams) {
        query.set(paginationParamName.page, `${paginationParams.page}`);
        query.set(paginationParamName.size, `${paginationParams.size}`);
        query.set(paginationParamName.sort, paginationParams.sort);
    }
    return query;
};
export const toLineCategoryLink = (params, paginationParams) => {
    const query = createLineCategoriesQueryParams(params, paginationParams);
    return `#${routeLinks.lineCategory}?${query.toString()}`;
};
export const createLineCategoriesQueryParams = (params, paginationParams) => {
    const query = new URLSearchParams();
    if (isNotBlank(params.category)) {
        query.set(lineCategoriesParamNames.category, params.category);
    }
    if (isNotBlank(params.transportGroup)) {
        query.set(lineCategoriesParamNames.transportGroup, params.transportGroup);
    }
    if (isNotBlank(params.transportType)) {
        query.set(lineCategoriesParamNames.transportType, params.transportType);
    }
    if (paginationParams) {
        query.set(paginationParamName.page, `${paginationParams.page}`);
        query.set(paginationParamName.size, `${paginationParams.size}`);
        query.set(paginationParamName.sort, paginationParams.sort);
    }
    return query;
};
export const toLineColorLink = (params, paginationParams) => {
    const query = createLineColorQueryParams(params, paginationParams);
    return `#${routeLinks.lineColor}?${query.toString()}`;
};
export const createLineColorQueryParams = (params, paginationParams) => {
    const query = new URLSearchParams();
    if (isNotBlank(params.agency)) {
        query.set(lineColorParamNames.agency, params.agency);
    }
    if (isNotBlank(params.lineName)) {
        query.set(lineColorParamNames.lineName, params.lineName);
    }
    if (isNotBlank(params.lineType)) {
        query.set(lineColorParamNames.lineType, params.lineType);
    }
    if (paginationParams) {
        query.set(paginationParamName.page, `${paginationParams.page}`);
        query.set(paginationParamName.size, `${paginationParams.size}`);
        query.set(paginationParamName.sort, paginationParams.sort);
    }
    return query;
};
export const toVehicleCategoryLink = (params, paginationParams) => {
    const query = createVehicleCategoryQueryParams(params, paginationParams);
    return `#${routeLinks.vehicleCategoryMapping}?${query.toString()}`;
};
export const createVehicleCategoryQueryParams = (params, paginationParams) => {
    const query = new URLSearchParams();
    if (isNotBlank(params.text)) {
        query.set(vehicleCategoryMappingParamNames.text, params.text);
    }
    if (isNotBlank(params.from)) {
        query.set(vehicleCategoryMappingParamNames.from, params.from);
    }
    if (isNotBlank(params.to)) {
        query.set(vehicleCategoryMappingParamNames.to, params.to);
    }
    if (paginationParams) {
        query.set(paginationParamName.page, `${paginationParams.page}`);
        query.set(paginationParamName.size, `${paginationParams.size}`);
        query.set(paginationParamName.sort, paginationParams.sort);
    }
    return query;
};
export const toMarketingLink = (params, paginationParams) => {
    const query = createMarketingQueryParams(params, paginationParams);
    return `#${routeLinks.marketing}?${query.toString()}`;
};
export const createMarketingQueryParams = (params, paginationParams) => {
    const query = new URLSearchParams();
    if (isNotBlank(params.name)) {
        query.set(marketingTextParamNames.name, params.name);
    }
    if (params.fromDate) {
        query.set(marketingTextParamNames.fromDate, format(params.fromDate, ISO_DATE_FORMAT));
    }
    if (params.toDate) {
        query.set(marketingTextParamNames.toDate, format(params.toDate, ISO_DATE_FORMAT));
    }
    if (params.organizationId != undefined) {
        query.set(marketingTextParamNames.organization, params.organizationId.toString());
    }
    if (paginationParams) {
        query.set(paginationParamName.page, `${paginationParams.page}`);
        query.set(paginationParamName.size, `${paginationParams.size}`);
        query.set(paginationParamName.sort, paginationParams.sort);
    }
    return query;
};
export const toDisruptionCauseLink = (params, paginationParams) => {
    const query = createDisruptionCauseQueryParams(params, paginationParams);
    return `#${routeLinks.vdvDisruptionCause}?${query.toString()}`;
};
export const createDisruptionCauseQueryParams = (params, paginationParams) => {
    const query = new URLSearchParams();
    if (isNotBlank(params.category)) {
        query.set(disruptionCausesParamNames.category, params.category);
    }
    if (isNotBlank(params.cause)) {
        query.set(disruptionCausesParamNames.cause, params.cause);
    }
    if (isNotBlank(params.group)) {
        query.set(disruptionCausesParamNames.group, params.group);
    }
    if (isNotBlank(params.query)) {
        query.set(disruptionCausesParamNames.query, params.query);
    }
    if (paginationParams) {
        query.set(paginationParamName.page, `${paginationParams.page}`);
        query.set(paginationParamName.size, `${paginationParams.size}`);
        query.set(paginationParamName.sort, paginationParams.sort);
    }
    return query;
};
