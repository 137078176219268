<script lang="ts">
  import { initializePaginationFromQueryParams, type PaginationParams } from 'src/api/query/pagination';
  import type { SortFunction } from 'src/api/query/sort';
  import type { Pagination, Station } from '$generated/service/cache-api';
  import type { SvelteEvent } from 'src/model/event';
  import type { StationFilterCriteria } from 'src/components/pages/station/stationService';
  import { _ } from 'svelte-i18n';
  import { onMount } from 'svelte';
  import { writable } from 'svelte/store';
  import { NumberedPagination, PageLayout, notificationService } from '@pids/shared-component';
  import { StationSort } from 'src/components/pages/station/stationSort';
  import { sortBy } from 'src/api/query/sort';
  import { createQuery } from 'src/api/query/query';
  import { fetchStations, stationParamNames } from 'src/components/pages/station/stationService';
  import ClearFiltersButton from 'src/components/shared/ClearFiltersButton.svelte';
  import StationFilter from 'src/components/pages/station/StationFilter.svelte';
  import StationTable from 'src/components/pages/station/StationTable.svelte';
  import { DEFAULT_ERROR_TEXT_KEY } from 'src/api/notification';
  import type { LoadingStateStore } from '@pids/shared-component';
  import { querystring, replace } from 'svelte-spa-router';
  import { toStationsLink } from 'src/components/pages/routingService';

  const initialPagination: PaginationParams = {
    page: 1,
    size: 50,
    sort: `${StationSort.Id},asc`
  };

  const sortFunction: SortFunction = sortBy(StationSort.Id);
  let paginationParams: PaginationParams = initialPagination;
  let firstLoad = true;

  let results: Station[] = [];
  let pagination: Pagination | undefined;
  let loading: LoadingStateStore;
  let filter = writable<StationFilterCriteria>({});

  const resultsQuery = createQuery([], signal => fetchStations($filter, paginationParams, { signal }), false);

  let clearFiltersDisabled: boolean;
  $: clearFiltersDisabled = filtersAreEmpty($filter);

  $: results = !$resultsQuery.isError ? $resultsQuery.results : [];
  $: pagination = !$resultsQuery.isError ? $resultsQuery.pagination : undefined;
  $: loading = resultsQuery.loading;

  $: $resultsQuery.isError && notificationService.error($resultsQuery.error.message ?? $_(DEFAULT_ERROR_TEXT_KEY));

  onMount(() => {
    handleQueryString($querystring);

    const unsubscribeFilter = filter.subscribe(() => {
      if (!firstLoad) {
        resetPageAndSort();
      } else {
        firstLoad = false;
      }
      void loadData();
    });

    return () => {
      unsubscribeFilter();
      resultsQuery.abort();
    };
  });

  const resetPageAndSort = () => {
    paginationParams = initialPagination;
  };

  const handleQueryString = (query: string | undefined) => {
    if (!query) {
      return;
    }

    const params = new URLSearchParams(query);

    paginationParams = initializePaginationFromQueryParams(params, paginationParams);
    $filter = initializeCriteriaFromQueryParams(params);
  };

  const initializeCriteriaFromQueryParams = (params: URLSearchParams): StationFilterCriteria => {
    return {
      ...$filter,
      id: params.get(stationParamNames.id) ?? undefined,
      name: params.get(stationParamNames.name) ?? undefined,
      sloid: params.get(stationParamNames.sloid) ?? undefined,
      shortName: params.get(stationParamNames.shortName) ?? undefined,
      schedule: params.get(stationParamNames.schedule) ?? undefined
    };
  };

  const loadData = async () => {
    updateUrlParameters();
    await resultsQuery.next();
  };

  const updateUrlParameters = () => {
    replace(toStationsLink({ ...$filter, schedule: $filter.schedule }, paginationParams));
  };

  const handleSortChange = (e: SvelteEvent<{ sort: StationSort }>) => {
    const newSort = sortFunction(e.detail.sort);

    paginationParams = {
      ...paginationParams,
      sort: newSort
    };

    void loadData();
  };

  const handlePageChange = (page: number) => {
    paginationParams = {
      ...paginationParams,
      page
    };
    void loadData();
  };

  const filtersAreEmpty = (filter: StationFilterCriteria): boolean => {
    return !filter.id && !filter.sloid && !filter.name && !filter.shortName && !filter.schedule;
  };

  const clearFilters = () => {
    $filter = {};
  };
</script>

<PageLayout title={$_('station.page.title')}>
  <svelte:fragment slot="criteria">
    <StationFilter bind:filter={$filter} />
  </svelte:fragment>

  <svelte:fragment slot="actions">
    <ClearFiltersButton on:click={clearFilters} disabled={clearFiltersDisabled} />
  </svelte:fragment>

  <svelte:fragment slot="content">
    <StationTable loading={$loading} resultsList={results} on:sort={handleSortChange} />
  </svelte:fragment>

  <svelte:fragment slot="pagination">
    {#if !$loading && pagination}
      <NumberedPagination {...pagination} onPageChange={handlePageChange} />
    {/if}
  </svelte:fragment>
</PageLayout>
