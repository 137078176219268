/* tslint:disable */
/* eslint-disable */
/**
 * Netcetera PIDS - Hub Cache API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.6.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { __awaiter } from "tslib";
import * as runtime from '../runtime';
import { CreateEditVehicleCategoryMappingToJSON, VehicleCategoryMappingFromJSON, VehicleCategoryMappingOverviewFromJSON, } from '../models/index';
/**
 *
 */
export class VehicleCategoryMappingApi extends runtime.BaseAPI {
    /**
     */
    createVehicleCategoryMappingRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters['createEditVehicleCategoryMapping'] == null) {
                throw new runtime.RequiredError('createEditVehicleCategoryMapping', 'Required parameter "createEditVehicleCategoryMapping" was null or undefined when calling createVehicleCategoryMapping().');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/vehicle-category-mapping`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: CreateEditVehicleCategoryMappingToJSON(requestParameters['createEditVehicleCategoryMapping']),
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => VehicleCategoryMappingFromJSON(jsonValue));
        });
    }
    /**
     */
    createVehicleCategoryMapping(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.createVehicleCategoryMappingRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    deleteVehicleCategoryMappingRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters['id'] == null) {
                throw new runtime.RequiredError('id', 'Required parameter "id" was null or undefined when calling deleteVehicleCategoryMapping().');
            }
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/vehicle-category-mapping/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
                method: 'DELETE',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.VoidApiResponse(response);
        });
    }
    /**
     */
    deleteVehicleCategoryMapping(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.deleteVehicleCategoryMappingRaw(requestParameters, initOverrides);
        });
    }
    /**
     */
    getAllVehicleCategoryMappingsRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            if (requestParameters['textLike'] != null) {
                queryParameters['text.like'] = requestParameters['textLike'];
            }
            if (requestParameters['textEq'] != null) {
                queryParameters['text.eq'] = requestParameters['textEq'];
            }
            if (requestParameters['fromLike'] != null) {
                queryParameters['from.like'] = requestParameters['fromLike'];
            }
            if (requestParameters['fromEq'] != null) {
                queryParameters['from.eq'] = requestParameters['fromEq'];
            }
            if (requestParameters['toLike'] != null) {
                queryParameters['to.like'] = requestParameters['toLike'];
            }
            if (requestParameters['toEq'] != null) {
                queryParameters['to.eq'] = requestParameters['toEq'];
            }
            if (requestParameters['page'] != null) {
                queryParameters['page'] = requestParameters['page'];
            }
            if (requestParameters['size'] != null) {
                queryParameters['size'] = requestParameters['size'];
            }
            if (requestParameters['sort'] != null) {
                queryParameters['sort'] = requestParameters['sort'].join(runtime.COLLECTION_FORMATS["csv"]);
            }
            const headerParameters = {};
            const response = yield this.request({
                path: `/vehicle-category-mapping`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => VehicleCategoryMappingOverviewFromJSON(jsonValue));
        });
    }
    /**
     */
    getAllVehicleCategoryMappings() {
        return __awaiter(this, arguments, void 0, function* (requestParameters = {}, initOverrides) {
            const response = yield this.getAllVehicleCategoryMappingsRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    getVehicleCategoryMappingRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters['id'] == null) {
                throw new runtime.RequiredError('id', 'Required parameter "id" was null or undefined when calling getVehicleCategoryMapping().');
            }
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/vehicle-category-mapping/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => VehicleCategoryMappingFromJSON(jsonValue));
        });
    }
    /**
     */
    getVehicleCategoryMapping(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.getVehicleCategoryMappingRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    updateVehicleCategoryMappingRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters['id'] == null) {
                throw new runtime.RequiredError('id', 'Required parameter "id" was null or undefined when calling updateVehicleCategoryMapping().');
            }
            if (requestParameters['createEditVehicleCategoryMapping'] == null) {
                throw new runtime.RequiredError('createEditVehicleCategoryMapping', 'Required parameter "createEditVehicleCategoryMapping" was null or undefined when calling updateVehicleCategoryMapping().');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/vehicle-category-mapping/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
                method: 'PUT',
                headers: headerParameters,
                query: queryParameters,
                body: CreateEditVehicleCategoryMappingToJSON(requestParameters['createEditVehicleCategoryMapping']),
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => VehicleCategoryMappingFromJSON(jsonValue));
        });
    }
    /**
     */
    updateVehicleCategoryMapping(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.updateVehicleCategoryMappingRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
}
