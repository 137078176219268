<script lang="ts">
  import { onMount } from 'svelte';
  import { _ } from 'svelte-i18n';
  import {
    Table,
    TableCol,
    LineIcon,
    getLineIcon,
    defaultStringStartsWithFilter,
    ColoredLine,
    NumberedPagination,
    DebouncedTextInput,
    Icon,
    DropdownSelect,
    LineIconContainer
  } from '@pids/shared-component';
  import type { Line } from '$generated/service/cache-api';
  import type { SvelteEvent } from 'src/model/event';

  export let lines: Line[] = [];
  export let selectedLine: Line | undefined;

  const PAGE_SIZE = 5;

  let loading = true;
  let agencies: string[] = [];

  // pagination
  let page = 1;
  let totalElements = 0;
  let totalPages = 1;

  // input filter
  let nameFilter: string = '';
  let idFilter: string = '';
  let agencyFilter: string[] = [];

  $: staging = nameFilter || idFilter || agencyFilter ? filterByName(filterById(filterByAgency(lines))) : lines;
  $: staging, updatePaging();
  $: rows = staging.slice((page - 1) * PAGE_SIZE, page * PAGE_SIZE);

  const filterByName = (lines: Line[]) => {
    return nameFilter ? lines.filter(line => line.name.toUpperCase().includes(nameFilter.toUpperCase())) : lines;
  };

  const filterById = (lines: Line[]) => {
    return idFilter ? lines.filter(line => line.id.toUpperCase().includes(idFilter.toUpperCase())) : lines;
  };

  const filterByAgency = (lines: Line[]) => {
    return agencyFilter && agencyFilter.length === 1 ? lines.filter(line => line.agency === agencyFilter[0]) : lines;
  };

  const onSelect = ({ detail }: SvelteEvent<Line>) => {
    selectedLine = detail;
  };

  const onDeselect = () => {
    selectedLine = undefined;
  };

  const initializeData = () => {
    agencies = Array.from(new Set(lines.map(line => line.agency)));
    loading = false;
  };

  const updatePaging = () => {
    totalElements = staging.length;
    totalPages = Math.ceil(totalElements / PAGE_SIZE);
    page = 1;
  };

  onMount(() => {
    initializeData();
  });
</script>

<style>
  .line-filter {
    gap: 1rem;
  }
</style>

<div class="line-filter ml-5 mr-5 mt-2 mb-2 is-flex is-flex-direction-column">
  <div class="columns">
    <div class="column pb-0">
      <label for="name-search">
        {$_('disruption-management.editor.line-selector.filter.name')}
      </label>
      <div class="control has-icons-left">
        <DebouncedTextInput
          id="name-search"
          class="input"
          placeholder={$_('disruption-management.editor.line-selector.filter.name.placeholder')}
          on:change={({ detail }) => (nameFilter = detail)}
          clearable />
        <span class="icon is-left">
          <Icon name="search" style="font-size: 1.25rem;" />
        </span>
      </div>
    </div>

    <div class="column pb-0">
      {#if agencies}
        <label for="agency-filter">
          {$_('disruption-management.editor.line-selector.filter.agency')}
        </label>
        <div class="control">
          <DropdownSelect
            id="agency-filter"
            items={agencies}
            filter={defaultStringStartsWithFilter}
            placeholder={$_('select.default-placeholder')}
            empty={$_('table.empty.default')}
            on:clear={() => (agencyFilter = [])}
            on:change={({ detail }) => (agencyFilter = detail)} />
        </div>
      {/if}
    </div>

    <div class="column pb-0">
      <label for="id-search">
        {$_('disruption-management.editor.line-selector.filter.id')}
      </label>
      <div class="control has-icons-left">
        <DebouncedTextInput
          id="id-search"
          class="input"
          placeholder={$_('disruption-management.editor.line-selector.filter.id.placeholder')}
          on:change={({ detail }) => (idFilter = detail)}
          clearable />
        <span class="icon is-left">
          <Icon name="search" style="font-size: 1.25rem;" />
        </span>
      </div>
    </div>
  </div>

  <Table
    {rows}
    {loading}
    let:row
    selectionMode={true}
    singleSelect={true}
    emptyText={$_('table.empty.default')}
    on:select={onSelect}
    on:deselect={onDeselect}
    loadingText={$_('table.loading.default')}>
    <TableCol {row} header={$_('disruption-management.editor.line-selector.table.column.name')} chipped>
      {#if row}
        <LineIconContainer>
          <LineIcon height="1.25rem" type={getLineIcon(row.type)} number={row.number ?? ''}>
            <ColoredLine
              line={row.name}
              background={row.color.background}
              foreground={row.color.foreground}
              border={row.color.border} />
          </LineIcon>
        </LineIconContainer>
      {/if}
    </TableCol>

    <TableCol {row} value="agency" header={$_('disruption-management.editor.line-selector.table.column.agency')}>
      {row?.agency ?? ''}
    </TableCol>

    <TableCol {row} value="type" header={$_('disruption-management.editor.line-selector.table.column.type')}>
      {row?.type ?? ''}
    </TableCol>

    <TableCol {row} value="id" header={$_('disruption-management.editor.line-selector.table.column.id')} />
  </Table>

  {#if !loading}
    <NumberedPagination
      {page}
      {totalElements}
      {totalPages}
      shownPagesPerSide={2}
      onPageChange={pageNum => (page = pageNum)} />
  {/if}
</div>
