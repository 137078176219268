<script lang="ts">
  import { initializePaginationFromQueryParams, type PaginationParams } from 'src/api/query/pagination';
  import type { SortFunction } from 'src/api/query/sort';
  import type { Pagination, ServicePoint } from '$generated/service/cache-api';
  import type { SvelteEvent } from 'src/model/event';
  import { _ } from 'svelte-i18n';
  import { onMount } from 'svelte';
  import { writable } from 'svelte/store';
  import { NumberedPagination, PageLayout, notificationService } from '@pids/shared-component';
  import { sortBy } from 'src/api/query/sort';
  import { createQuery } from 'src/api/query/query';
  import { DEFAULT_ERROR_TEXT_KEY } from 'src/api/notification';
  import type { LoadingStateStore } from '@pids/shared-component';
  import ClearFiltersButton from 'src/components/shared/ClearFiltersButton.svelte';
  import { ServicePointSort } from 'src/components/pages/servicePoint/servicePointSort';
  import {
    fetchServicePoints,
    servicePointParamNames,
    type ServicePointFilterCriteria
  } from 'src/components/pages/servicePoint/servicePointService';
  import ServicePointTable from 'src/components/pages/servicePoint/ServicePointTable.svelte';
  import ServicePointFilter from 'src/components/pages/servicePoint/ServicePointFilter.svelte';
  import { toServicePointLink } from 'src/components/pages/routingService';
  import { querystring, replace } from 'svelte-spa-router';

  const initialPagination: PaginationParams = {
    page: 1,
    size: 50,
    sort: `${ServicePointSort.Sloid},asc`
  };

  const sortFunction: SortFunction = sortBy(ServicePointSort.Sloid);
  let paginationParams: PaginationParams = initialPagination;

  let results: ServicePoint[] = [];
  let pagination: Pagination | undefined;
  let loading: LoadingStateStore;
  let filter = writable<ServicePointFilterCriteria>({});

  const resultsQuery = createQuery([], signal => fetchServicePoints($filter, paginationParams, { signal }), false);

  let clearFiltersDisabled: boolean;
  $: clearFiltersDisabled = filtersAreEmpty($filter);

  $: results = !$resultsQuery.isError ? $resultsQuery.results : [];
  $: pagination = !$resultsQuery.isError ? $resultsQuery.pagination : undefined;
  $: loading = resultsQuery.loading;

  $: $resultsQuery.isError && notificationService.error($resultsQuery.error.message ?? $_(DEFAULT_ERROR_TEXT_KEY));

  onMount(() => {
    handleQueryString($querystring);

    const unsubscribeFilter = filter.subscribe(() => {
      resetPageAndSort();
      void loadData();
    });

    return () => {
      unsubscribeFilter();
      resultsQuery.abort();
    };
  });

  const loadData = async () => {
    updateUrlParameters();
    await resultsQuery.next();
  };

  const handleSortChange = (e: SvelteEvent<{ sort: ServicePointSort }>) => {
    const newSort = sortFunction(e.detail.sort);

    paginationParams = {
      ...paginationParams,
      sort: newSort
    };

    void loadData();
  };

  const resetPageAndSort = () => {
    paginationParams = initialPagination;
  };

  const handlePageChange = (page: number) => {
    paginationParams = {
      ...paginationParams,
      page
    };
    void loadData();
  };

  const filtersAreEmpty = (filter: ServicePointFilterCriteria): boolean => {
    return filter.countryCode === undefined && !filter.sloid && !filter.abbreviation;
  };

  const clearFilters = () => {
    $filter = {};
  };

  const updateUrlParameters = () => {
    replace(toServicePointLink({ ...$filter }, paginationParams));
  };

  const handleQueryString = (query: string | undefined) => {
    if (!query) {
      return;
    }

    const params = new URLSearchParams(query);

    paginationParams = initializePaginationFromQueryParams(params, paginationParams);
    $filter = initializeCriteriaFromQueryParams(params);
  };

  const initializeCriteriaFromQueryParams = (params: URLSearchParams): ServicePointFilterCriteria => {
    return {
      ...$filter,
      name: params.get(servicePointParamNames.name) ?? undefined,
      abbreviation: params.get(servicePointParamNames.abbreviation) ?? undefined,
      countryCode: params.get(servicePointParamNames.countryCode) ?? undefined,
      sloid: params.get(servicePointParamNames.sloid) ?? undefined
    };
  };
</script>

<PageLayout title={$_('service-point.page.title')}>
  <svelte:fragment slot="criteria">
    <ServicePointFilter bind:filter={$filter} />
  </svelte:fragment>

  <svelte:fragment slot="actions">
    <ClearFiltersButton on:click={clearFilters} disabled={clearFiltersDisabled} />
  </svelte:fragment>

  <svelte:fragment slot="content">
    <ServicePointTable loading={$loading} resultsList={results} on:sort={handleSortChange} />
  </svelte:fragment>

  <svelte:fragment slot="pagination">
    {#if !$loading && pagination}
      <NumberedPagination {...pagination} onPageChange={handlePageChange} />
    {/if}
  </svelte:fragment>
</PageLayout>
