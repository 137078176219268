/* tslint:disable */
/* eslint-disable */
/**
 * Netcetera PIDS - Hub Cache API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.6.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 *
 * @export
 */
export const DisruptionMessageCauseEnum = {
    Emergency: 'EMERGENCY',
    Disruption: 'DISRUPTION',
    ConstructionWork: 'CONSTRUCTION_WORK',
    EndInformation: 'END_INFORMATION',
    AdvanceInformation: 'ADVANCE_INFORMATION',
    Information: 'INFORMATION'
};
export function instanceOfDisruptionMessageCauseEnum(value) {
    for (const key in DisruptionMessageCauseEnum) {
        if (Object.prototype.hasOwnProperty.call(DisruptionMessageCauseEnum, key)) {
            if (DisruptionMessageCauseEnum[key] === value) {
                return true;
            }
        }
    }
    return false;
}
export function DisruptionMessageCauseEnumFromJSON(json) {
    return DisruptionMessageCauseEnumFromJSONTyped(json, false);
}
export function DisruptionMessageCauseEnumFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function DisruptionMessageCauseEnumToJSON(value) {
    return value;
}
