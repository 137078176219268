<script lang="ts">
  import { ActionIcon, Button, ColoredLine, getLineIcon, LineIcon, LineIconContainer } from '@pids/shared-component';
  import { fetchTripDetails, fromTripsPage } from 'src/components/pages/trips/tripService';
  import { tripDetailsParamName } from 'src/components/pages/trips/types';
  import { pop, push, querystring } from 'svelte-spa-router';
  import TripCalendar from 'src/components/pages/trips/details/TripCalendar.svelte';
  import TripDetailsTable from 'src/components/pages/trips/details/TripDetailsTable.svelte';
  import StopTable from 'src/components/pages/trips/details/StopTable.svelte';
  import { _ } from 'svelte-i18n';
  import { routeLinks } from 'src/components/pages/routingService';
  import { onDestroy } from 'svelte';
  import { defaultAbortReason } from 'src/api/query';

  const abortController = new AbortController();

  $: queryParams = new URLSearchParams($querystring);
  $: scheduleId = queryParams.get(tripDetailsParamName.schedule);
  $: tripId = queryParams.get(tripDetailsParamName.id);
  $: stationId = queryParams.get(tripDetailsParamName.stationId);

  const navigateBack = (): void => {
    if ($fromTripsPage) {
      pop();
    } else {
      push(`#${routeLinks.trip}`);
    }
  };

  onDestroy(() => {
    abortController.abort(defaultAbortReason(undefined));
  });
</script>

<style lang="scss">
  @import 'src/styles/variables';

  .trip-details-title {
    display: flex;
    gap: 1rem;

    line-height: $line-height-large;
  }

  .trip-title {
    display: flex;
    gap: 0.625rem;
    padding-inline: 1rem;
  }

  .trip-title-text {
    font-size: 1.25rem;
    font-weight: $font-weight-semi-bold;
    line-height: $line-height-large;
    display: flex;
    align-items: center;
  }

  .trip-details {
    display: grid;
    gap: 1.25rem;
  }

  .calendars {
    padding-block: 0.625rem 1.25rem;
    border-block: 1px solid black;
    box-sizing: border-box;
  }

  .operational-days-title {
    font-size: $font-size-x-large;
    font-weight: $font-weight-semi-bold;
    line-height: $line-height-large;
  }

  .canceled {
    text-decoration: line-through;
  }
</style>

{#if tripId && scheduleId}
  {#await fetchTripDetails(tripId, scheduleId, { signal: abortController.signal }) then trip}
    {#if trip}
      <div class="trip-details">
        <div class="trip-details-title">
          <ActionIcon icon="arrow-back" on:click={navigateBack} />
          <div class="trip-title">
            <LineIconContainer>
              <LineIcon height="1.25rem" type={getLineIcon(trip.line.type)} number={trip.line.number ?? ''}>
                {#if trip.line.name}
                  <ColoredLine
                    line={trip.line.name}
                    background={trip.line.color?.background}
                    foreground={trip.line.color?.foreground}
                    border={trip.line.color?.border} />
                {/if}
              </LineIcon>
            </LineIconContainer>
            <span class="trip-title-text" class:canceled={trip.canceled}>
              {$_('trip.details.title', {
                values: { id: trip.id }
              })}
            </span>
          </div>
        </div>

        <TripDetailsTable trips={[trip]} />

        {#if trip.calendars?.length && trip.schedule?.validity}
          <div class="calendars">
            <h3 class="operational-days-title">{$_('trip.details.segment.operational-days.title')}</h3>
            {#each trip.calendars as cal}
              <TripCalendar calendar={cal} validityPeriod={trip.schedule.validity} />
              <div class="my-4">
                <StopTable stops={cal.stops} highlightedStationId={stationId} tripType={trip.type} />
              </div>
            {/each}
          </div>
        {/if}

        <div>
          <Button icon="arrow-back" primary={true} outlined={true} on:click={navigateBack}>
            {$_('button.back')}
          </Button>
        </div>
      </div>
    {/if}
  {/await}
{/if}
