/* tslint:disable */
/* eslint-disable */
/**
 * Netcetera PIDS - Hub Cache API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.6.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { __awaiter } from "tslib";
import * as runtime from '../runtime';
import { AgenciesFromJSON, DisruptionMessageFromJSON, DisruptionMessagesFromJSON, LineFilterFromJSON, LinesFromJSON, SchedulesFromJSON, StationsFromJSON, TripDetailsFromJSON, TripFilterFromJSON, TripsFromJSON, } from '../models/index';
/**
 *
 */
export class CacheApi extends runtime.BaseAPI {
    /**
     * Get a page of agencies
     */
    findAgenciesRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            if (requestParameters['schedules'] != null) {
                queryParameters['schedules'] = requestParameters['schedules'];
            }
            if (requestParameters['page'] != null) {
                queryParameters['page'] = requestParameters['page'];
            }
            if (requestParameters['size'] != null) {
                queryParameters['size'] = requestParameters['size'];
            }
            if (requestParameters['sort'] != null) {
                queryParameters['sort'] = requestParameters['sort'];
            }
            if (requestParameters['fullnameEq'] != null) {
                queryParameters['fullname.eq'] = requestParameters['fullnameEq'];
            }
            if (requestParameters['fullnameLike'] != null) {
                queryParameters['fullname.like'] = requestParameters['fullnameLike'];
            }
            if (requestParameters['idEq'] != null) {
                queryParameters['id.eq'] = requestParameters['idEq'];
            }
            if (requestParameters['idLike'] != null) {
                queryParameters['id.like'] = requestParameters['idLike'];
            }
            if (requestParameters['saidEq'] != null) {
                queryParameters['said.eq'] = requestParameters['saidEq'];
            }
            if (requestParameters['saidLike'] != null) {
                queryParameters['said.like'] = requestParameters['saidLike'];
            }
            const headerParameters = {};
            const response = yield this.request({
                path: `/agency`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => AgenciesFromJSON(jsonValue));
        });
    }
    /**
     * Get a page of agencies
     */
    findAgencies() {
        return __awaiter(this, arguments, void 0, function* (requestParameters = {}, initOverrides) {
            const response = yield this.findAgenciesRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     * Get all available schedules
     */
    findAllSchedulesRaw(initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/schedule`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => SchedulesFromJSON(jsonValue));
        });
    }
    /**
     * Get all available schedules
     */
    findAllSchedules(initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.findAllSchedulesRaw(initOverrides);
            return yield response.value();
        });
    }
    /**
     * Get a page of lines
     */
    findLinesRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            if (requestParameters['schedules'] != null) {
                queryParameters['schedules'] = requestParameters['schedules'];
            }
            if (requestParameters['page'] != null) {
                queryParameters['page'] = requestParameters['page'];
            }
            if (requestParameters['size'] != null) {
                queryParameters['size'] = requestParameters['size'];
            }
            if (requestParameters['sort'] != null) {
                queryParameters['sort'] = requestParameters['sort'];
            }
            if (requestParameters['idEq'] != null) {
                queryParameters['id.eq'] = requestParameters['idEq'];
            }
            if (requestParameters['idLike'] != null) {
                queryParameters['id.like'] = requestParameters['idLike'];
            }
            if (requestParameters['nameEq'] != null) {
                queryParameters['name.eq'] = requestParameters['nameEq'];
            }
            if (requestParameters['nameLike'] != null) {
                queryParameters['name.like'] = requestParameters['nameLike'];
            }
            if (requestParameters['type'] != null) {
                queryParameters['type'] = requestParameters['type'];
            }
            if (requestParameters['agency'] != null) {
                queryParameters['agency'] = requestParameters['agency'];
            }
            const headerParameters = {};
            const response = yield this.request({
                path: `/line`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => LinesFromJSON(jsonValue));
        });
    }
    /**
     * Get a page of lines
     */
    findLines() {
        return __awaiter(this, arguments, void 0, function* (requestParameters = {}, initOverrides) {
            const response = yield this.findLinesRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     * Get the disruption message with the given id
     */
    findMessageByIdRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters['id'] == null) {
                throw new runtime.RequiredError('id', 'Required parameter "id" was null or undefined when calling findMessageById().');
            }
            const queryParameters = {};
            if (requestParameters['schedules'] != null) {
                queryParameters['schedules'] = requestParameters['schedules'];
            }
            const headerParameters = {};
            const response = yield this.request({
                path: `/message/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => DisruptionMessageFromJSON(jsonValue));
        });
    }
    /**
     * Get the disruption message with the given id
     */
    findMessageById(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.findMessageByIdRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     * Get a page of disruption messages
     */
    findMessagesRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            if (requestParameters['schedules'] != null) {
                queryParameters['schedules'] = requestParameters['schedules'];
            }
            if (requestParameters['page'] != null) {
                queryParameters['page'] = requestParameters['page'];
            }
            if (requestParameters['size'] != null) {
                queryParameters['size'] = requestParameters['size'];
            }
            if (requestParameters['sort'] != null) {
                queryParameters['sort'] = requestParameters['sort'];
            }
            if (requestParameters['cause'] != null) {
                queryParameters['cause'] = requestParameters['cause'];
            }
            if (requestParameters['validFrom'] != null) {
                queryParameters['validFrom'] = requestParameters['validFrom'].toISOString().substring(0, 10);
            }
            if (requestParameters['validTo'] != null) {
                queryParameters['validTo'] = requestParameters['validTo'].toISOString().substring(0, 10);
            }
            if (requestParameters['stationEq'] != null) {
                queryParameters['station.eq'] = requestParameters['stationEq'];
            }
            if (requestParameters['stationLike'] != null) {
                queryParameters['station.like'] = requestParameters['stationLike'];
            }
            if (requestParameters['lineEq'] != null) {
                queryParameters['line.eq'] = requestParameters['lineEq'];
            }
            if (requestParameters['lineLike'] != null) {
                queryParameters['line.like'] = requestParameters['lineLike'];
            }
            const headerParameters = {};
            const response = yield this.request({
                path: `/message`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => DisruptionMessagesFromJSON(jsonValue));
        });
    }
    /**
     * Get a page of disruption messages
     */
    findMessages() {
        return __awaiter(this, arguments, void 0, function* (requestParameters = {}, initOverrides) {
            const response = yield this.findMessagesRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     * Get a page of stations
     */
    findStationsRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            if (requestParameters['schedules'] != null) {
                queryParameters['schedules'] = requestParameters['schedules'];
            }
            if (requestParameters['page'] != null) {
                queryParameters['page'] = requestParameters['page'];
            }
            if (requestParameters['size'] != null) {
                queryParameters['size'] = requestParameters['size'];
            }
            if (requestParameters['sort'] != null) {
                queryParameters['sort'] = requestParameters['sort'];
            }
            if (requestParameters['idEq'] != null) {
                queryParameters['id.eq'] = requestParameters['idEq'];
            }
            if (requestParameters['idLike'] != null) {
                queryParameters['id.like'] = requestParameters['idLike'];
            }
            if (requestParameters['sloidEq'] != null) {
                queryParameters['sloid.eq'] = requestParameters['sloidEq'];
            }
            if (requestParameters['sloidLike'] != null) {
                queryParameters['sloid.like'] = requestParameters['sloidLike'];
            }
            if (requestParameters['nameEq'] != null) {
                queryParameters['name.eq'] = requestParameters['nameEq'];
            }
            if (requestParameters['nameLike'] != null) {
                queryParameters['name.like'] = requestParameters['nameLike'];
            }
            if (requestParameters['shortNameEq'] != null) {
                queryParameters['shortName.eq'] = requestParameters['shortNameEq'];
            }
            if (requestParameters['shortNameLike'] != null) {
                queryParameters['shortName.like'] = requestParameters['shortNameLike'];
            }
            const headerParameters = {};
            const response = yield this.request({
                path: `/station`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => StationsFromJSON(jsonValue));
        });
    }
    /**
     * Get a page of stations
     */
    findStations() {
        return __awaiter(this, arguments, void 0, function* (requestParameters = {}, initOverrides) {
            const response = yield this.findStationsRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     * Get a page of trips
     */
    findTripsRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            if (requestParameters['schedules'] != null) {
                queryParameters['schedules'] = requestParameters['schedules'];
            }
            if (requestParameters['page'] != null) {
                queryParameters['page'] = requestParameters['page'];
            }
            if (requestParameters['size'] != null) {
                queryParameters['size'] = requestParameters['size'];
            }
            if (requestParameters['sort'] != null) {
                queryParameters['sort'] = requestParameters['sort'];
            }
            if (requestParameters['idEq'] != null) {
                queryParameters['id.eq'] = requestParameters['idEq'];
            }
            if (requestParameters['idLike'] != null) {
                queryParameters['id.like'] = requestParameters['idLike'];
            }
            if (requestParameters['lineEq'] != null) {
                queryParameters['line.eq'] = requestParameters['lineEq'];
            }
            if (requestParameters['lineLike'] != null) {
                queryParameters['line.like'] = requestParameters['lineLike'];
            }
            if (requestParameters['lineIdEq'] != null) {
                queryParameters['lineId.eq'] = requestParameters['lineIdEq'];
            }
            if (requestParameters['lineIdLike'] != null) {
                queryParameters['lineId.like'] = requestParameters['lineIdLike'];
            }
            if (requestParameters['destinationEq'] != null) {
                queryParameters['destination.eq'] = requestParameters['destinationEq'];
            }
            if (requestParameters['destinationLike'] != null) {
                queryParameters['destination.like'] = requestParameters['destinationLike'];
            }
            if (requestParameters['agencyEq'] != null) {
                queryParameters['agency.eq'] = requestParameters['agencyEq'];
            }
            if (requestParameters['agencyLike'] != null) {
                queryParameters['agency.like'] = requestParameters['agencyLike'];
            }
            if (requestParameters['stationId'] != null) {
                queryParameters['stationId'] = requestParameters['stationId'];
            }
            const headerParameters = {};
            const response = yield this.request({
                path: `/trip`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => TripsFromJSON(jsonValue));
        });
    }
    /**
     * Get a page of trips
     */
    findTrips() {
        return __awaiter(this, arguments, void 0, function* (requestParameters = {}, initOverrides) {
            const response = yield this.findTripsRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     * Get values that will be used for filtering lines
     */
    getLineFilterRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            if (requestParameters['schedules'] != null) {
                queryParameters['schedules'] = requestParameters['schedules'];
            }
            const headerParameters = {};
            const response = yield this.request({
                path: `/line/filter`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => LineFilterFromJSON(jsonValue));
        });
    }
    /**
     * Get values that will be used for filtering lines
     */
    getLineFilter() {
        return __awaiter(this, arguments, void 0, function* (requestParameters = {}, initOverrides) {
            const response = yield this.getLineFilterRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     * Get the details for a specific trip
     */
    getTripDetailsRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters['id'] == null) {
                throw new runtime.RequiredError('id', 'Required parameter "id" was null or undefined when calling getTripDetails().');
            }
            const queryParameters = {};
            if (requestParameters['schedule'] != null) {
                queryParameters['schedule'] = requestParameters['schedule'];
            }
            const headerParameters = {};
            const response = yield this.request({
                path: `/trip/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => TripDetailsFromJSON(jsonValue));
        });
    }
    /**
     * Get the details for a specific trip
     */
    getTripDetails(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.getTripDetailsRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     * Get the available filter data for trips
     */
    getTripFilterDataRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            if (requestParameters['schedules'] != null) {
                queryParameters['schedules'] = requestParameters['schedules'];
            }
            const headerParameters = {};
            const response = yield this.request({
                path: `/trip/filter`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => TripFilterFromJSON(jsonValue));
        });
    }
    /**
     * Get the available filter data for trips
     */
    getTripFilterData() {
        return __awaiter(this, arguments, void 0, function* (requestParameters = {}, initOverrides) {
            const response = yield this.getTripFilterDataRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
}
