<script lang="ts">
  import { ActionIcon, Button, ModalDialog } from '@pids/shared-component';
  import { type Language, LanguageValues, getTextByLanguage } from 'src/util/languageUtils';
  import TextForm from 'src/components/pages/disruptionTextSnippet/TranslatedTextForm.svelte';
  import { _ } from 'svelte-i18n';
  import { hasPermission } from 'src/user/permissionCheck';
  import { user } from 'src/user/userStore';
  import { Permission } from 'src/user/permissions';
  import type { TextSnippet } from '$generated/service/cache-api';
  import type { TextData } from 'src/model/Text';
  import TextSnippetSelector from './TextSnippetSelector.svelte';

  export let data: Record<Language, TextData[]> | undefined;
  export let readOnly = false;
  export let dialogEnabled = false;

  let textSnippetModalOpen = false;
  let selectedTextSnippet: TextSnippet | null = null;

  const removeText = (index: number, language: Language) => {
    if (!data) return;
    data[language] = data[language].filter((_v, i) => index !== i);
  };

  const addTextLine = (language: Language) => {
    if (!data) return;
    data[language] = [...data[language], { value: '' }];
  };

  const insertTextSnippetText = () => {
    if (selectedTextSnippet) {
      const entries = LanguageValues.map(
        lang =>
          <[Language, TextData[]]>[
            lang,
            getTextByLanguage(lang, selectedTextSnippet!)
              ?.split('\n')
              .map(t => ({ value: t })) ?? [{ value: '' }]
          ]
      );

      data = Object.fromEntries(entries) as Record<Language, TextData[]>;
    }
  };

  const closeTextSnippetDialog = () => {
    textSnippetModalOpen = false;
  };

  const isSelected = (selectedTextSnippet: TextSnippet | null): boolean => {
    return selectedTextSnippet !== null;
  };

  let writable: boolean;
  $: writable = hasPermission($user, Permission.DISRUPTION_TEXT_SNIPPET_WRITE) && !readOnly;
</script>

<style lang="scss">
  @import 'src/styles/variables';

  .fields {
    display: flex;
    flex-direction: column;
    gap: 1.25rem;
  }

  .single-field {
    display: grid;
    flex-direction: row;
    grid-template-columns: 1fr 4fr;
    width: 100%;
    gap: 1.25rem;
    align-items: baseline;
    justify-content: right;
  }

  .single-field-label {
    margin: 0;
    width: 100%;
    display: flex;
    justify-content: start;
    font-weight: 600;
  }

  .single-field-control {
    width: 100%;
  }

  .single-field-control {
    display: flex;
  }

  .form-section-label {
    width: 100%;
    font-size: 1.125rem;
    font-weight: 600;
  }

  .text-content-section {
    display: grid;
    gap: 1rem;
    padding-block: 0.625rem;
  }

  .lock-icon {
    display: flex;
    justify-content: center;
    align-items: center;
  }
</style>

<div class="text-content-section">
  <label for="text" class="label form-section-label">
    {$_('disruption-management.text-snippet.details.field.text-content.title')}
  </label>
  <TextForm
    dialogButton={dialogEnabled && !readOnly}
    on:insertTextSnippet={() => (textSnippetModalOpen = true)}
    let:language>
    <div class="fields">
      {#if data}
        {#each data[language] as _text, i}
          <div class="single-field">
            <label class="label single-field-label" for="text-snippet-name" title={$_('settings.label.url')}>
              {$_('disruption-management.text-snippet.details.field.text-content.text.label', {
                values: { number: `${i + 1}`.padStart(2, '0') }
              })}
            </label>
            <div class="control single-field-control actioned">
              <input
                id="text-snippet-text-row-{i}"
                class="input"
                type="text"
                bind:value={data[language][i].value}
                readonly={!writable} />
              {#if writable}
                {#if data[language].length > 1}
                  <ActionIcon icon="delete-outline" on:click={() => removeText(i, language)} />
                {:else}
                  <ActionIcon icon="lock" disabled />
                {/if}
              {/if}
            </div>
          </div>
        {/each}
      {/if}

      <div class="single-field">
        <div></div>
        <div>
          {#if writable}
            <Button
              icon="plus"
              link={true}
              compact={true}
              text={true}
              style="font-size: inherit; font-weight: inherit; gap: 0.25rem;"
              iconStyle="font-size: 1.25rem"
              className="link-button"
              on:click={() => addTextLine(language)}>
              {$_('disruption-management.text-snippet.details.field.text-content.button.add')}
            </Button>
          {/if}
        </div>
      </div>
    </div>
  </TextForm>
</div>

{#if dialogEnabled}
  <ModalDialog
    bind:active={textSnippetModalOpen}
    title={$_('disruption-management.text-snippet.list.title')}
    okText={$_('button.add')}
    cancelText={$_('button.cancel')}
    showCancel={true}
    okHandler={insertTextSnippetText}
    cancelHandler={closeTextSnippetDialog}
    primaryButtonEnabled={isSelected(selectedTextSnippet)}
    handleEnter={false}
    contentPadding={false}>
    <TextSnippetSelector bind:selectedTextSnippet />
  </ModalDialog>
{/if}
