<script lang="ts">
  import { onDestroy } from 'svelte';
  import { _ } from 'svelte-i18n';
  import { DebouncedTextInput, Icon, NumberedPagination } from '@pids/shared-component';
  import type { TextSnippet } from '$generated/service/cache-api';
  import { fetchDisruptionTextSnippets } from './disruptionTextSnippetService';
  import type { PaginationParams, Query } from 'src/api/query';
  import { createQuery } from 'src/api/query';
  import TextSnippetInsertTable from './TextSnippetInsertTable.svelte';

  export let selectedTextSnippet: TextSnippet | null;

  const initialPagination: PaginationParams = {
    page: 1,
    size: 10,
    sort: 'name,asc'
  };

  let paginationParams: PaginationParams = initialPagination;

  let query = '';
  let textSnippets: TextSnippet[] = [];

  const textSnippetQuery: Query<TextSnippet[]> = createQuery(
    [],
    signal => fetchDisruptionTextSnippets({ search: query }, paginationParams, { signal }),
    false
  );

  $: textSnippets = !$textSnippetQuery.isError ? $textSnippetQuery.results : [];
  $: pagination = !$textSnippetQuery.isError ? $textSnippetQuery.pagination : undefined;
  $: loading = textSnippetQuery.loading;

  const loadData = async () => {
    await textSnippetQuery.next();
  };

  const resetPagination = () => {
    paginationParams = initialPagination;
    return true;
  };

  const handlePageChange = (page: number) => {
    paginationParams = {
      ...paginationParams,
      page
    };
    void loadData();
  };

  onDestroy(() => {
    textSnippetQuery.abort();
  });

  $: query, resetPagination() && loadData();
</script>

<style>
  .bottom-content {
    display: flex;
    justify-content: end;
  }
</style>

<div class="field filter-grid p-4 m-0">
  <div class="control has-icons-left">
    <DebouncedTextInput
      id="search"
      class="input"
      autofocus
      placeholder={$_('placeholder.search')}
      bind:value={query}
      clearable />
    <span class="icon is-left" style="height: 100%;">
      <Icon name="search" style="font-size: 1.25rem;" />
    </span>
  </div>
</div>

<div class="px-4">
  <hr class="m-0" />
  <TextSnippetInsertTable loading={$loading} bind:selectedTextSnippet {textSnippets} />
  <hr class="mt-0" />
</div>

<div class="bottom-content px-4 mb-4">
  <span>
    {#if pagination}
      <NumberedPagination
        page={pagination.page}
        totalPages={pagination.totalPages}
        totalElements={pagination.totalElements}
        onPageChange={handlePageChange} />
    {/if}
  </span>
</div>
