import { _ } from 'svelte-i18n';
import { format, isSameDay } from 'date-fns';
import { get } from 'svelte/store';
const dateOnlyFormat = 'dd.MM.yy';
const timeOnlyFormat = 'HH:mm';
const dateTimeFormat = 'dd.MM.yy (HH:mm)';
/**
 * Returns a formatted string that represents the given interval.
 *
 * @param interval the interval
 */
export const formatInterval = (interval) => {
    const { start, end } = interval;
    if (start && end) {
        if (isSameDay(start, end)) {
            return `${format(start, dateOnlyFormat)} (${format(start, timeOnlyFormat)}/${format(end, timeOnlyFormat)})`;
        }
        return `${format(start, dateTimeFormat)} - ${format(end, dateTimeFormat)}`;
    }
    if (!start && !end) {
        return get(_)('disruption-management.config.column.interval.always');
    }
    if (start && !end) {
        return `${format(start, dateTimeFormat)} - ${get(_)('disruption-management.config.column.interval.no-to')}`;
    }
    if (!start && end) {
        return `${get(_)('disruption-management.config.column.interval.no-from')} - ${format(end, dateTimeFormat)}`;
    }
};
/**
 * Returns the text for the specified language. If such text does not exist, the first available
 * one is returned.
 *
 * @param lang the language
 * @param translations the list of all translations
 */
export const findText = (lang, translations) => {
    var _a;
    if (!lang || !translations || translations.length === 0) {
        return '';
    }
    const langLC = lang.toLowerCase();
    let match = translations.find(t => { var _a; return ((_a = t.language) === null || _a === void 0 ? void 0 : _a.toLowerCase()) === langLC; });
    if (!match) {
        match = translations[0];
    }
    return ((_a = match.text) !== null && _a !== void 0 ? _a : []).join(' ');
};
