<script lang="ts">
  import { Chip, Table, TableCol, ChipType, ChipList } from '@pids/shared-component';
  import type { LoadingState } from '@pids/shared-component';
  import { type Link, toLink } from 'src/components/pages/routingService';
  import { scheduleQuickFilterOptions } from 'src/components/pages/schedule/scheduleService';
  import QuickFilters from 'src/components/shared/QuickFilters.svelte';
  import type { ScheduleAgency } from 'src/generated/service/cache-api';
  import { ScheduleTypeEnum, Protocol, type Schedule } from 'src/generated/service/cache-api';
  import { formatValidityPeriod } from 'src/util/dateTimeUtils';
  import { _ } from 'svelte-i18n';

  export let resultsList: Schedule[];
  export let loading: LoadingState = undefined;

  const scheduleTypeTranslation: Record<ScheduleTypeEnum, string> = {
    [ScheduleTypeEnum.CompleteSchedule]: $_('schedule.type.complete'),
    [ScheduleTypeEnum.DailySchedule]: $_('schedule.type.daily'),
    [ScheduleTypeEnum.ContinuousSchedule]: $_('schedule.type.continuous')
  } as const;

  const scheduleProtocolTranslation: Record<Protocol, string> = {
    [Protocol.Hrdf]: $_('schedule.protocol.hrdf'),
    [Protocol.Vdv454Aus]: $_('schedule.protocol.vdv454aus'),
    [Protocol.Vdv736]: $_('schedule.protocol.vdv736'),
    [Protocol.GtfsStatic]: $_('schedule.protocol.gtfs-static'),
    [Protocol.GtfsRealtime]: $_('schedule.protocol.gtfs-realtime'),
    [Protocol.DisruptionDirect]: $_('schedule.protocol.disruption-direct')
  } as const;

  const getScheduleLinks = (protocol: Protocol | undefined, schedule: string | undefined): Link[] => {
    if (!schedule || !protocol) {
      return [];
    }

    return scheduleQuickFilterOptions[protocol].map(
      type =>
        <Link>{
          type,
          url: toLink({
            type,
            data: {
              schedule
            }
          })
        }
    );
  };

  type AgencyGroup = {
    name: string;
    ids: string[];
  };

  const groupAgencies = (agencies: ScheduleAgency[]) => {
    return Object.values(
      agencies.reduce(
        (a, v) => ({
          ...(a ?? {}),
          [v.name]: {
            name: v.name,
            ids: [...(a[v.name]?.ids ?? []), v.id]
          }
        }),
        (<Record<string, AgencyGroup>>{}) as Record<string, AgencyGroup>
      )
    );
  };
</script>

<Table
  rows={resultsList}
  {loading}
  let:row
  emptyText={$_('table.empty.default')}
  loadingText={$_('table.loading.default')}>
  <TableCol {row} value="id" width="0" header={$_('schedule.column.label.id')} />

  <TableCol {row} value="displayName" width="0" header={$_('schedule.column.label.name')}>
    <span style="text-wrap: nowrap;">{row?.displayName ?? ''}</span>
  </TableCol>

  <TableCol {row} header={$_('schedule.column.label.agencies')} chipped>
    {#if row?.agencies}
      <ChipList>
        {#each groupAgencies(row.agencies) as agency}
          <Chip type={ChipType.Readonly} content="{agency.name}: {agency.ids.join(', ')}" />
        {/each}
      </ChipList>
    {/if}
  </TableCol>

  <TableCol {row} width="0" header={$_('schedule.column.label.validity')}>
    <span style="text-wrap: nowrap;">
      {#if row?.validity}
        {formatValidityPeriod(row.validity)}
      {/if}
    </span>
  </TableCol>

  <TableCol {row} width="0" header={$_('schedule.column.label.type')}>
    <span style="text-wrap: nowrap;">
      {#if row?.type}
        {scheduleTypeTranslation[row.type]}
      {/if}
    </span>
  </TableCol>

  <TableCol {row} width="0" header={$_('schedule.column.label.format')}>
    {#if row?.protocol}
      {scheduleProtocolTranslation[row.protocol]}
    {/if}
  </TableCol>

  <TableCol {row} width="0" header={$_('label.column.quick-filter')}>
    <QuickFilters links={getScheduleLinks(row?.protocol, row?.id)} />
  </TableCol>
</Table>
