<script lang="ts">
  import { _ } from 'svelte-i18n';
  import { DropdownSelect, type DropdownSelectEvents } from '@pids/shared-component';
  import type { StationBase, StationRef } from '$generated/service/cache-api';
  import { searchStations } from 'src/services/stationService';
  import { formatStationName } from 'src/util/stationUtils';

  type $$Events = DropdownSelectEvents<StationRef>;

  export let id: string;
  export let values: StationBase[] = [];
  export let disabled = false;
  export let readonly = false;
  export let hasError: boolean;
</script>

<DropdownSelect
  {id}
  bind:values
  multiple={true}
  request={searchStations}
  placeholder={$_('disruption-management.editor.station-filter.search-stations')}
  empty={$_('select.empty-text')}
  equals={(left, right) => left.sloid === right.sloid}
  {disabled}
  {hasError}
  showClearIcon={!readonly}
  {readonly}
  on:remove
  on:clear
  on:change
  let:item>
  {formatStationName(item)}
</DropdownSelect>
