/* tslint:disable */
/* eslint-disable */
/**
 * Netcetera PIDS - Hub Cache API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.6.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { CreateEditLineFilterFromJSON, CreateEditLineFilterToJSON, } from './CreateEditLineFilter';
import { DisruptionScopeFromJSON, DisruptionScopeToJSON, } from './DisruptionScope';
import { DisruptionMessageCauseEnumFromJSON, DisruptionMessageCauseEnumToJSON, } from './DisruptionMessageCauseEnum';
import { IntervalFromJSON, IntervalToJSON, } from './Interval';
/**
 * Check if a given object implements the CreateEditDisruptionMessageConfig interface.
 */
export function instanceOfCreateEditDisruptionMessageConfig(value) {
    if (!('validity' in value) || value['validity'] === undefined)
        return false;
    if (!('cause' in value) || value['cause'] === undefined)
        return false;
    if (!('active' in value) || value['active'] === undefined)
        return false;
    if (!('disruptionScope' in value) || value['disruptionScope'] === undefined)
        return false;
    return true;
}
export function CreateEditDisruptionMessageConfigFromJSON(json) {
    return CreateEditDisruptionMessageConfigFromJSONTyped(json, false);
}
export function CreateEditDisruptionMessageConfigFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'validity': IntervalFromJSON(json['validity']),
        'cause': DisruptionMessageCauseEnumFromJSON(json['cause']),
        'active': json['active'],
        'textEn': json['textEn'] == null ? undefined : json['textEn'],
        'textDe': json['textDe'] == null ? undefined : json['textDe'],
        'textIt': json['textIt'] == null ? undefined : json['textIt'],
        'textFr': json['textFr'] == null ? undefined : json['textFr'],
        'disruptionScope': DisruptionScopeFromJSON(json['disruptionScope']),
        'regionIds': json['regionIds'] == null ? undefined : json['regionIds'],
        'stationSloids': json['stationSloids'] == null ? undefined : json['stationSloids'],
        'lineFilters': json['lineFilters'] == null ? undefined : (json['lineFilters'].map(CreateEditLineFilterFromJSON)),
    };
}
export function CreateEditDisruptionMessageConfigToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'validity': IntervalToJSON(value['validity']),
        'cause': DisruptionMessageCauseEnumToJSON(value['cause']),
        'active': value['active'],
        'textEn': value['textEn'],
        'textDe': value['textDe'],
        'textIt': value['textIt'],
        'textFr': value['textFr'],
        'disruptionScope': DisruptionScopeToJSON(value['disruptionScope']),
        'regionIds': value['regionIds'],
        'stationSloids': value['stationSloids'],
        'lineFilters': value['lineFilters'] == null ? undefined : (value['lineFilters'].map(CreateEditLineFilterToJSON)),
    };
}
