<script lang="ts">
  import { ModalDialog } from '@pids/shared-component';
  import { _ } from 'svelte-i18n';
  import type { CreateEditVehicleCategoryMapping } from '$generated/service/cache-api';
  import { createEventDispatcher } from 'svelte';
  import { isNotBlank } from 'src/util/stringUtils';
  import VehicleCategoryMappingForm from 'src/components/pages/vehiclecategorymapping/VehicleCategoryMappingForm.svelte';

  const dispatch = createEventDispatcher<{
    save: CreateEditVehicleCategoryMapping;
    close: void;
  }>();

  export let show = false;
  export let title: string = '';
  export let initial: CreateEditVehicleCategoryMapping | undefined = undefined;

  let data: Partial<CreateEditVehicleCategoryMapping>;

  const handleSave = () => {
    dispatch('save', data as CreateEditVehicleCategoryMapping);
  };

  const handleClose = () => {
    dispatch('close');
  };

  const validate = (data: Partial<CreateEditVehicleCategoryMapping>) => {
    return (
      Boolean(data) &&
      isNotBlank(data.from) &&
      isNotBlank(data.to) &&
      isNotBlank(data.textDe) &&
      isNotBlank(data.textEn) &&
      isNotBlank(data.textFr) &&
      isNotBlank(data.textIt)
    );
  };

  $: isValid = validate(data);
</script>

<ModalDialog
  {title}
  bind:active={show}
  okText={$_('button.ok')}
  cancelText={$_('button.cancel')}
  overflow="visible"
  showCancel={true}
  okHandler={handleSave}
  cancelHandler={handleClose}
  primaryButtonEnabled={isValid}
  width={920}>
  <VehicleCategoryMappingForm {initial} bind:data />
</ModalDialog>
