<script lang="ts">
  import { _ } from 'svelte-i18n';
  import type { VehicleCategoryMappingCriteria } from 'src/components/pages/vehiclecategorymapping/vehicleCategoryMappingService';
  import { DebouncedTextInput, Icon } from '@pids/shared-component';

  export let filter: VehicleCategoryMappingCriteria;
</script>

<div class="columns">
  <div class="column">
    <label for="text-search">
      {$_('vehicle-category-mapping.filter.text.label')}
    </label>
    <div class="control has-icons-left">
      <DebouncedTextInput
        id="text-search"
        class="input"
        placeholder={$_('vehicle-category-mapping.filter.text.placeholder')}
        bind:value={filter.text}
        clearable />
      <span class="icon is-left">
        <Icon name="search" style="font-size: 1.25rem;" />
      </span>
    </div>
  </div>
  <div class="column">
    <label for="text-search">
      {$_('vehicle-category-mapping.filter.from.label')}
    </label>
    <div class="control has-icons-left">
      <DebouncedTextInput
        id="text-search"
        class="input"
        placeholder={$_('vehicle-category-mapping.filter.from.placeholder')}
        bind:value={filter.from}
        clearable />
      <span class="icon is-left">
        <Icon name="search" style="font-size: 1.25rem;" />
      </span>
    </div>
  </div>
  <div class="column">
    <label for="text-search">
      {$_('vehicle-category-mapping.filter.to.label')}
    </label>
    <div class="control has-icons-left">
      <DebouncedTextInput
        id="text-search"
        class="input"
        placeholder={$_('vehicle-category-mapping.filter.to.placeholder')}
        bind:value={filter.to}
        clearable />
      <span class="icon is-left">
        <Icon name="search" style="font-size: 1.25rem;" />
      </span>
    </div>
  </div>
</div>
