/* tslint:disable */
/* eslint-disable */
/**
 * Netcetera PIDS - Hub Cache API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.6.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * Check if a given object implements the StationItem interface.
 */
export function instanceOfStationItem(value) {
    if (!('id' in value) || value['id'] === undefined)
        return false;
    if (!('sloid' in value) || value['sloid'] === undefined)
        return false;
    if (!('name' in value) || value['name'] === undefined)
        return false;
    return true;
}
export function StationItemFromJSON(json) {
    return StationItemFromJSONTyped(json, false);
}
export function StationItemFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'id': json['id'],
        'sloid': json['sloid'],
        'name': json['name'],
        'shortName': json['shortName'] == null ? undefined : json['shortName'],
    };
}
export function StationItemToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'id': value['id'],
        'sloid': value['sloid'],
        'name': value['name'],
        'shortName': value['shortName'],
    };
}
