<script lang="ts">
  import { _ } from 'svelte-i18n';
  import type { Region } from '$generated/service/cache-api';
  import {
    ActionIcon,
    type LoadingState,
    Table,
    TableCol,
    Button,
    Chip,
    ChipType,
    ChipList
  } from '@pids/shared-component';
  import { DisruptionRegionSort } from 'src/components/pages/disruptionregion/disruptionRegionSort';
  import { createEventDispatcher } from 'svelte';
  import Restricted from 'src/components/shared/Restricted.svelte';
  import { Permission } from 'src/user/permissions';
  import { formatStationName } from 'src/util/stationUtils';

  export let resultsList: Region[];
  export let loading: LoadingState = undefined;

  const dispatch = createEventDispatcher<{
    sort: DisruptionRegionSort;
    delete: Region;
    edit: Region;
  }>();

  const dispatchSortEvent = (sort: DisruptionRegionSort) => {
    dispatch('sort', sort);
  };

  const dispatchDelete = (region: Region) => {
    dispatch('delete', region);
  };

  const dispatchEdit = (region: Region) => {
    dispatch('edit', region);
  };
</script>

<style>
  .hide-overflow {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    line-clamp: 1;
    -webkit-box-orient: vertical;
  }

  .actions {
    display: flex;
    gap: 1rem;
  }
</style>

<Table
  rows={resultsList}
  {loading}
  let:row
  emptyText={$_('table.empty.default')}
  loadingText={$_('table.loading.default')}>
  <TableCol
    {row}
    header={$_('disruption-region.column.name.header-label')}
    value="name"
    className="w20"
    sortable
    onHeaderClick={() => dispatchSortEvent(DisruptionRegionSort.Name)} />
  <TableCol {row} header={$_('disruption-region.column.description.header-label')} className="w20">
    <span class="hide-overflow">{row?.description ?? ''}</span>
  </TableCol>
  <TableCol {row} header={$_('disruption-region.column.stations.header-label')} chipped>
    {#if row?.stations}
      <ChipList>
        {#each row.stations as station}
          <Chip type={ChipType.Readonly}>
            {formatStationName(station)}
          </Chip>
        {/each}
      </ChipList>
    {/if}
  </TableCol>
  <Restricted to={Permission.DISRUPTION_REGION_WRITE}>
    <TableCol {row} header={$_('disruption-region.column.actions.header-label')} width="0">
      <div class="actions">
        {#if row}
          <Button
            text
            compact
            style="font-size: inherit; font-weight: inherit; gap: 0.25rem;"
            iconStyle="font-size: 1.25rem"
            icon="edit"
            className="link-button"
            on:click={() => dispatchEdit(row)}>
            {$_('button.edit')}
          </Button>
          <ActionIcon icon="delete-outline" on:click={() => dispatchDelete(row)} />
        {/if}
      </div>
    </TableCol>
  </Restricted>
  <Restricted to={Permission.ORGANIZATION_READ}>
    <TableCol {row} header={$_('disruption-region.column.organization.header-label')} value="organization">
      {row?.organization?.name ?? ''}
    </TableCol>
  </Restricted>
</Table>
