/* tslint:disable */
/* eslint-disable */
/**
 * Netcetera PIDS - Hub Cache API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.6.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { DateRangeFromJSON, DateRangeToJSON, } from './DateRange';
import { ScheduleAgencyFromJSON, ScheduleAgencyToJSON, } from './ScheduleAgency';
import { ProtocolFromJSON, ProtocolToJSON, } from './Protocol';
/**
 * @export
 */
export const ScheduleTypeEnum = {
    CompleteSchedule: 'COMPLETE_SCHEDULE',
    DailySchedule: 'DAILY_SCHEDULE',
    ContinuousSchedule: 'CONTINUOUS_SCHEDULE'
};
/**
 * Check if a given object implements the Schedule interface.
 */
export function instanceOfSchedule(value) {
    if (!('id' in value) || value['id'] === undefined)
        return false;
    if (!('displayName' in value) || value['displayName'] === undefined)
        return false;
    if (!('type' in value) || value['type'] === undefined)
        return false;
    if (!('protocol' in value) || value['protocol'] === undefined)
        return false;
    if (!('validity' in value) || value['validity'] === undefined)
        return false;
    return true;
}
export function ScheduleFromJSON(json) {
    return ScheduleFromJSONTyped(json, false);
}
export function ScheduleFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'id': json['id'],
        'displayName': json['displayName'],
        'type': json['type'],
        'agencies': json['agencies'] == null ? undefined : (json['agencies'].map(ScheduleAgencyFromJSON)),
        'protocol': ProtocolFromJSON(json['protocol']),
        'validity': DateRangeFromJSON(json['validity']),
    };
}
export function ScheduleToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'id': value['id'],
        'displayName': value['displayName'],
        'type': value['type'],
        'agencies': value['agencies'] == null ? undefined : (value['agencies'].map(ScheduleAgencyToJSON)),
        'protocol': ProtocolToJSON(value['protocol']),
        'validity': DateRangeToJSON(value['validity']),
    };
}
