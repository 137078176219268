/* tslint:disable */
/* eslint-disable */
/**
 * Netcetera PIDS - Hub Cache API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.6.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { OrganizationFromJSON, OrganizationToJSON, } from './Organization';
/**
 * Check if a given object implements the TextSnippet interface.
 */
export function instanceOfTextSnippet(value) {
    if (!('id' in value) || value['id'] === undefined)
        return false;
    if (!('name' in value) || value['name'] === undefined)
        return false;
    return true;
}
export function TextSnippetFromJSON(json) {
    return TextSnippetFromJSONTyped(json, false);
}
export function TextSnippetFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'id': json['id'],
        'name': json['name'],
        'textIt': json['textIt'] == null ? undefined : json['textIt'],
        'textFr': json['textFr'] == null ? undefined : json['textFr'],
        'textEn': json['textEn'] == null ? undefined : json['textEn'],
        'textDe': json['textDe'] == null ? undefined : json['textDe'],
        'organization': json['organization'] == null ? undefined : OrganizationFromJSON(json['organization']),
    };
}
export function TextSnippetToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'id': value['id'],
        'name': value['name'],
        'textIt': value['textIt'],
        'textFr': value['textFr'],
        'textEn': value['textEn'],
        'textDe': value['textDe'],
        'organization': OrganizationToJSON(value['organization']),
    };
}
