<script lang="ts">
  import { Button } from '@pids/shared-component';
  import {
    createDisruptionTextSnippet,
    getDisruptionTextSnippet,
    updateDisruptionTextSnippet
  } from 'src/components/pages/disruptionTextSnippet/disruptionTextSnippetService';
  import { getTextByLanguage, LanguageValues, setText, type Language } from 'src/util/languageUtils';
  import TextConfigurer from 'src/components/pages/disruptionTextSnippet/TextConfigurer.svelte';
  import type { CreateEditTextSnippet, TextSnippet } from 'src/generated/service/cache-api';
  import { _ } from 'svelte-i18n';
  import { params } from 'svelte-spa-router';
  import { hasPermission } from 'src/user/permissionCheck';
  import { user } from 'src/user/userStore';
  import { Permission } from 'src/user/permissions';
  import type { TextData } from 'src/model/Text';
  import { hasNonEmptyText } from 'src/util/textUtils';
  import { back } from 'src/stores/navigation';
  import { toDisruptionMessageTextSnippetLink } from 'src/components/pages/routingService';

  type TextSnippedModel = {
    id?: number;
    name: string;
    text: Record<Language, TextData[]>;
  };

  let data: TextSnippedModel | undefined;

  let nameInteracted: boolean;

  const textDataFrom = (textSnippet: TextSnippet): TextSnippedModel => {
    const textsEntries = LanguageValues.map(
      l =>
        <[Language, TextData[]]>[
          l,
          getTextByLanguage(l, textSnippet)
            ?.split('\n')
            .map(v => ({ value: v })) ?? [{ value: '' }]
        ]
    );
    return {
      id: textSnippet.id,
      name: textSnippet.name,
      text: Object.fromEntries(textsEntries) as Record<Language, TextData[]>
    };
  };

  const setInitialData = async () => {
    data = await loadData();
  };

  const loadData = async () => {
    const idParam = $params?.['id'];
    if (!idParam) {
      return defaultTextData();
    }

    const id = parseInt(idParam);
    if (isNaN(id)) {
      return defaultTextData();
    }

    const textSnippet = await getDisruptionTextSnippet(id);
    if (!textSnippet) {
      return defaultTextData();
    }

    return textDataFrom(textSnippet);
  };

  const defaultTextData = (): TextSnippedModel => {
    const textEntries = LanguageValues.map(l => <[Language, TextData[]]>[l, [{ value: '' }]]);

    return {
      name: '',
      text: Object.fromEntries(textEntries) as Record<Language, TextData[]>
    };
  };

  const save = async () => {
    if (!data) return;
    const request: CreateEditTextSnippet = {
      name: data.name
    };

    const entries = Object.entries(data.text) as [Language, TextData[]][];
    entries.forEach(([language, values]) => {
      setText(values.map(t => t.value).join('\n'), language, request);
    });

    if (data.id) {
      await updateDisruptionTextSnippet(data.id, request);
    } else {
      await createDisruptionTextSnippet(request);
    }

    handleBack();
  };

  const validateTextSnippet = (data: TextSnippedModel | undefined) => {
    return Boolean(data && data.name && hasNonEmptyText(data.text));
  };

  const handleBack = () => {
    back(toDisruptionMessageTextSnippetLink({}));
  };

  $: isValid = validateTextSnippet(data);
  $: $params, setInitialData();
  $: writable = hasPermission($user, Permission.DISRUPTION_TEXT_SNIPPET_WRITE);
</script>

<style lang="scss">
  @import 'src/styles/variables';

  .details-title {
    display: flex;
    gap: 1rem;
    line-height: $line-height-large;
  }

  .title-content {
    display: flex;
    gap: 0.625rem;
    padding-inline: 1rem;
  }

  .title-text {
    font-size: 1.25rem;
    font-weight: $font-weight-semi-bold;
    line-height: $line-height-large;
  }

  .details-page {
    display: grid;
    gap: 1.25rem;
  }

  .fields {
    display: flex;
    flex-direction: column;
    gap: 1.25rem;
  }

  .single-field {
    display: grid;
    flex-direction: row;
    grid-template-columns: 1fr 4fr;
    width: 100%;
    gap: 1.25rem;
    align-items: baseline;
    justify-content: right;
  }

  .single-field-label {
    margin: 0;
    width: 100%;
    display: flex;
    justify-content: start;
    font-weight: 600;
  }

  .single-field-control {
    width: 100%;
    display: flex;
  }

  input.invalid {
    outline-color: $alert-error !important;
    outline: 0.0625rem solid;
    outline-offset: 0.06rem;
  }
</style>

<div class="details-page">
  <div class="details-title">
    <Button
      link={true}
      compact={true}
      text={true}
      icon="arrow-back"
      style="font-size: inherit; font-weight: inherit; gap: 0.25rem;"
      iconStyle="font-size: 1.25rem"
      className="link-button"
      on:click={handleBack}>
      <div class="title-content">
        <span class="title-text">
          {$_('disruption-management.text-snippet.details.title')}
        </span>
      </div>
    </Button>
  </div>
  <div class="fields">
    <div class="single-field">
      <label class="label single-field-label" for="text-snippet-name" title={$_('settings.label.url')}>
        {$_('disruption-management.text-snippet.details.field.name.label')}
      </label>
      <div class="control single-field-control">
        {#if data}
          <input
            id="text-snippet-name"
            class="input"
            type="text"
            class:invalid={nameInteracted && !data.name}
            bind:value={data.name}
            readonly={!writable}
            on:blur={() => (nameInteracted = true)} />
        {/if}
      </div>
    </div>
  </div>

  {#if data}
    <TextConfigurer bind:data={data.text} />
  {/if}
  <div class="buttons">
    <Button icon="arrow-back" primary={true} outlined={true} on:click={handleBack}>
      {$_('button.back')}
    </Button>
    {#if writable}
      <Button icon="save" primary={true} disabled={!isValid} on:click={save}>
        {$_('button.save')}
      </Button>
    {/if}
  </div>
</div>
