/**
 * Returns whether at least one text is non-empty
 *
 * @param texts texts to check
 */
export const hasNonEmptyText = (texts) => {
    return Boolean(texts &&
        Object.values(texts)
            .flatMap(s => s)
            .filter(s => s.value.trim()).length > 0);
};
