/* tslint:disable */
/* eslint-disable */
/**
 * Netcetera PIDS - Hub Cache API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.6.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 *
 * @export
 */
export const Accessibility = {
    Wheelchair: 'WHEELCHAIR',
    WheelchairPartially: 'WHEELCHAIR_PARTIALLY',
    WheelchairReservation: 'WHEELCHAIR_RESERVATION',
    WheelchairSubstituteTransport: 'WHEELCHAIR_SUBSTITUTE_TRANSPORT',
    WheelchairInaccessible: 'WHEELCHAIR_INACCESSIBLE',
    WheelchairUncertain: 'WHEELCHAIR_UNCERTAIN'
};
export function instanceOfAccessibility(value) {
    for (const key in Accessibility) {
        if (Object.prototype.hasOwnProperty.call(Accessibility, key)) {
            if (Accessibility[key] === value) {
                return true;
            }
        }
    }
    return false;
}
export function AccessibilityFromJSON(json) {
    return AccessibilityFromJSONTyped(json, false);
}
export function AccessibilityFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function AccessibilityToJSON(value) {
    return value;
}
