/* tslint:disable */
/* eslint-disable */
/**
 * Netcetera PIDS - Hub Cache API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.6.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { StationRefFromJSON, StationRefToJSON, } from './StationRef';
/**
 * Check if a given object implements the CreateEditRegion interface.
 */
export function instanceOfCreateEditRegion(value) {
    if (!('name' in value) || value['name'] === undefined)
        return false;
    if (!('description' in value) || value['description'] === undefined)
        return false;
    return true;
}
export function CreateEditRegionFromJSON(json) {
    return CreateEditRegionFromJSONTyped(json, false);
}
export function CreateEditRegionFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'name': json['name'],
        'description': json['description'],
        'stations': json['stations'] == null ? undefined : (json['stations'].map(StationRefFromJSON)),
    };
}
export function CreateEditRegionToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'name': value['name'],
        'description': value['description'],
        'stations': value['stations'] == null ? undefined : (value['stations'].map(StationRefToJSON)),
    };
}
