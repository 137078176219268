<script lang="ts">
  import type { DisruptionTextSnippetFilterCriteria } from 'src/components/pages/disruptionTextSnippet/disruptionTextSnippetService';
  import { _ } from 'svelte-i18n';
  import { DebouncedTextInput, Icon } from '@pids/shared-component';
  import Restricted from 'src/components/shared/Restricted.svelte';
  import { Permission } from 'src/user/permissions';
  import OrganizationFilter from 'src/components/shared/OrganizationFilter.svelte';

  export let filter: DisruptionTextSnippetFilterCriteria;
</script>

<div class="columns">
  <div class="column is-one-quarter">
    <label for="text-search">
      {$_('disruption-management.text-snippet.filter.search.label')}
    </label>
    <div class="control has-icons-left">
      <DebouncedTextInput
        id="text-search"
        class="input"
        placeholder={$_('disruption-management.text-snippet.filter.search.placeholder')}
        bind:value={filter.search}
        clearable />
      <span class="icon is-left">
        <Icon name="search" style="font-size: 1.25rem;" />
      </span>
    </div>
  </div>

  <Restricted to={Permission.ORGANIZATION_READ}>
    <div class="column is-one-quarter">
      <OrganizationFilter
        bind:selectedOrganizationId={filter.organizationId}
        emptyText={$_('disruption-management.text-snippet.filter.organization.no-items-text')}
        placeholder={$_('disruption-management.text-snippet.filter.organization.placeholder')}>
        {$_('disruption-management.text-snippet.filter.organization.label')}
      </OrganizationFilter>
    </div>
  </Restricted>
</div>
