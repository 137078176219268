<script lang="ts">
  import { _ } from 'svelte-i18n';
  import { DropdownSelect, type DropdownSelectEvents } from '@pids/shared-component';
  import type { Region, RegionRef } from '$generated/service/cache-api';
  import { disruptionRegionApi } from 'src/api/apiClients';
  import { errorHandlerWithDefault } from 'src/util/errorUtils';

  type $$Events = DropdownSelectEvents<RegionRef>;

  export let id: string;
  export let values: RegionRef[] = [];
  export let disabled = false;
  export let readonly = false;
  export let hasError: boolean;

  const format = (r: RegionRef): string => {
    return r.name;
  };

  const searchRegions = async (nameQuery: string): Promise<Region[]> => {
    return await disruptionRegionApi
      .searchRegions({
        name: nameQuery
      })
      .catch(errorHandlerWithDefault([]));
  };
</script>

<DropdownSelect
  {id}
  bind:values
  multiple={true}
  request={searchRegions}
  placeholder={$_('disruption-management.editor.station-filter.search-regions')}
  empty={$_('select.empty-text')}
  equals={(left, right) => left.id === right.id}
  minimumLetters={1}
  showClearIcon={!readonly}
  {disabled}
  {hasError}
  {readonly}
  on:remove
  on:clear
  on:change
  let:item>
  {format(item)}
</DropdownSelect>
