export const paginationParamName = {
    page: 'page',
    size: 'size',
    sort: 'sort'
};
export const initializePaginationFromQueryParams = (params, defaultPaginationParams) => {
    var _a, _b, _c;
    return {
        page: Number((_a = params.get(paginationParamName.page)) !== null && _a !== void 0 ? _a : defaultPaginationParams.page),
        size: Number((_b = params.get(paginationParamName.size)) !== null && _b !== void 0 ? _b : defaultPaginationParams.size),
        sort: (_c = params.get(paginationParamName.sort)) !== null && _c !== void 0 ? _c : defaultPaginationParams.sort
    };
};
