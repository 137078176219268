<script lang="ts">
  import { onMount } from 'svelte';
  import { _ } from 'svelte-i18n';
  import { Icon, PageLayout, ColoredLine, Button } from '@pids/shared-component';
  import type { AffectedStation, DisruptionMessage } from '$generated/service/cache-api';
  import type { FetchResult } from 'src/api/query';
  import { selectedMessage } from './messageStore';
  import TextRow from './TextRow.svelte';
  import { formatInterval } from './messageUtils';
  import { fetchMessage } from './messageService';
  import { querystring } from 'svelte-spa-router';
  import { messageDetailsParamNames } from 'src/components/pages/message/types';
  import MessageScope from 'src/components/pages/message/MessageScope.svelte';
  import { back } from 'src/stores/navigation';
  import { toMessagesLink } from 'src/components/pages/routingService';

  $: queryParams = new URLSearchParams($querystring);
  $: id = queryParams.get(messageDetailsParamNames.id);
  $: scheduleId = queryParams.get(messageDetailsParamNames.schedule);

  let message: DisruptionMessage | undefined;

  onMount(() => {
    message = $selectedMessage;

    if (!message) {
      fetchMessageDetails();
    } else if (message.id !== id || message.schedule.id !== scheduleId) {
      throw Error('The provided message does not match the url params');
    }
  });

  const fetchMessageDetails = async () => {
    if (!id || !scheduleId) {
      return;
    }

    const response: FetchResult<DisruptionMessage> = await fetchMessage(id, scheduleId);
    message = response.isError ? undefined : response.results;
  };

  const formatStationName = (station: AffectedStation): string => {
    return `${station.name} (` + (station.shortName ? `${station.shortName}, ` : '') + `${station.id})`;
  };

  const handleBack = () => {
    back(toMessagesLink({}));
  };

  $: id, scheduleId, fetchMessageDetails();
</script>

<style lang="scss">
  @import 'src/styles/variables';

  #message-details-tbl tr td:first-child {
    font-weight: 600;
  }

  .h-list li {
    display: inline-block;
    margin-right: 0.5rem;
  }

  .details-title {
    display: flex;
    gap: 1rem;
    line-height: $line-height-large;
  }

  .title-content {
    display: flex;
    gap: 0.625rem;
    padding-inline: 1rem;
  }

  .title-text {
    font-size: 1.25rem;
    font-weight: $font-weight-semi-bold;
    line-height: $line-height-large;
  }
</style>

{#if message}
  <PageLayout>
    <svelte:fragment slot="title">
      <div class="details-title">
        <Button
          link={true}
          compact={true}
          text={true}
          icon="arrow-back"
          style="font-size: inherit; font-weight: inherit; gap: 0.25rem;"
          iconStyle="font-size: 1.25rem"
          className="link-button"
          on:click={handleBack}>
          <div class="title-content">
            <h4 class="title-text">
              {$_('message.details.title')}
            </h4>
          </div>
        </Button>
      </div>
    </svelte:fragment>

    <svelte:fragment slot="content">
      <table id="message-details-tbl" class="table is-hoverable is-fullwidth">
        <colgroup>
          <col span="1" style="width: 15%" />
          <col span="1" style="width: 85%" />
        </colgroup>
        <tbody>
          <tr>
            <td>{$_('message.column.id.label')}</td>
            <td>{message.id}</td>
          </tr>
          <tr>
            <td>{$_('message.column.intervals.label')}</td>
            <td>
              {#if message.activeIntervals}
                {#each message.activeIntervals as interval}
                  <p>{formatInterval(interval)}</p>
                {/each}
              {/if}
            </td>
          </tr>
          <tr>
            <td>{$_('message.column.cause.label')}</td>
            <td>{message.cause}</td>
          </tr>
          <tr>
            <td>{$_('message.details.scope.label')}</td>
            <td class="chipped">
              <MessageScope {message} />
            </td>
          </tr>
          <tr>
            <td>{$_('message.column.schedule.label')}</td>
            <td>{message.schedule.name ?? message.schedule.id}</td>
          </tr>
          {#if message.stations && message.stations.length > 0}
            <tr>
              <td>{$_('message.column.stations.label')}</td>
              <td class="chipped">
                <ul>
                  {#each message.stations as station}
                    <li>
                      <span>
                        <Icon name="location" />
                        {formatStationName(station)}
                      </span>
                    </li>
                  {/each}
                </ul>
              </td>
            </tr>
          {/if}
          {#if message.lines && message.lines.length > 0}
            <tr>
              <td>{$_('message.column.lines.label')}</td>
              <td class="chipped">
                <ul class="h-list">
                  {#each message.lines as af}
                    <li>
                      {#if af.line.name}
                        <ColoredLine
                          line={af.line.name}
                          background={af.line.color?.background}
                          foreground={af.line.color?.foreground}
                          border={af.line.color?.border} />
                      {:else}
                        <p>{af.line.id}</p>
                      {/if}
                    </li>
                  {/each}
                </ul>
              </td>
            </tr>
          {/if}
          <TextRow label={$_('message.column.text.label')} texts={message.texts} />
        </tbody>
      </table>
    </svelte:fragment>
  </PageLayout>
{/if}
