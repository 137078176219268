// permissions available on the backend
// need to be in sync with the backend
export var Permission;
(function (Permission) {
    Permission["SCHEDULE_READ"] = "SCHEDULE_READ";
    Permission["AGENCY_READ"] = "AGENCY_READ";
    Permission["LINE_READ"] = "LINE_READ";
    Permission["STATION_READ"] = "STATION_READ";
    Permission["TRIP_READ"] = "TRIP_READ";
    Permission["MESSAGE_READ"] = "MESSAGE_READ";
    Permission["MARKETING_READ"] = "MARKETING_READ";
    Permission["MARKETING_WRITE"] = "MARKETING_WRITE";
    Permission["DISRUPTION_TEXT_SNIPPET_READ"] = "DISRUPTION_TEXT_SNIPPET_READ";
    Permission["DISRUPTION_TEXT_SNIPPET_WRITE"] = "DISRUPTION_TEXT_SNIPPET_WRITE";
    Permission["LINE_CATEGORY_READ"] = "LINE_CATEGORY_READ";
    Permission["LINE_COLOR_READ"] = "LINE_COLOR_READ";
    Permission["SERVICE_POINT_READ"] = "SERVICE_POINT_READ";
    Permission["DISRUPTION_CAUSE_READ"] = "DISRUPTION_CAUSE_READ";
    Permission["FEED_MANAGEMENT"] = "FEED_MANAGEMENT";
    Permission["ORGANIZATION_READ"] = "ORGANIZATION_READ";
    Permission["VEHICLE_CATEGORY_MAPPING_READ"] = "VEHICLE_CATEGORY_MAPPING_READ";
    Permission["VEHICLE_CATEGORY_MAPPING_WRITE"] = "VEHICLE_CATEGORY_MAPPING_WRITE";
    Permission["DISRUPTION_REGION_READ"] = "DISRUPTION_REGION_READ";
    Permission["DISRUPTION_REGION_WRITE"] = "DISRUPTION_REGION_WRITE";
    Permission["DISRUPTION_MESSAGE_CONFIG_READ"] = "DISRUPTION_MESSAGE_CONFIG_READ";
    Permission["DISRUPTION_MESSAGE_CONFIG_WRITE"] = "DISRUPTION_MESSAGE_CONFIG_WRITE";
})(Permission || (Permission = {}));
