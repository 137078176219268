<script lang="ts">
  import { DropdownSelect } from '@pids/shared-component';
  import type { Organization } from 'src/generated/service/cache-api';
  import { getAllOrganizations } from 'src/services/organizationService';
  import { onMount } from 'svelte';
  import type { SvelteEvent } from 'src/model/event';

  export let selectedOrganizationId: number | undefined = undefined;

  export let placeholder: string = '';
  export let emptyText: string = '';

  let organizations: Organization[];

  const organizationFilter = (name: string) => {
    return organizations.filter(o => o.name.includes(name));
  };

  const onChange = ({ detail }: SvelteEvent<Organization[]>) => {
    selectedOrganizationId = detail?.length === 1 ? detail[0].id : undefined;
  };

  const onClear = () => {
    selectedOrganizationId = undefined;
  };

  onMount(async () => {
    organizations = await getAllOrganizations();
  });

  $: selectedOrganization = organizations?.find(value => value.id === selectedOrganizationId);
</script>

{#if organizations}
  <label for="organizatoin-filter">
    <slot />
  </label>
  <div class="control">
    <DropdownSelect
      id="organizatoin-filter"
      items={organizations}
      bind:value={selectedOrganization}
      filter={organizationFilter}
      {placeholder}
      empty={emptyText}
      readonly={Boolean(selectedOrganization)}
      on:change={onChange}
      on:clear={onClear}
      let:item>
      {item.name}
    </DropdownSelect>
  </div>
{/if}
