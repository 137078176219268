/* tslint:disable */
/* eslint-disable */
/**
 * Netcetera PIDS - Hub Cache API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.6.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * Check if a given object implements the ServicePoint interface.
 */
export function instanceOfServicePoint(value) {
    return true;
}
export function ServicePointFromJSON(json) {
    return ServicePointFromJSONTyped(json, false);
}
export function ServicePointFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'sloid': json['sloid'] == null ? undefined : json['sloid'],
        'countryCode': json['countryCode'] == null ? undefined : json['countryCode'],
        'number': json['number'] == null ? undefined : json['number'],
        'name': json['name'] == null ? undefined : json['name'],
        'abbreviation': json['abbreviation'] == null ? undefined : json['abbreviation'],
        'vehicleCategories': json['vehicleCategories'] == null ? undefined : json['vehicleCategories'],
        'businessOrganization': json['businessOrganization'] == null ? undefined : json['businessOrganization'],
        'businessOrganizationNumber': json['businessOrganizationNumber'] == null ? undefined : json['businessOrganizationNumber'],
        'validFrom': json['validFrom'] == null ? undefined : (new Date(json['validFrom'])),
        'validTo': json['validTo'] == null ? undefined : (new Date(json['validTo'])),
    };
}
export function ServicePointToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'sloid': value['sloid'],
        'countryCode': value['countryCode'],
        'number': value['number'],
        'name': value['name'],
        'abbreviation': value['abbreviation'],
        'vehicleCategories': value['vehicleCategories'],
        'businessOrganization': value['businessOrganization'],
        'businessOrganizationNumber': value['businessOrganizationNumber'],
        'validFrom': value['validFrom'] == null ? undefined : ((value['validFrom']).toISOString().substring(0, 10)),
        'validTo': value['validTo'] == null ? undefined : ((value['validTo']).toISOString().substring(0, 10)),
    };
}
