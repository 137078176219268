<script lang="ts">
  import type { NativeEvent } from 'src/model/event';
  import { toHtmlId } from 'src/util/htmlUtils';
  import { createEventDispatcher } from 'svelte';

  const dispatch = createEventDispatcher<{
    change: T;
  }>();

  type T = $$Generic;

  export let id: string;
  export let value: T;
  export let options: T[];
  export let disabled = false;
  export let readonly = false;

  export let optionsDisplay: Map<T, string> | undefined = undefined;
  export let optionsDisabled: Map<T, boolean> | undefined = undefined;
  export let optionIds: Map<T, string> | undefined = undefined;

  export let spacing: string | undefined = undefined;

  const dispatchChangeEvent = (newValue: T) => {
    dispatch('change', newValue);
  };

  const handleChangeEvent = (e: NativeEvent<Event, HTMLInputElement>) => {
    dispatchChangeEvent(e.currentTarget.value as T);
  };

  const toOptionHtmlId = (option: T, alt: string | number) => {
    return toHtmlId(optionIds?.get(option) ?? alt.toString(), id);
  };
</script>

<style lang="scss">
  @import 'src/styles/variables';

  .radio-group {
    display: flex;
    flex-direction: row;
    gap: 2rem;
    user-select: none;
  }

  label.disabled {
    color: $medium-emphasis;
  }

  .readonly {
    pointer-events: none;
  }
</style>

<div class="radio-group" style:gap={spacing}>
  {#each options as option, i (toOptionHtmlId(option, i))}
    {@const optionHtmlId = toOptionHtmlId(option, i)}
    <div class="field m-0" class:readonly>
      <input
        class="is-checkradio"
        class:checked={value !== option}
        id={optionHtmlId}
        bind:group={value}
        {readonly}
        radioGroup={id}
        type="radio"
        value={option}
        disabled={optionsDisabled?.get(option) || disabled}
        on:change={handleChangeEvent} />
      <label class="label interval-fields" class:disabled={value !== option} for={optionHtmlId}>
        {#if optionsDisplay?.get(option)}
          {optionsDisplay?.get(option)}
        {:else}
          <slot {option} {optionHtmlId} {value} />
        {/if}
      </label>
    </div>
  {/each}
</div>
