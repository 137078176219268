import { wrapPaginated } from 'src/api/query/fetchResult';
import { configurationDataApi } from 'src/api/apiClients';
import { isNotBlank } from 'src/util/stringUtils';
import { applyStringParam } from 'src/api/paramUtils';
export const lineCategoriesParamNames = {
    transportType: 'transportType',
    category: 'category',
    transportGroup: 'transportGroup'
};
export const fetchLineCategories = (filterCriteria, pagination, options) => {
    const params = createFilterParams(filterCriteria, pagination);
    const func = () => configurationDataApi.findLineCategories(params, options);
    return wrapPaginated(func);
};
const createFilterParams = (filterCriteria, pagination) => {
    const request = {
        page: pagination.page,
        size: pagination.size,
        sort: pagination.sort
    };
    if (isNotBlank(filterCriteria.category)) {
        request.category = filterCriteria.category;
    }
    if (isNotBlank(filterCriteria.transportGroup)) {
        request.transportGroup = filterCriteria.transportGroup;
    }
    applyStringParam({
        value: filterCriteria.transportType,
        target: request,
        eqField: 'transportTypeEq',
        likeField: 'transportTypeLike'
    });
    return request;
};
