/* tslint:disable */
/* eslint-disable */
/**
 * Netcetera PIDS - Hub Cache API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.6.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * Check if a given object implements the CreateEditVehicleCategoryMapping interface.
 */
export function instanceOfCreateEditVehicleCategoryMapping(value) {
    if (!('from' in value) || value['from'] === undefined)
        return false;
    if (!('to' in value) || value['to'] === undefined)
        return false;
    if (!('textDe' in value) || value['textDe'] === undefined)
        return false;
    if (!('textFr' in value) || value['textFr'] === undefined)
        return false;
    if (!('textIt' in value) || value['textIt'] === undefined)
        return false;
    if (!('textEn' in value) || value['textEn'] === undefined)
        return false;
    return true;
}
export function CreateEditVehicleCategoryMappingFromJSON(json) {
    return CreateEditVehicleCategoryMappingFromJSONTyped(json, false);
}
export function CreateEditVehicleCategoryMappingFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'from': json['from'],
        'to': json['to'],
        'textDe': json['textDe'],
        'textFr': json['textFr'],
        'textIt': json['textIt'],
        'textEn': json['textEn'],
    };
}
export function CreateEditVehicleCategoryMappingToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'from': value['from'],
        'to': value['to'],
        'textDe': value['textDe'],
        'textFr': value['textFr'],
        'textIt': value['textIt'],
        'textEn': value['textEn'],
    };
}
