<script lang="ts">
  import { DebouncedTextInput, Icon } from '@pids/shared-component';
  import { _ } from 'svelte-i18n';
  import type { AgencyFilterCriteria } from 'src/components/pages/agency/agencyService';
  import ScheduleSelect from 'src/components/shared/ScheduleSelect.svelte';
  import { QuickFilterType } from 'src/components/pages/routingService';

  export let filter: AgencyFilterCriteria;
</script>

<div class="columns">
  <div class="column is-one-quarter">
    <label for="id-search">
      {$_('agency.filter.id.label')}
    </label>
    <div class="control has-icons-left">
      <DebouncedTextInput
        id="id-search"
        class="input"
        placeholder={$_('agency.filter.placeholder.name')}
        bind:value={filter.id}
        clearable />
      <span class="icon is-left">
        <Icon name="search" style="font-size: 1.25rem;" />
      </span>
    </div>
  </div>
  <div class="column is-one-quarter">
    <label for="said-search">
      {$_('agency.filter.said.label')}
    </label>
    <div class="control has-icons-left">
      <DebouncedTextInput
        id="said-search"
        class="input"
        placeholder={$_('agency.filter.placeholder.name')}
        bind:value={filter.said}
        clearable />
      <span class="icon is-left">
        <Icon name="search" style="font-size: 1.25rem;" />
      </span>
    </div>
  </div>
  <div class="column is-one-quarter">
    <label for="fullname-search">
      {$_('agency.filter.full-name.label')}
    </label>
    <div class="control has-icons-left">
      <DebouncedTextInput
        id="fullname-search"
        class="input"
        placeholder={$_('agency.filter.placeholder.name')}
        bind:value={filter.fullname}
        clearable />
      <span class="icon is-left">
        <Icon name="search" style="font-size: 1.25rem;" />
      </span>
    </div>
  </div>

  <div class="column is-one-quarter">
    <label for="schedule-select">
      {$_('agency.filter.schedule.label')}
    </label>
    <div class="control">
      <ScheduleSelect bind:value={filter.schedule} type={QuickFilterType.Agency} id="schedule-select" />
    </div>
  </div>
</div>
