import { __awaiter } from "tslib";
import { wrapPaginated } from 'src/api/query/fetchResult';
import { disruptionTextSnippetApi } from 'src/api/apiClients';
import { errorHandlerWithDefault } from 'src/util/errorUtils';
import { applyStringParam } from 'src/api/paramUtils';
export const disruptionMessageTextSnippetParamNames = {
    search: 'search',
    organization: 'organization'
};
export const fetchDisruptionTextSnippets = (filterCriteria, pagination, options) => {
    const params = createFilterParams(filterCriteria, pagination);
    const func = () => disruptionTextSnippetApi.getAllTextSnippets(params, options);
    return wrapPaginated(func);
};
export const getDisruptionTextSnippet = (id, options) => __awaiter(void 0, void 0, void 0, function* () {
    return yield disruptionTextSnippetApi.getTextSnippet({ id }, options).catch(errorHandlerWithDefault(undefined));
});
export const createDisruptionTextSnippet = (data) => __awaiter(void 0, void 0, void 0, function* () {
    return yield disruptionTextSnippetApi
        .createTextSnippet({ createEditTextSnippet: data })
        .catch(errorHandlerWithDefault(undefined));
});
export const updateDisruptionTextSnippet = (id, data) => __awaiter(void 0, void 0, void 0, function* () {
    return yield disruptionTextSnippetApi
        .updateTextSnippet({ id, createEditTextSnippet: data })
        .catch(errorHandlerWithDefault(undefined));
});
export const deleteDisruptionTextSnippet = (id) => __awaiter(void 0, void 0, void 0, function* () {
    return yield disruptionTextSnippetApi.deleteTextSnippet({ id }).catch(errorHandlerWithDefault(undefined));
});
const createFilterParams = (filterCriteria, pagination) => {
    const request = {
        page: pagination.page,
        size: pagination.size,
        sort: [pagination.sort]
    };
    applyStringParam({
        value: filterCriteria.search,
        target: request,
        eqField: 'searchEq',
        likeField: 'searchLike'
    });
    if (filterCriteria.organizationId != undefined) {
        request.organizationId = filterCriteria.organizationId;
    }
    return request;
};
