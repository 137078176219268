<script lang="ts">
  import { initializePaginationFromQueryParams, type PaginationParams } from 'src/api/query/pagination';
  import type { SortFunction } from 'src/api/query/sort';
  import { type Pagination, type Line, type LineFilter as LineFilterModel } from '$generated/service/cache-api';
  import type { SvelteEvent } from 'src/model/event';
  import { lineParamNames, type LineFilterCriteria } from 'src/components/pages/line/lineService';
  import { onMount } from 'svelte';
  import { writable } from 'svelte/store';
  import { _ } from 'svelte-i18n';
  import { NumberedPagination, PageLayout, notificationService, type LoadingStateStore } from '@pids/shared-component';
  import { LineSort } from 'src/components/pages/line/lineSort';
  import { sortBy } from 'src/api/query/sort';
  import { createQuery } from 'src/api/query/query';
  import { fetchLines, fetchLineFilterData } from 'src/components/pages/line/lineService';
  import ClearFiltersButton from 'src/components/shared/ClearFiltersButton.svelte';
  import LineFilter from 'src/components/pages/line/LineFilter.svelte';
  import LineTable from 'src/components/pages/line/LineTable.svelte';
  import { DEFAULT_ERROR_TEXT_KEY } from 'src/api/notification';
  import { querystring, replace } from 'svelte-spa-router';
  import { toLinesLink } from 'src/components/pages/routingService';
  import { defaultAbortReason } from 'src/api/query';

  const abortController = new AbortController();

  const initialPagination: PaginationParams = {
    page: 1,
    size: 50,
    sort: `${LineSort.Name},asc`
  };

  const sortFunction: SortFunction = sortBy(LineSort.Name);
  let paginationParams: PaginationParams = initialPagination;

  let results: Line[] = [];
  let pagination: Pagination | undefined;
  let loading: LoadingStateStore;
  let filter = writable<LineFilterCriteria>({});

  let filterData: LineFilterModel = {
    agencies: [],
    types: []
  };

  // keeps the latest loaded schedule to avoid unnecessary fetching on "clear filters"
  let filterDataSchedule: string | undefined = '_';

  const resultsQuery = createQuery([], signal => fetchLines($filter, paginationParams, { signal }), false);

  let clearFiltersDisabled: boolean;
  $: clearFiltersDisabled = filtersAreEmpty($filter);

  $: results = !$resultsQuery.isError ? $resultsQuery.results : [];
  $: pagination = !$resultsQuery.isError ? $resultsQuery.pagination : undefined;
  $: loading = resultsQuery.loading;

  $: $resultsQuery.isError && notificationService.error($resultsQuery.error.message ?? $_(DEFAULT_ERROR_TEXT_KEY));

  onMount(() => {
    clearFilters();
    handleQueryString($querystring);

    const unsubscribeFilter = filter.subscribe(() => {
      resetPageAndSort();
      void loadData();
    });

    loadFilterData($filter.schedule);

    return () => {
      unsubscribeFilter();
      abortController.abort(defaultAbortReason([]));
      resultsQuery.abort();
    };
  });

  const handleQueryString = (query: string | undefined) => {
    const params = new URLSearchParams(query);

    paginationParams = initializePaginationFromQueryParams(params, paginationParams);
    $filter = initializeCriteriaFromQueryParams(params);
  };

  const initializeCriteriaFromQueryParams = (params: URLSearchParams): LineFilterCriteria => {
    return {
      ...$filter,
      id: params.get(lineParamNames.id) ?? undefined,
      agency: params.get(lineParamNames.agency) ?? undefined,
      name: params.get(lineParamNames.name) ?? undefined,
      type: params.get(lineParamNames.type) ?? undefined,
      schedule: params.get(lineParamNames.schedule) ?? undefined
    };
  };

  const loadData = async () => {
    updateUrlParameters();
    await resultsQuery.next();
  };

  const updateUrlParameters = () => {
    replace(toLinesLink({ ...$filter, schedule: $filter.schedule }, paginationParams));
  };

  const loadFilterData = async (scheduleId?: string | undefined) => {
    if (filterDataSchedule === scheduleId) {
      return;
    }

    filterDataSchedule = scheduleId;
    filterData = await fetchLineFilterData(scheduleId, { signal: abortController.signal });
  };

  const handleSortChange = (e: SvelteEvent<{ sort: LineSort }>) => {
    const newSort = sortFunction(e.detail.sort);

    paginationParams = {
      ...paginationParams,
      sort: newSort
    };

    void loadData();
  };

  const resetPageAndSort = () => {
    paginationParams = initialPagination;
  };

  const handlePageChange = (page: number) => {
    paginationParams = {
      ...paginationParams,
      page
    };
    void loadData();
  };

  const filtersAreEmpty = (filter: LineFilterCriteria): boolean => {
    return !filter.id && !filter.name && !filter.type && !filter.agency && !filter.schedule;
  };

  const clearFilters = () => {
    loadFilterData();
    $filter = {};
  };

  // NOTE: whenever the schedule changes, agencies & types must be cleared and reloaded
  // for the newly selected schedule
  const handleScheduleChange = (scheduleId: string | undefined) => {
    loadFilterData(scheduleId);

    $filter = {
      ...$filter,
      schedule: scheduleId,
      agency: undefined,
      type: undefined
    };
  };
</script>

<PageLayout title={$_('line.page.title')}>
  <svelte:fragment slot="criteria">
    <LineFilter bind:filter={$filter} {filterData} onScheduleChange={handleScheduleChange} />
  </svelte:fragment>

  <svelte:fragment slot="actions">
    <ClearFiltersButton on:click={clearFilters} disabled={clearFiltersDisabled} />
  </svelte:fragment>

  <svelte:fragment slot="content">
    <LineTable loading={$loading} resultsList={results} on:sort={handleSortChange} />
  </svelte:fragment>

  <svelte:fragment slot="pagination">
    {#if !$loading && pagination}
      <NumberedPagination {...pagination} onPageChange={handlePageChange} />
    {/if}
  </svelte:fragment>
</PageLayout>
