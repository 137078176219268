/* tslint:disable */
/* eslint-disable */
/**
 * Netcetera PIDS - Hub Cache API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.6.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { LineVariantFromJSON, LineVariantToJSON, } from './LineVariant';
/**
 * Check if a given object implements the LineVariants interface.
 */
export function instanceOfLineVariants(value) {
    if (!('lineId' in value) || value['lineId'] === undefined)
        return false;
    if (!('variants' in value) || value['variants'] === undefined)
        return false;
    return true;
}
export function LineVariantsFromJSON(json) {
    return LineVariantsFromJSONTyped(json, false);
}
export function LineVariantsFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'lineId': json['lineId'],
        'variants': (json['variants'].map(LineVariantFromJSON)),
    };
}
export function LineVariantsToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'lineId': value['lineId'],
        'variants': (value['variants'].map(LineVariantToJSON)),
    };
}
