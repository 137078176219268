import { __awaiter } from "tslib";
import { disruptionRegionApi } from 'src/api/apiClients';
import { wrapPaginated } from 'src/api/query';
import { applyStringParam } from 'src/api/paramUtils';
import { errorHandlerWithDefault } from 'src/util/errorUtils';
import { ClientError } from 'src/api/httpErrorHandling';
export const disruptionRegionParamNames = {
    name: 'name',
    station: 'station',
    organization: 'organization'
};
export const fetchDisruptionRegion = (filterCriteria, pagination, options) => {
    const params = createFilterParams(filterCriteria, pagination);
    const func = () => disruptionRegionApi.getAllRegions(params, options);
    return wrapPaginated(func);
};
export const getDisruptionRegion = (id, options) => __awaiter(void 0, void 0, void 0, function* () {
    return yield disruptionRegionApi.getRegion({ id }, options).catch(errorHandlerWithDefault(undefined));
});
export const createDisruptionRegion = (data) => __awaiter(void 0, void 0, void 0, function* () {
    return yield disruptionRegionApi.createRegion({ createEditRegion: data }).catch(errorHandlerWithDefault(undefined));
});
export const deleteDisruptionRegion = (id) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        yield disruptionRegionApi.deleteRegion({ id });
        return {
            ok: true
        };
    }
    catch (e) {
        if (e instanceof ClientError) {
            if (e.response.status === 409) {
                return {
                    ok: false,
                    conflict: true
                };
            }
        }
        return {
            ok: false
        };
    }
});
export const updateDisruptionRegion = (id, data) => __awaiter(void 0, void 0, void 0, function* () {
    return yield disruptionRegionApi
        .updateRegion({ id, createEditRegion: data })
        .catch(errorHandlerWithDefault(undefined));
});
export const fetchUsedStations = () => __awaiter(void 0, void 0, void 0, function* () {
    return yield disruptionRegionApi.findUsedStations().catch(errorHandlerWithDefault([]));
});
const createFilterParams = (filterCriteria, pagination) => {
    const request = {
        page: pagination.page,
        size: pagination.size,
        sort: [pagination.sort]
    };
    applyStringParam({
        value: filterCriteria.name,
        target: request,
        eqField: 'nameEq',
        likeField: 'nameLike'
    });
    if (filterCriteria.stationSloid) {
        request.stationSloid = filterCriteria.stationSloid;
    }
    if (filterCriteria.organizationId) {
        request.organizationId = filterCriteria.organizationId;
    }
    return request;
};
