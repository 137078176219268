<script lang="ts">
  import { onMount } from 'svelte';
  import { _ } from 'svelte-i18n';
  import { fetchLineVariants } from 'src/services/lineService';
  import type { LineVariant, StationRef } from '$generated/service/cache-api';
  import {
    Button,
    ModalDialog,
    TagSelector,
    TagSelectionType,
    getAlphabetByIndex,
    type TagSelectionItem,
    type TagSelection,
    type TagSelectionValue
  } from '@pids/shared-component';

  export let show: boolean = false;
  export let lineId: string;
  export let value: StationRef[] = [];

  let values: TagSelectionValue[] = value.map(val => Object.assign(val, { id: val.sloid, name: val.name }));
  let data: TagSelection<TagSelectionItem>[];

  const onApplyHandler = () => {
    value = values.map(val => Object.assign({}, { sloid: val.id, name: val.name }));
    show = false;
  };

  const onResetHandler = () => {
    values = [];
  };

  const onCancelHandler = () => {
    show = false;
  };

  onMount(async () => {
    const lineVariants: LineVariant[] =
      (await fetchLineVariants(lineId).then(lineVariants => lineVariants?.variants)) ?? [];
    const alphabet = getAlphabetByIndex(lineVariants.length);
    data =
      lineVariants.map((lineVariant, index) => ({
        tag: $_('disruption-management.config.line-variant.prefix') + ' ' + alphabet[index],
        items: lineVariant.stops.map((stop, index) => Object.assign(stop, { id: stop.sloid, index }))
      })) ?? [];
  });
</script>

<style>
  footer {
    background-color: #fff;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    align-items: center;
    flex-shrink: 0;
    justify-content: flex-start;
    padding: 20px;
    position: relative;
  }
</style>

<ModalDialog
  title="Select Section"
  active={show}
  okText={$_('button.apply')}
  cancelText={$_('button.cancel')}
  cancelHandler={onCancelHandler}
  overflow="visible"
  width={520}>
  <TagSelector type={TagSelectionType.Window} bind:values {data} noItemsText={$_('select.empty-text')}>
    <svelte:fragment slot="items-title"
      >{$_('disruption-management.config.line-variant.segment.title')}</svelte:fragment>
  </TagSelector>
  <svelte:fragment slot="footer">
    <footer class="is-flex is-justify-content-space-between">
      <div class="is-flex">
        <Button outlined style="min-width: 120px; font-weight: 600;" on:click={onResetHandler}>
          {$_('disruption-management.config.line-variant.reset')}
        </Button>
      </div>
      <div class="is-flex">
        <Button text style="min-width: 120px; font-weight: 600;" on:click={onCancelHandler}>
          {$_('button.cancel')}
        </Button>
        <Button primary style="min-width: 120px; font-weight: 600;" on:click={onApplyHandler}>
          {$_('button.apply')}
        </Button>
      </div>
    </footer>
  </svelte:fragment>
</ModalDialog>
