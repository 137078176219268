<script lang="ts">
  import { _ } from 'svelte-i18n';
  import { type LoadingState, Table, TableCol, ActionIcon } from '@pids/shared-component';
  import type { VehicleCategoryMapping } from '$generated/service/cache-api';
  import { Permission } from 'src/user/permissions';
  import Restricted from 'src/components/shared/Restricted.svelte';
  import { createEventDispatcher } from 'svelte';
  import { VehicleCategoryMappingSort } from 'src/components/pages/vehiclecategorymapping/vehicleCategoryMappingSort';

  export let resultsList: VehicleCategoryMapping[];
  export let loading: LoadingState = undefined;

  const dispatch = createEventDispatcher<{
    sort: { sort: VehicleCategoryMappingSort };
    edit: VehicleCategoryMapping;
    delete: VehicleCategoryMapping;
  }>();

  const dispatchSortEvent = (sort: VehicleCategoryMappingSort) => {
    dispatch('sort', { sort });
  };

  const dispatchDelete = (data: VehicleCategoryMapping) => {
    dispatch('delete', data);
  };

  const dispatchEdit = (data: VehicleCategoryMapping) => {
    dispatch('edit', data);
  };
</script>

<Table
  rows={resultsList}
  {loading}
  let:row
  emptyText={$_('table.empty.default')}
  loadingText={$_('table.loading.default')}>
  <TableCol
    {row}
    header={$_('vehicle-category-mapping.column.from.header-label')}
    value="from"
    sortable
    onHeaderClick={() => dispatchSortEvent(VehicleCategoryMappingSort.From)} />
  <TableCol
    {row}
    header={$_('vehicle-category-mapping.column.to.header-label')}
    value="to"
    sortable
    onHeaderClick={() => dispatchSortEvent(VehicleCategoryMappingSort.To)} />
  <TableCol {row} header={$_('vehicle-category-mapping.column.text-de.header-label')} value="textDe" />
  <TableCol {row} header={$_('vehicle-category-mapping.column.text-fr.header-label')} value="textFr" />
  <TableCol {row} header={$_('vehicle-category-mapping.column.text-it.header-label')} value="textIt" />
  <TableCol {row} header={$_('vehicle-category-mapping.column.text-en.header-label')} value="textEn" />
  <Restricted to={Permission.VEHICLE_CATEGORY_MAPPING_WRITE}>
    <TableCol {row} header={$_('vehicle-category-mapping.column.actions.header-label')}>
      {#if row}
        <ActionIcon icon="edit_note" on:click={() => dispatchEdit(row)} />
        <ActionIcon icon="delete-outline" on:click={() => dispatchDelete(row)} />
      {/if}
    </TableCol>
  </Restricted>
</Table>
