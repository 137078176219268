<script lang="ts">
  import { _ } from 'svelte-i18n';
  import { Table, TableCol } from '@pids/shared-component';
  import type { TextSnippet } from '$generated/service/cache-api';
  import { config } from 'src/config/static';
  import { type Language, getTextByLanguage } from 'src/util/languageUtils';

  export let textSnippets: TextSnippet[];
  export let selectedTextSnippet: TextSnippet | null;
  export let loading: boolean = false;

  const lang = $config.disruptionLanguages![0] as Language;

  const textFormatter = (ts: TextSnippet): string => {
    return getTextByLanguage(lang, ts) ?? '';
  };

  let selectedValue: TextSnippet[] = [];
  $: selectedTextSnippet = selectedValue[0] ?? null;
</script>

<Table
  rows={textSnippets}
  bind:selectedRows={selectedValue}
  selectionMode={true}
  {loading}
  singleSelect={true}
  let:row
  emptyText={$_('table.empty.default')}
  loadingText={$_('table.loading.default')}>
  <TableCol
    {row}
    header={$_('disruption-management.text-snippet.column.name.header-label')}
    value="name"
    selectable={false} />

  <TableCol
    {row}
    header={$_('disruption-management.text-snippet.column.text.header-label')}
    value="textEn"
    selectable={false}>
    {textFormatter(row)}
  </TableCol>
</Table>
