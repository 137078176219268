import { wrapPaginated } from 'src/api/query/fetchResult';
import { cacheApi } from 'src/api/apiClients';
import { applyStringParam } from 'src/api/paramUtils';
import { isNotBlank } from 'src/util/stringUtils';
export const lineParamNames = {
    id: 'id',
    schedule: 'schedule',
    agency: 'agency',
    type: 'type',
    name: 'name'
};
export const fetchLines = (filter, pagination, options) => {
    const params = createFilterParams(filter, pagination);
    const func = () => cacheApi.findLines(params, options);
    return wrapPaginated(func);
};
export const fetchLineFilterData = (schedule, options) => {
    const req = {};
    if (isNotBlank(schedule)) {
        req.schedules = [schedule];
    }
    try {
        return cacheApi.getLineFilter(req, options);
    }
    catch (e) {
        console.error('Failed to fetch line filter data', e);
        return Promise.resolve({
            agencies: [],
            types: []
        });
    }
};
const createFilterParams = (filterCriteria, pagination) => {
    const request = {};
    applyStringParam({ value: filterCriteria.id, target: request, eqField: 'idEq', likeField: 'idLike' });
    applyStringParam({ value: filterCriteria.name, target: request, eqField: 'nameEq', likeField: 'nameLike' });
    request.type = filterCriteria.type;
    request.agency = filterCriteria.agency;
    if (filterCriteria.schedule && isNotBlank(filterCriteria.schedule)) {
        request.schedules = [filterCriteria.schedule];
    }
    request.page = pagination.page;
    request.size = pagination.size;
    request.sort = pagination.sort;
    return request;
};
