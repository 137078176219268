import { derived, get, writable } from 'svelte/store';
const NOT_DEFINED = 'NOT_DEFINED';
const configWriter = writable({
    hubBaseUrl: NOT_DEFINED,
    hubCacheApiBasePath: NOT_DEFINED,
    hubDataApiBasePath: NOT_DEFINED,
    keycloakConfigUrl: NOT_DEFINED,
    licenseBasePath: NOT_DEFINED,
    environmentName: undefined,
    environmentColor: undefined,
    disruptionLanguages: []
});
const applicationConfigUrl = '/static/ui-config.json';
export const loadApplicationConfiguration = () => {
    const currentHostname = window.location.origin;
    console.log('Fetching app config for hostname:', currentHostname);
    return window
        .fetch(applicationConfigUrl)
        .then(response => response.json())
        .then((response) => {
        setConfig(response, currentHostname);
    })
        .catch(error => {
        console.error('Failed to load app config', error);
    });
};
const setConfig = (response, hostname) => {
    const props = response[hostname];
    if (!props) {
        console.error('Props not found for hostname', hostname);
        return;
    }
    console.log('Received app config', props);
    configWriter.set(props);
};
/**
 * Holds the loaded application configuration.
 */
export const config = derived(configWriter, $c => $c);
/**
 * Convenience function for getting the latest config in
 * cases where subscribing to the store is unneeded.
 */
export const getConfig = () => get(config);
// intended for testing only, do not use in production code
export const updateConfig = (update) => {
    configWriter.update(current => (Object.assign(Object.assign({}, current), update)));
};
