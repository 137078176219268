<script lang="ts">
  import type { DisruptionConfigSnapshot } from 'src/components/pages/disruptionMessageConfig/editor/types';
  import DateTimeInput from 'src/components/shared/DateTimeInput.svelte';
  import { _ } from 'svelte-i18n';

  export let id: string;
  export let snapshot: DisruptionConfigSnapshot;

  export let readonly = false;

  export let invalid = false;
  export let invalidStart = false;
  export let invalidEnd = false;

  let startTime: string | undefined;
  let endTime: string | undefined;

  const validate = (snapshot: DisruptionConfigSnapshot) => {
    return (
      snapshot &&
      snapshot.validityStart !== undefined &&
      snapshot.validityEnd !== undefined &&
      snapshot.validityEnd < snapshot.validityStart
    );
  };

  $: invalid = startTime && endTime ? validate(snapshot) : false;
</script>

<style>
  .duration-row {
    display: flex;
    gap: 2rem;
  }
</style>

<div class="duration-row">
  <DateTimeInput
    id="{id}-interval-start"
    bind:value={snapshot.validityStart}
    bind:invalid={invalidStart}
    bind:time={startTime}
    {readonly}
    maxDate={snapshot.validityEnd}
    validateTime={Boolean(endTime && snapshot.validityStart)}
    dateLabel={$_('disruption-management.editor.validity.start-date')}
    timeLabel={$_('disruption-management.editor.validity.start-time')} />
  <DateTimeInput
    id="{id}-interval-end"
    bind:value={snapshot.validityEnd}
    bind:invalid={invalidEnd}
    bind:time={endTime}
    {readonly}
    minDate={snapshot.validityStart}
    validateTime={Boolean(startTime && snapshot.validityEnd)}
    dateLabel={$_('disruption-management.editor.validity.end-date')}
    timeLabel={$_('disruption-management.editor.validity.end-time')} />
</div>
