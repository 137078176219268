<script lang="ts">
  import { _ } from 'svelte-i18n';
  import { ActionIcon, Button, Chip, ChipType, DropdownSelect, FieldControl } from '@pids/shared-component';
  import { Direction, type Line, type StationRef } from '$generated/service/cache-api';
  import type { LineFilterParams } from '../types';
  import DisruptionMessageConfigLineVariantModal from 'src/components/pages/disruptionMessageConfig/DisruptionMessageConfigLineVariantModal.svelte';
  import { isEmpty } from 'src/util/objectUtils';

  export let id: string;
  export let filter: LineFilterParams;
  export let deletable: boolean;
  export let readOnly: boolean;
  export let onEditLine: () => void;
  export let onRemove: () => void;

  let showLineVariantModal = false;

  const directionOptions = Object.values(Direction);

  const directionTranslations: Record<Direction, string> = {
    [Direction.Both]: 'disruption-management.editor.line-filter.direction.both',
    [Direction.Inbound]: 'disruption-management.editor.line-filter.direction.inbound',
    [Direction.Outbound]: 'disruption-management.editor.line-filter.direction.outbound'
  };

  let line: Line | undefined;
  $: line = filter.line;

  let interactedLineSelection = false;
  let onEditLineWrapper = () => {
    if (readOnly) return;
    interactedLineSelection = true;
    onEditLine();
  };

  const hasWhiteBg = (bg?: string): boolean => {
    return !!bg && ['#FFFFFF', '#FFF', 'WHITE'].includes(bg.trim().toUpperCase());
  };

  const getBorder = (line: Line) => {
    return hasWhiteBg(line.color.background) ? line.color.border || line.color.foreground : line.color.background;
  };

  const getStationsDisplay = (items: StationRef[]): string => {
    if (isEmpty(items)) {
      return $_('disruption-management.editor.line-filter.section.all');
    } else if (items.length === 1) {
      return items[0].name;
    }

    return `${items[0].name} -  ${items[items.length - 1].name}`;
  };
</script>

<style lang="scss">
  @import 'src/styles/variables';

  .line-item-container {
    display: grid;
    grid-template-columns: 1fr auto;
    gap: 2rem;
  }

  .line-item-input-container {
    display: grid;
    gap: 0.625rem;
    max-width: 50rem;
  }

  .invalid {
    outline-color: $alert-error !important;
    outline: 0.0625rem solid;
    outline-offset: 0.06rem;
  }

  .line-selector-row {
    display: grid;
    grid-template-columns: 2fr 1fr;
    gap: 2rem;
  }

  .section-row {
    display: flex;
    justify-content: space-between;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    border-top: solid 0.1rem #d7d7d7;
    border-bottom: solid 0.1rem #d7d7d7;
  }

  .chip-input-container {
    padding: 0.3125rem 0;
    box-sizing: border-box;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    min-height: 2.1875rem;
    height: fit-content;
    padding-left: 1rem;
  }

  .chip-input {
    padding: 0;
    margin: 0.3125rem 0;
    font-size: 1rem;
    visibility: hidden;
    line-height: 23px;
    width: 0;
    border: none;
  }

  .field-container {
    display: grid;
    grid-template-columns: 1fr auto;
    align-items: center;
    gap: 0.5rem;
    width: 100%;
  }

  .delete-icon {
    padding-inline: 0.25rem;
  }

  .readonly {
    pointer-events: none;
  }
</style>

<div class="line-item-container">
  <div class="line-item-input-container">
    <div class="line-selector-row">
      <FieldControl id="station-search">
        <svelte:fragment slot="label">
          {$_('disruption-management.editor.line-filter.line.control-label')}
        </svelte:fragment>

        <svelte:fragment slot="control">
          <div class="field-container">
            <div
              class="input chip-input-container"
              class:invalid={interactedLineSelection && !line}
              on:click={onEditLineWrapper}
              role="none">
              <input class="chip-input" value={line} />
              {#if line}
                <Chip
                  type={ChipType.Interactive}
                  content={`${line.name} ${line.agency} ${line.type} (${line.id})`}
                  border={`solid ${getBorder(line)} 0.1rem`}
                  fg={line.color.foreground}
                  bg={line.color.background}
                  on:click={onEditLineWrapper} />
              {/if}
            </div>
            {#if !readOnly}
              <div>
                <Button
                  text={true}
                  compact={true}
                  icon="edit"
                  opacityDisabledStyle={true}
                  className="icon-text has-text-link has-text-weight-bold"
                  on:click={onEditLineWrapper}>
                  {$_('disruption-management.editor.line-filter.line.edit')}
                </Button>
              </div>
            {/if}
          </div>
        </svelte:fragment>
      </FieldControl>

      <FieldControl id="{id}-line-direction">
        <svelte:fragment slot="label">
          {$_('disruption-management.editor.line-filter.direction')}
        </svelte:fragment>

        <svelte:fragment slot="control">
          <div class:readonly={readOnly}>
            <DropdownSelect
              bind:value={filter.direction}
              height="2.8125rem"
              id="cause-select"
              items={directionOptions}
              readonly={true}
              showClearIcon={false}
              let:item>
              {$_(directionTranslations[item])}
            </DropdownSelect>
          </div>
        </svelte:fragment>
      </FieldControl>
    </div>

    <div class="section-row">
      <span>
        <b>{$_('disruption-management.editor.line-filter.section.label')}:</b>
        {getStationsDisplay(filter?.stations ?? [])}
      </span>
      {#if !readOnly}
        <Button
          text={true}
          compact={true}
          icon="edit"
          disabled={!line}
          opacityDisabledStyle={true}
          className="icon-text has-text-link has-text-weight-bold"
          on:click={() => (showLineVariantModal = true)}>
          {$_('disruption-management.editor.line-filter.section.edit')}
        </Button>
      {/if}
    </div>
  </div>

  <div class="delete-icon">
    {#if deletable}
      <ActionIcon icon="delete-outline" on:click={onRemove} />
    {:else}
      <ActionIcon icon="lock" disabled />
    {/if}
  </div>
</div>

{#if showLineVariantModal && filter?.line?.id}
  <DisruptionMessageConfigLineVariantModal
    lineId={filter.line.id}
    bind:show={showLineVariantModal}
    bind:value={filter.stations} />
{/if}
