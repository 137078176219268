import { __awaiter } from "tslib";
import { DisruptionMessageCauseEnum } from '$generated/service/cache-api';
import { wrapPaginated } from 'src/api/query/fetchResult';
import { disruptionMessageApi } from 'src/api/apiClients';
import { errorHandlerWithDefault } from 'src/util/errorUtils';
import { isNotBlank } from 'src/util/stringUtils';
export const messageConfigParamNames = {
    cause: 'cause',
    from: 'from',
    to: 'to',
    organization: 'organization'
};
export const fetchDisruptionMessageConfig = (filterCriteria, pagination, options) => {
    const params = createFilterParams(filterCriteria, pagination);
    const func = () => disruptionMessageApi.getAllDisruptionMessageConfigurations(params, options);
    return wrapPaginated(func);
};
export const getDisruptionMessageConfig = (uid, options) => __awaiter(void 0, void 0, void 0, function* () {
    return yield disruptionMessageApi.getDisruptionMessage({ uid }, options).catch(errorHandlerWithDefault(undefined));
});
export const createDisruptionMessageConfig = (data) => __awaiter(void 0, void 0, void 0, function* () {
    return yield disruptionMessageApi
        .createDisruptionMessage({ createEditDisruptionMessageConfig: data })
        .catch(errorHandlerWithDefault(undefined));
});
export const updateDisruptionMessageConfig = (uid, data) => __awaiter(void 0, void 0, void 0, function* () {
    return yield disruptionMessageApi
        .updateDisruptionMessage({ uid, createEditDisruptionMessageConfig: data })
        .catch(errorHandlerWithDefault(undefined));
});
export const patchDisruptionMessage = (uid, data) => __awaiter(void 0, void 0, void 0, function* () {
    return yield disruptionMessageApi
        .patchDisruptionMessage({ uid, disruptionMessageConfigPatch: data })
        .catch(errorHandlerWithDefault(undefined));
});
export const deleteDisruptionMessageConfig = (uid) => __awaiter(void 0, void 0, void 0, function* () {
    return yield disruptionMessageApi.deleteDisruptionMessage({ uid }).catch(errorHandlerWithDefault(undefined));
});
const createFilterParams = (filterCriteria, pagination) => {
    const request = {
        page: pagination.page,
        size: pagination.size,
        sort: pagination.sort
    };
    if (isNotBlank(filterCriteria.cause)) {
        request.cause = filterCriteria.cause;
    }
    if (filterCriteria.fromDate) {
        request.startDate = filterCriteria.fromDate;
    }
    if (filterCriteria.toDate) {
        request.endDate = filterCriteria.toDate;
    }
    if (filterCriteria.organizationId != undefined) {
        request.organizationId = filterCriteria.organizationId;
    }
    return request;
};
const DisruptionMessageCauseEnumKey = Object.fromEntries(Object.entries(DisruptionMessageCauseEnum).map(([k, v]) => [v, k]));
export const getDisruptionMessageCause = (value) => {
    return value ? DisruptionMessageCauseEnum[DisruptionMessageCauseEnumKey[value.toUpperCase()]] : undefined;
};
