<script lang="ts">
  import { onMount } from 'svelte';
  import { _ } from 'svelte-i18n';
  import { Button, ModalDialog } from '@pids/shared-component';
  import type { Line } from '$generated/service/cache-api';
  import { Direction } from '$generated/service/cache-api';
  import { lineApi } from 'src/api/apiClients';
  import { errorHandlerWithDefault } from 'src/util/errorUtils';
  import type { LineFilterParams } from '../types';
  import LineFilterTable from './LineFilterTable.svelte';
  import LineFilterItem from './LineFilterItem.svelte';

  let lineDialogOpen = false;
  let editIndex: number = -1;

  export let lineFilters: LineFilterParams[] = [];
  export let readOnly: boolean;

  let lines: Line[] = [];

  onMount(() => {
    init();
  });

  const init = async () => {
    lines = await fetchLines();
  };

  let selectedLines: Record<number, Line> = {};

  const fetchLines = async (): Promise<Line[]> => {
    return lineApi.getAllLines().catch(errorHandlerWithDefault([]));
  };

  $: hasEmptyLineFilter = lineFilters.some(filter => filter.line === undefined);
  const addLineFilter = () => {
    lineFilters = [
      ...lineFilters,
      {
        line: undefined,
        direction: Direction.Both
      }
    ];
  };

  const removeLineFilter = (index: number) => {
    const e = lineFilters[index];
    lineFilters = lineFilters.filter(lf => lf !== e);
  };

  const showLineDialog = (index: number) => {
    editIndex = index;
    lineDialogOpen = true;
  };

  const handleLineAdd = () => {
    lineFilters[editIndex].line = selectedLines[editIndex];
    lineFilters[editIndex].stations = [];
    editIndex = -1;
  };

  const cancelLineDialog = () => {
    lineDialogOpen = false;
  };
</script>

<style>
  .line-filter-container {
    display: grid;
    grid-template-columns: 1fr 4fr;
    padding-top: 0.625rem;
    border-top: 1px solid #d7d7d7;
    gap: 1.25rem;
  }

  .line-filter-form-container {
    display: grid;
    gap: 1.25rem;
  }
</style>

<div class="line-filter-form-container">
  {#each lineFilters as __, i}
    <div class="line-filter-container">
      <label for="line-filter-{i}">
        {$_('disruption-management.editor.line-filter.line', {
          values: { n: `${i + 1}`.padStart(2, '0') }
        })}
      </label>
      <LineFilterItem
        id="line-filter-{i}"
        bind:filter={lineFilters[i]}
        deletable={lineFilters.length > 1}
        {readOnly}
        onEditLine={() => showLineDialog(i)}
        onRemove={() => removeLineFilter(i)} />
    </div>
  {/each}
  {#if !readOnly}
    <div class="line-filter-container">
      <div></div>
      <div>
        <Button
          icon="plus"
          link={true}
          compact={true}
          text={true}
          style="font-size: inherit; font-weight: inherit; gap: 0.25rem;"
          disabled={hasEmptyLineFilter}
          iconStyle="font-size: 1.25rem"
          className="link-button"
          on:click={addLineFilter}>
          {$_('disruption-management.editor.line-filter.add-line')}
        </Button>
      </div>
    </div>
  {/if}
</div>

<ModalDialog
  id="lineFilterDialog"
  title={$_('disruption-management.editor.line-selector.title')}
  bind:active={lineDialogOpen}
  okText={$_('disruption-management.editor.line-selector.button')}
  cancelText={$_('button.cancel')}
  showCancel={true}
  handleEnter={false}
  contentPadding={false}
  contentHeight="475px"
  contentClassName="scroll-y"
  okHandler={handleLineAdd}
  cancelHandler={cancelLineDialog}>
  <LineFilterTable {lines} bind:selectedLine={selectedLines[editIndex]} />
</ModalDialog>
