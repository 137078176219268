<script lang="ts">
  import { _ } from 'svelte-i18n';
  import {
    PageLayout,
    NumberedPagination,
    Button,
    notificationService,
    type LoadingStateStore
  } from '@pids/shared-component';
  import { writable } from 'svelte/store';
  import VehicleCategoryMappingFilter from 'src/components/pages/vehiclecategorymapping/VehicleCategoryMappingFilter.svelte';
  import {
    createQuery,
    initializePaginationFromQueryParams,
    type PaginationParams,
    sortBy,
    type SortFunction
  } from 'src/api/query';
  import type { VehicleCategoryMappingCriteria } from 'src/components/pages/vehiclecategorymapping/vehicleCategoryMappingService';
  import {
    updateVehicleCategoryMapping,
    vehicleCategoryMappingParamNames
  } from 'src/components/pages/vehiclecategorymapping/vehicleCategoryMappingService';
  import { createVehicleCategoryMapping } from 'src/components/pages/vehiclecategorymapping/vehicleCategoryMappingService';
  import { getVehicleCategoryMapping } from 'src/components/pages/vehiclecategorymapping/vehicleCategoryMappingService';
  import { deleteVehicleCategoryMapping } from 'src/components/pages/vehiclecategorymapping/vehicleCategoryMappingService';
  import { fetchVehicleCategoryMappings } from 'src/components/pages/vehiclecategorymapping/vehicleCategoryMappingService';
  import { VehicleCategoryMappingSort } from 'src/components/pages/vehiclecategorymapping/vehicleCategoryMappingSort';
  import { DEFAULT_ERROR_TEXT_KEY } from 'src/api/notification';
  import VehicleCategoryMappingTable from 'src/components/pages/vehiclecategorymapping/VehicleCategoryMappingTable.svelte';
  import type {
    CreateEditVehicleCategoryMapping,
    Pagination,
    VehicleCategoryMapping
  } from '$generated/service/cache-api';
  import { onMount } from 'svelte';
  import type { SvelteEvent } from 'src/model/event';
  import DeleteModalDialog from 'src/components/shared/DeleteModalDialog.svelte';
  import { Permission } from 'src/user/permissions';
  import Restricted from 'src/components/shared/Restricted.svelte';
  import VehicleCategoryMappingModalDialog from 'src/components/pages/vehiclecategorymapping/VehicleCategoryMappingModalDialog.svelte';
  import { toVehicleCategoryLink } from 'src/components/pages/routingService';
  import { querystring, replace } from 'svelte-spa-router';

  const initialPagination: PaginationParams = {
    page: 1,
    size: 50,
    sort: `${VehicleCategoryMappingSort.From},asc`
  };

  const sortFunction: SortFunction = sortBy(VehicleCategoryMappingSort.From);
  let paginationParams: PaginationParams = { ...initialPagination };
  let filter = writable<VehicleCategoryMappingCriteria>({});
  let results: VehicleCategoryMapping[] = [];
  let pagination: Pagination | undefined;

  let vehicleCategoryMapping: VehicleCategoryMapping | undefined = undefined;
  let vehicleCategoryMappingData: CreateEditVehicleCategoryMapping | undefined = undefined;
  let loading: LoadingStateStore;

  let showDeleteDialog = false;
  let showSaveDialog = false;

  const resultsQuery = createQuery(
    [],
    signal => fetchVehicleCategoryMappings($filter, paginationParams, { signal }),
    false
  );

  $: results = !$resultsQuery.isError ? $resultsQuery.results : [];
  $: pagination = !$resultsQuery.isError ? $resultsQuery.pagination : undefined;
  $: loading = resultsQuery.loading;

  $: $resultsQuery.isError && notificationService.error($resultsQuery.error.message ?? $_(DEFAULT_ERROR_TEXT_KEY));

  onMount(() => {
    handleQueryString($querystring);

    const unsubscribeFilter = filter.subscribe(() => {
      resetPageAndSort();
      void loadData();
    });

    return () => {
      unsubscribeFilter();
      resultsQuery.abort();
    };
  });

  const resetPageAndSort = () => {
    paginationParams = { ...initialPagination };
  };

  const loadData = async () => {
    updateUrlParameters();
    await resultsQuery.next();
  };

  const handlePageChange = (page: number) => {
    Object.assign(paginationParams, { page });
    void loadData();
  };

  const handleSortChange = (e: SvelteEvent<{ sort: VehicleCategoryMappingSort }>) => {
    const newSort = sortFunction(e.detail.sort);

    paginationParams = {
      ...paginationParams,
      sort: newSort
    };

    void loadData();
  };

  const openDeleteDialog = ({ detail }: SvelteEvent<VehicleCategoryMapping>) => {
    showDeleteDialog = true;
    vehicleCategoryMapping = detail;
  };

  const closeDeleteDialog = () => {
    showDeleteDialog = false;
    vehicleCategoryMapping = undefined;
  };

  const closeSaveDialog = () => {
    showSaveDialog = false;
    vehicleCategoryMappingData = undefined;
    vehicleCategoryMapping = undefined;
  };

  const openSaveDialog = async (event?: VehicleCategoryMapping) => {
    if (event) {
      const { id } = event;
      vehicleCategoryMapping = await getVehicleCategoryMapping(id);
      if (!vehicleCategoryMapping) return;
      const { from, to, textDe, textEn, textFr, textIt } = vehicleCategoryMapping;
      vehicleCategoryMappingData = {
        from,
        to,
        textDe,
        textEn,
        textFr,
        textIt
      };
    }

    showSaveDialog = true;
  };

  const handleConfirmDelete = async () => {
    if (!vehicleCategoryMapping || !vehicleCategoryMapping.id) return;

    await deleteVehicleCategoryMapping(vehicleCategoryMapping.id);

    loadData();
    closeDeleteDialog();
  };

  const handleSave = async ({ detail }: SvelteEvent<CreateEditVehicleCategoryMapping>) => {
    const data = await saveVehicleCategoryMapping(detail);

    if (data) loadData();

    closeSaveDialog();
  };

  const saveVehicleCategoryMapping = async (data: CreateEditVehicleCategoryMapping) => {
    if (vehicleCategoryMapping) {
      return updateVehicleCategoryMapping(vehicleCategoryMapping.id, data);
    }

    return createVehicleCategoryMapping(data);
  };

  const updateUrlParameters = () => {
    replace(toVehicleCategoryLink({ ...$filter }, paginationParams));
  };

  const handleQueryString = (query: string | undefined) => {
    if (!query) {
      return;
    }

    const params = new URLSearchParams(query);

    paginationParams = initializePaginationFromQueryParams(params, paginationParams);
    $filter = initializeCriteriaFromQueryParams(params);
  };

  const initializeCriteriaFromQueryParams = (params: URLSearchParams): VehicleCategoryMappingCriteria => {
    return {
      ...$filter,
      text: params.get(vehicleCategoryMappingParamNames.text) ?? undefined,
      from: params.get(vehicleCategoryMappingParamNames.from) ?? undefined,
      to: params.get(vehicleCategoryMappingParamNames.to) ?? undefined
    };
  };
</script>

<PageLayout title={$_('routes.group.configurations.vehicle-category-mapping')}>
  <svelte:fragment slot="criteria">
    <VehicleCategoryMappingFilter bind:filter={$filter} />
  </svelte:fragment>

  <svelte:fragment slot="actions">
    <Restricted to={Permission.VEHICLE_CATEGORY_MAPPING_WRITE}>
      <Button primary icon="plus" on:click={() => openSaveDialog(vehicleCategoryMapping)}>
        {$_('vehicle-category-mapping.action.create.label')}
      </Button>
    </Restricted>
  </svelte:fragment>

  <svelte:fragment slot="content">
    <VehicleCategoryMappingTable
      loading={$loading}
      resultsList={results}
      on:sort={handleSortChange}
      on:delete={openDeleteDialog}
      on:edit={({ detail }) => openSaveDialog(detail)} />
  </svelte:fragment>

  <svelte:fragment slot="pagination">
    {#if !$loading && pagination}
      <NumberedPagination {...pagination} onPageChange={handlePageChange} />
    {/if}
  </svelte:fragment>
</PageLayout>

{#if showSaveDialog}
  <VehicleCategoryMappingModalDialog
    title={vehicleCategoryMappingData
      ? $_('vehicle-category-mapping.dialog.config.title.edit')
      : $_('vehicle-category-mapping.dialog.config.title.create')}
    bind:show={showSaveDialog}
    on:close={closeSaveDialog}
    on:save={handleSave}
    initial={vehicleCategoryMappingData} />
{/if}

{#if showDeleteDialog && vehicleCategoryMapping}
  {@const deleteModalTitle = `${vehicleCategoryMapping.from} - ${vehicleCategoryMapping.to}`}
  <DeleteModalDialog
    bind:open={showDeleteDialog}
    title={$_('vehicle-category-mapping.dialog.delete.title')}
    name={deleteModalTitle}
    on:confirm={handleConfirmDelete}
    on:cancel={closeDeleteDialog}>
    <svelte:fragment slot="confirm">
      {$_('vehicle-category-mapping.dialog.delete.confirm')}
    </svelte:fragment>
  </DeleteModalDialog>
{/if}
