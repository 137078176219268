import { __awaiter } from "tslib";
import { organizationApi } from 'src/api/apiClients';
import { silentErrorHandlerWithDefault } from 'src/util/errorUtils';
export const getAllOrganizations = () => __awaiter(void 0, void 0, void 0, function* () {
    return yield organizationApi
        .getOrganizations()
        .then(s => s.organizations)
        .catch(silentErrorHandlerWithDefault([]));
});
export const getOrganizationByName = (name) => __awaiter(void 0, void 0, void 0, function* () {
    return yield organizationApi.getOrganizationByName({ name }).catch(silentErrorHandlerWithDefault(undefined));
});
