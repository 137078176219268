/* tslint:disable */
/* eslint-disable */
/**
 * Netcetera PIDS - Hub Cache API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.6.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { __awaiter } from "tslib";
import * as runtime from '../runtime';
import { CreateEditRegionToJSON, RegionFromJSON, RegionOverviewFromJSON, StationRefFromJSON, } from '../models/index';
/**
 *
 */
export class RegionApi extends runtime.BaseAPI {
    /**
     */
    createRegionRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters['createEditRegion'] == null) {
                throw new runtime.RequiredError('createEditRegion', 'Required parameter "createEditRegion" was null or undefined when calling createRegion().');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/region`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: CreateEditRegionToJSON(requestParameters['createEditRegion']),
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => RegionFromJSON(jsonValue));
        });
    }
    /**
     */
    createRegion(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.createRegionRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    deleteRegionRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters['id'] == null) {
                throw new runtime.RequiredError('id', 'Required parameter "id" was null or undefined when calling deleteRegion().');
            }
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/region/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
                method: 'DELETE',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.VoidApiResponse(response);
        });
    }
    /**
     */
    deleteRegion(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.deleteRegionRaw(requestParameters, initOverrides);
        });
    }
    /**
     */
    findUsedStationsRaw(initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/region/station`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(StationRefFromJSON));
        });
    }
    /**
     */
    findUsedStations(initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.findUsedStationsRaw(initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    getAllRegionsRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            if (requestParameters['nameLike'] != null) {
                queryParameters['name.like'] = requestParameters['nameLike'];
            }
            if (requestParameters['nameEq'] != null) {
                queryParameters['name.eq'] = requestParameters['nameEq'];
            }
            if (requestParameters['descriptionLike'] != null) {
                queryParameters['description.like'] = requestParameters['descriptionLike'];
            }
            if (requestParameters['descriptionEq'] != null) {
                queryParameters['description.eq'] = requestParameters['descriptionEq'];
            }
            if (requestParameters['stationSloid'] != null) {
                queryParameters['stationSloid'] = requestParameters['stationSloid'];
            }
            if (requestParameters['organizationId'] != null) {
                queryParameters['organizationId'] = requestParameters['organizationId'];
            }
            if (requestParameters['page'] != null) {
                queryParameters['page'] = requestParameters['page'];
            }
            if (requestParameters['size'] != null) {
                queryParameters['size'] = requestParameters['size'];
            }
            if (requestParameters['sort'] != null) {
                queryParameters['sort'] = requestParameters['sort'].join(runtime.COLLECTION_FORMATS["csv"]);
            }
            const headerParameters = {};
            const response = yield this.request({
                path: `/region`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => RegionOverviewFromJSON(jsonValue));
        });
    }
    /**
     */
    getAllRegions() {
        return __awaiter(this, arguments, void 0, function* (requestParameters = {}, initOverrides) {
            const response = yield this.getAllRegionsRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    getRegionRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters['id'] == null) {
                throw new runtime.RequiredError('id', 'Required parameter "id" was null or undefined when calling getRegion().');
            }
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/region/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => RegionFromJSON(jsonValue));
        });
    }
    /**
     */
    getRegion(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.getRegionRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     * Get all regions that match the given query.
     */
    searchRegionsRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            if (requestParameters['name'] != null) {
                queryParameters['name'] = requestParameters['name'];
            }
            if (requestParameters['limit'] != null) {
                queryParameters['limit'] = requestParameters['limit'];
            }
            const headerParameters = {};
            const response = yield this.request({
                path: `/region/search`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(RegionFromJSON));
        });
    }
    /**
     * Get all regions that match the given query.
     */
    searchRegions() {
        return __awaiter(this, arguments, void 0, function* (requestParameters = {}, initOverrides) {
            const response = yield this.searchRegionsRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    updateRegionRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters['id'] == null) {
                throw new runtime.RequiredError('id', 'Required parameter "id" was null or undefined when calling updateRegion().');
            }
            if (requestParameters['createEditRegion'] == null) {
                throw new runtime.RequiredError('createEditRegion', 'Required parameter "createEditRegion" was null or undefined when calling updateRegion().');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/region/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
                method: 'PUT',
                headers: headerParameters,
                query: queryParameters,
                body: CreateEditRegionToJSON(requestParameters['createEditRegion']),
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => RegionFromJSON(jsonValue));
        });
    }
    /**
     */
    updateRegion(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.updateRegionRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
}
